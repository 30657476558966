import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
//import { BrowserRouter } from 'react-router-dom';
//import { Router } from 'react-router-dom'
import NavigationBar from './component/NavBar'
import { StyledEngineProvider } from '@mui/material/styles';
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginApiRequest } from "./authConfig.js";
//import ErrorBoundary from "./ErrorBoundary.jsx"
import axios from "axios";
import { SignInButton } from "./component/SignInButton"
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

 

const msalInstance = new PublicClientApplication(msalConfig);

//axios.defaults.baseURL = "https://localhost:7259/";
axios.defaults.baseURL = "https://raporter2-api20221222183249.azurewebsites.net/";

axios.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account,
        });
        //console.log('Access Token:', msalResponse.accessToken);
        response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
        return response;
    },
    (err) => {
        if (err.response.status === 401) {
            // Błąd 401 - przekieruj użytkownika do strony logowania
            msalInstance.loginRedirect(loginApiRequest);
        }
        return Promise.reject(err);
    }
);



ReactDOM.createRoot(document.querySelector("#root")).render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <MsalProvider instance={msalInstance}>

                <AuthenticatedTemplate>
                    <NavigationBar />
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate className="unauthenticated-template" >
                    <br /><br /><br />
                    <Box className="center1"
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: 380,
                                height: 220,
                            },
                        }}
                    >

                        <Paper elevation={12} className="center">
                            <br />
                            <img className="raporter-logo-login" src='/Betacom_.png' />
                            <br />
                            <SignInButton />
                            <br />
                        </Paper>
                    </Box>


                </UnauthenticatedTemplate>
            </MsalProvider>
        </StyledEngineProvider>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
