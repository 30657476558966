import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import ReactDOM from 'react-dom'; // Dodaj ten import
import Hls from 'hls.js';
/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const Sejm = () => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedVideoLink, setSelectedVideoLink] = useState('');
    const [selectedVideoLinkBlob, setSelectedVideoLinkBlob] = useState('');
    const openModal = (videoLink) => {
        setSelectedVideoLink(videoLink);
        //setSelectedVideoLinkBlob(URL.createObjectURL(videoLink));
        // Utwórz Blob z tekstu URL
        const blob = new Blob([videoLink], { type: 'application/x-mpegURL' });
        setSelectedVideoLinkBlob(URL.createObjectURL(blob));
        setModalShow(true);
    };
    const modifyVideoLink = (originalLink) => {
        const start = "http://r.dcs.redcdn.pl/nvr/";
        const parts = originalLink.split(start);

        if (parts.length === 2) {
            // Dzieli link po znaku zapytania
            const queryParams = parts[1].split("?");

            // Pierwsza część (przed znakiem zapytania)
            const end = queryParams[0];
            console.log(`https://r.dcs.redcdn.pl/livehls/${end}/playlist.m3u8`);
            // Zwraca zaktualizowany link
            return `https://r.dcs.redcdn.pl/livehls/${end}/playlist.m3u8`;
        }

        // Jeżeli link nie pasuje do oczekiwanego formatu, zwróć oryginalny link
        return originalLink;
    };
    const closeModal = () => {
        setModalShow(false);
        setSelectedVideoLink('');
    };

    useEffect(() => {
        if (modalShow) {
            // Sprawdź, czy link obsługuje HLS
            const isHLS = selectedVideoLink.endsWith('.m3u8');

            if (isHLS) {
                const video = document.getElementById('video-player');

                if (Hls.isSupported()) {
                    const hls = new Hls();
                    hls.loadSource(selectedVideoLink);
                    hls.attachMedia(video);
                } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = selectedVideoLink;
                }
            }
        }
    }, [modalShow, selectedVideoLink]);

    const fetchSejmData = async () => {
        try {
            const response = await fetch('https://api.sejm.gov.pl/sejm/term10/videos/today');
            const data = await response.json();

            // Pobierz wszystkie obiekty typu "posiedzenie"
            const posiedzenieVideos = data;

            // Pobierz dane do renderowania tabeli
            const tableData = posiedzenieVideos.map(item => ({
                title: item.title,
                description: item.description || '',
                type: item.type,
                videoLink: item.videoLink.trim(),
                otherVideoLinks: item.otherVideoLinks || [],
            }));

            renderTable(tableData);
        } catch (error) {
            console.error('Błąd pobierania danych:', error);
        }
    };

    const renderTable = (data) => {
        // Renderuj tabelę
        const tableRows = data.map((row, index) => (
            <tr key={index}>
                <td>{row.title}</td>
                <td>{row.description}</td>
                <td>{row.type}</td>
                <td>
                    <Button variant="primary" onClick={() => openModal(modifyVideoLink(row.videoLink))}>
                        Otwórz
                    </Button>
                </td>
                <td>
                    {row.otherVideoLinks.map((otherLink, otherIndex) => (
                        <Button
                            key={otherIndex}
                            variant="primary"
                            onClick={() => openModal(modifyVideoLink(otherLink.trim()))}
                        >
                            Otwórz
                        </Button>
                    ))}
                </td>
            </tr>
        ));

        // Dodaj tabelę do elementu z id "table-container"
        const table = (
            <table className="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Video Link</th>
                        <th>Other Video Links</th>
                    </tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        );

        ReactDOM.render(table, document.getElementById('table-container'));
    };
    useEffect(() => {
        fetchSejmData();
    }, []);
    return (
        
        <>
            <h4>Dzisiejsze transmisje</h4> 

            {/* Modal z odtwarzaczem video */}
            <Modal show={modalShow} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Tutaj umieść odtwarzacz video, używając selectedVideoLink */}
                    <video id="video-player" controls width="100%" height="400" playsInline autoPlay>
                        <source src={selectedVideoLinkBlob} type="application/x-mpegURL" />
                        Twoja przeglądarka nie obsługuje tagu wideo.
                    </video>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Kontener dla tabeli */}
            <div id="table-container"></div>
        </>
    );
};


export default Sejm;
