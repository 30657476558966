import React, { useState, useEffect } from 'react';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';

import { loginApiRequest, appRoles } from '../authConfig';

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard = ({ ...props }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
   
    //const { accounts } = useMsal();
    //const currentAccount = instance.getActiveAccount();
    const currentAccount = instance.getAllAccounts()[0];

    const authRequest = {
        ...loginApiRequest,
    };

    const onLoad = async () => {
        //console.log('current:' + currentAccount)
        //console.log('roles:' + props.roles)
        if (currentAccount && currentAccount.idTokenClaims['roles']) {

            let intersection = props.roles
                .filter((role) => currentAccount.idTokenClaims['roles'].includes(role));
            //console.log('inter:' + intersection)
            //console.log('roleuser:' + currentAccount.idTokenClaims['roles'])
            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
            else { setIsAuthorized(false); }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance, currentAccount]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >

            {isAuthorized ? (
                <div>{props.children}</div>
            ) : (
                <div className="data-area-div">
                    <h3>
                        Nie masz wystarczających uprawnień do przeglądania tej zawartości.
                    </h3>
                </div>
            )}

        </MsalAuthenticationTemplate>
    );
};