import * as React from 'react';

import Title from './Title';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const Test = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [datasUsers, setDatasUsers] = React.useState([]); //usery wylaczone z licencja przypisana
    const [isTableExpanded, setIsTableExpanded] = useState(true); //rozwijanie tabeli usery wylaczone z licencja przypisana
    const [upnInput, setUpnInput] = useState("");

    const [accountData, setAccountData] = useState({
        firstName: "",
        lastName: "",
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        position: "",
        department: "",
        manager: "",
    });
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setAccountData({
            ...accountData,
            [name]: value,
        });
    };
    const handleChange = (e) => {
        setUpnInput(e.target.value);
    };

    const handleCreate = () => {
        // Tutaj możesz dodać logikę zapisywania konta, np. wysłanie na serwer
        console.log("Utwórz konto:", accountData);
    };

    const handleFormClear = () => {
        setAccountData({
            firstName: "",
            lastName: "",
            fullName: "",
            phoneNumber: "",
            emailAddress: "",
            position: "",
            department: "",
            manager: "",
        });
    };
    const inputRef = useRef(null);




 

    const handleToggleTable = () => {
        setIsTableExpanded(prevState => !prevState);
    };

    const testGraph = () => {
        (async () => {
            const url = "/graph/getDisLicUsers"
            setLoading(true);
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatasUsers(response.value);
            console.log(datasUsers);
            setLoading(false);
        })();
    } //dodac: <button disabled onClick={testGraph}>graph req</button><br />
    /*
    const testAD = () => {
        (async () => {
            const url = "/proxy/getuser/ppoczobut@betacom.com.pl"
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }*/
    const testAD = async () => {
        try {
            const url = `/proxy/getuser/${upnInput}`;
            setLoading(true);
            const response = await axios.get(url);
            const responseData = JSON.parse(response.data);
            setDatas(responseData);
            
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
        setLoading(false);
    }
    };



    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    const formatValue = (value) => {
        if (value === null) {
            return "null";
        } else if (typeof value === "boolean") {
            return value ? "True" : "False";
        } else {
            return value;
        }
    };

    return (
        <React.Fragment>
            <button type="button" className="btn btn-secondary ml-2" onClick={testGraph}>
                usery
            </button>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <div style={{ overflowX: 'auto', marginBottom: '1rem' }}>                              
                                <IconButton onClick={handleToggleTable}>
                                    {isTableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>Konta wyłączone i licencje przypisane
                            </div>
                            {isTableExpanded && (
                                <div style={{ overflowX: 'auto' }}>
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : (
                                            <table style={{ borderCollapse: "collapse", width: "80%" }} >
                                            <thead>
                                                <tr>
                                                        <th style={tableHeaderStyle}>DisplayName</th>
                                                        <th style={tableHeaderStyle}>UserPrincipalName</th>
                                                        <th style={tableHeaderStyle}>extensionAttribute1</th>
                                                        <th style={tableHeaderStyle}>Enabled</th>
                                                        <th style={tableHeaderStyle}>Licenses</th>
                                                        <th style={tableHeaderStyle}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datasUsers.map((user, index) => (
                                                    <tr key={index}>
                                                        <td style={tableCellStyle}>{user.displayName}</td>
                                                        <td style={tableCellStyle}>{user.userPrincipalName}</td>
                                                        <td style={tableCellStyle}>{user.extensionAttribute1}</td>
                                                        <td style={tableCellStyle}>{user.accountEnabled ? "Yes" : "No"}</td>
                                                        <td style={tableCellStyle}>
                                                            <ul>
                                                                {user.licenses.map((license, licenseIndex) => (
                                                                    <li key={licenseIndex}>{license.skuPartNumber}</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                        <td style={tableCellStyle}><button>Zdejmij</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            </React.Fragment>
                    
 
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default Test;
