import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import axios from "axios";
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
//dialog
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'Id',
        numeric: false,
        disablePadding: false,
        label: 'Id',
    },
    {
        id: 'KlientNazwa',
        numeric: false,
        disablePadding: false,
        label: 'Klient',
    },
    {
        id: 'ABProduktGroup',
        numeric: false,
        disablePadding: false,
        label: 'Grupa',
    },
    {
        id: 'Produkt',
        numeric: false,
        disablePadding: false,
        label: 'Produkt',
    },
    {
        id: 'CenaRok',
        numeric: false,
        disablePadding: false,
        label: 'Cena R/M',
    },
    {
        id: 'CenaRokProcent',
        numeric: false,
        disablePadding: false,
        label: 'Rozl. procentowe rok',
    },
    {
        id: 'CenaMiesiac',
        numeric: false,
        disablePadding: false,
        label: 'Cena M/M',
    },
    {
        id: 'CenaMiesiacProcent',
        numeric: false,
        disablePadding: false,
        label: 'Rozl. procentowe miesiąc',
    },
    {
        id: 'Waluta',
        numeric: false,
        disablePadding: false,
        label: 'Waluta',
    },
    {
        id: 'KodProjektu',
        numeric: false,
        disablePadding: false,
        label: 'Kod projektu',
    },
    {
        id: 'CreatedAt',
        numeric: true,
        disablePadding: false,
        label: 'Data dodania',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));



    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </StyledTableCell>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const { searchTerm, onSearchTermChange } = props;
    /*function handleSearchTermChange(event) {
        setSearchTerm(event.target.value);
    }
    */

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Cennik BCM - edycja rekordów
                </Typography>
            )}
            <input
                type="text"
                placeholder="Szukaj klienta..."
                value={searchTerm}
                onChange={onSearchTermChange}
            />
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                    
                   </Tooltip>
            )}
        </Toolbar>
    );
}



EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

/*
const [searchTerm, setSearchTerm] = React.useState('');
function handleSearchTermChange(event) {
    setSearchTerm(event.target.value);
    //const searchTerm = event.target.value;
}
*/
function useSearchTerm(initialValue) {
    const [searchTerm, setSearchTerm] = useState(initialValue);

    function handleSearchTermChange(event) {
        setSearchTerm(event.target.value);
    }

    return [searchTerm, handleSearchTermChange];
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function BCMDialog(props) {
    const { selectedDialog, setSelectedDialog, open, handleCloseDialog, handleCloseDialogUpdate, setPrices } = props;
    const [ dataChanged, setDataChanged ] = React.useState(false);
    const handleInputChange = (e) => {
        setDataChanged(true);
        const { name, value } = e.target;
        if (name === "CenaRokProcent" || name === "CenaMiesiacProcent") {
            var val = (value.toLowerCase() === "true"); //konwersja do boolean
            setSelectedDialog({
                ...selectedDialog,
                [name]: val,
            });
        }
        else {
            setSelectedDialog({
                ...selectedDialog,
                [name]: value,
            });
        }
        //console.log(selectedDialog);
    }
    
    function fetchdata() {
        (async () => {
            axios.post("/db/UpdatePrice2", selectedDialog).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
        })();
        return
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(selectedDialog);
        if (dataChanged) {
            fetchdata();
        }
        handleCloseDialogUpdate();
        setDataChanged(false);
        event.target.reset();
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseDialog}
            TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Edycja
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>
                        <Grid item xs={4}><FormControl fullWidth>

                            <TextField
                                defaultValue={selectedDialog.KlientNazwa}
                                disabled
                                name="Klient"
                                label="Klient"
                                type="text"
                            /></FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>

                                <TextField
                                    defaultValue={selectedDialog.ABProduktGroup}
                                    disabled
                                    name="Grupa"
                                    label="Grupa"
                                    type="text"
                                /></FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>

                                <TextField
                                    defaultValue={selectedDialog.Produkt}
                                    disabled
                                    name="Produkt"
                                    label="Produkt"
                                    type="text"
                                /></FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth><TextField
                                defaultValue={selectedDialog.CenaRok}
                                id="cenaRok-input"
                                name="CenaRok"
                                label="Cena R/M"
                                type="number"
                                inputProps={{
                                    step: 0.0001,
                                }}
                                onChange={handleInputChange}
                            /></FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Sposób naliczania</FormLabel>
                                <RadioGroup
                                    name="CenaRokProcent"
                                    defaultValue={selectedDialog.CenaRokProcent}
                                    onChange={handleInputChange}
                                    defaultValue={false}
                                    row
                                >
                                    <FormControlLabel
                                        key="cenaR"
                                        value={false}
                                        control={<Radio size="small" />}
                                        label="Cena"
                                    />
                                    <FormControlLabel
                                        key="procentR"
                                        value={true}
                                        control={<Radio size="small" />}
                                        label="Procent"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    defaultValue={selectedDialog.CenaMiesiac}
                                    id="cena-miesiac"
                                    name="CenaMiesiac"
                                    label="Cena M/M"
                                    type="number"
                                    inputProps={{
                                        step: 0.0001,
                                    }}
                                    onChange={handleInputChange}
                                /></FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Sposób naliczania</FormLabel>
                                <RadioGroup

                                    name="CenaMiesiacProcent"
                                    defaultValue={selectedDialog.CenaMiesiacProcent}
                                    onChange={handleInputChange}
                                    defaultValue={false}
                                    row
                                >
                                    <FormControlLabel
                                        key="cenaM"
                                        value={false}
                                        control={<Radio size="small" />}
                                        label="Cena"
                                    />
                                    <FormControlLabel
                                        key="procentM"
                                        value={true}
                                        control={<Radio size="small" />}
                                        label="Procent"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="waluta-label">Waluta</InputLabel>
                                <Select
                                    labelId="waluta-label"
                                    id="waluta-select"
                                    name="Waluta"
                                    defaultValue={selectedDialog.Waluta ?? "PLN"}
                                    onChange={handleInputChange}
                                    label="Waluta"
                                >
                                    <MenuItem key="PLN" value="PLN">PLN</MenuItem>
                                    <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                                    <MenuItem key="USD" value="USD">USD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth><TextField
                                id="mailing-input"
                                name="Mailing"
                                label="Mailing"
                                type="text"
                                defaultValue={selectedDialog.Mailing}
                                onChange={handleInputChange}
                            /></FormControl></Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth><TextField
                                id="emaildh-input"
                                name="EmailDH"
                                label="Email DH"
                                type="email"
                                defaultValue={selectedDialog.EmailDH}
                                onChange={handleInputChange}
                            /></FormControl></Grid>
                        <Grid item xs={3}><FormControl fullWidth><TextField
                            id="kodprojektu-input"
                            name="KodProjektu"
                            label="Kod projektu"
                            type="text"
                            defaultValue={selectedDialog.KodProjektu}
                            onChange={handleInputChange}
                        /></FormControl></Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="uwagi-input"
                                    name="Uwagi"
                                    label="Uwagi"
                                    type="text"
                                    defaultValue={selectedDialog.Uwagi}
                                    onChange={handleInputChange}
                                /></FormControl></Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </Dialog>
    );
}


const BCMProductsEdit = () => {
    const [prices, setPrices] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            const response = await axios.get("/db/getcennikbcm").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setPrices(response);
        })();
    }, [setPrices]);

    const [update, setUpdate] = React.useState(false);
    React.useEffect(() => {
        let active = true;

        if (!update) {
            return undefined;
        }
        (async () => {
            const response = await axios.get("/db/getcennikbcm").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });

            if (active) {
                setPrices(response);
                setUpdate(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [update]);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 11
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
        },
    }));
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(true);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('KlientNazwa');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = prices.map((n) => n.Id);
            setSelected(newSelected);
            //console.log(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const [open, setOpen] = React.useState(false);

    const [selectedDialog, setSelectedDialog] = React.useState([]);
    const handleClickOpenDialog = (event, name) => {
        const selectedIndex = name;
        setSelectedDialog(selectedIndex);
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedDialog([]);
    };
    const handleCloseDialogUpdate = () => {
        setOpen(false);
        setUpdate(true);
        setSelectedDialog([]);
    };

    const [searchTerm, handleSearchTermChange] = useSearchTerm('');

    const filteredData = prices.filter(item =>
        item.KlientNazwa.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} onSearchTermChange={handleSearchTermChange} searchTerm={searchTerm} />
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredData.length}
                        />
                        <TableBody>
                            {stableSort(filteredData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((price, index) => {
                                    const isItemSelected = isSelected(price.Id);
                                    //const isItemClicked =  
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (

                                        <React.Fragment>

                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={price.Id}
                                                //key={index}
                                                selected={isItemSelected}
                                            >
                                                <StyledTableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClick(event, price.Id)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </StyledTableCell>

                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.Id}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.KlientNazwa}</StyledTableCell>
                                                <StyledTableCell size="small" onClick={(event) => handleClickOpenDialog(event, price)}>{price.ABProduktGroup}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.Produkt}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.CenaRok}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.CenaRokProcent === false ? 'False'
                                                    : price.CenaRokProcent === true
                                                        ? 'True'
                                                        : price.CenaRokProcent}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.CenaMiesiac}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.CenaMiesiacProcent === false ? 'False'
                                                    : price.CenaMiesiacProcent === true
                                                        ? 'True'
                                                        : price.CenaMiesiacProcent}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.Waluta}</StyledTableCell>
                                                <StyledTableCell onClick={(event) => handleClickOpenDialog(event, price)}>{price.KodProjektu}</StyledTableCell>
                                                <StyledTableCell align="right" onClick={(event) => handleClickOpenDialog(event, price)}>{price.CreatedAt}</StyledTableCell>

                                            </TableRow>

                                        </React.Fragment>

                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={prices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <BCMDialog
                open={open}
                handleCloseDialog={handleCloseDialog}
                handleCloseDialogUpdate={handleCloseDialogUpdate}
                setSelectedDialog={setSelectedDialog}
                selectedDialog={selectedDialog}
            />
        </React.Fragment>
    )
};

export default BCMProductsEdit;