//import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
//import { Component } from 'react';
//import { useState } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Row, Col, Button, Table, Modal} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import './MyForm.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // Importujemy Typeahead
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Importujemy styl Typeahead
import { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl'; // Importuj lokalizację dla Polski
import { ToastContainer, toast } from 'react-toastify';  //powiadomienia
import 'react-toastify/dist/ReactToastify.css';

// Zarejestruj lokalizację dla datepicekra
registerLocale('pl', pl);

const BcmPriceList = () => {
    const opcjeWalut = [
        { key: 'Wybierz', value: '', label: 'Wybierz...', selected: true, disabled: true },
        { key: 'PLN', value: 'PLN', label: 'PLN' },
        { key: 'EUR', value: 'EUR', label: 'EUR' },
        { key: 'USD', value: 'USD', label: 'USD' },
    ];


    const [defaultEndDate, setDefaultEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [klientOptions, setKlientOptions] = useState([]);
    const [grupaOptions, setGrupaOptions] = useState([]);
    const [produktOptions, setProduktOptions] = useState([]);
    const emptyOption = { label: 'Wybierz...', selected: true, disabled: true };
    const [billingPlanOptions, setBillingPlanOptions] = useState([emptyOption, /* inne opcje */]); //useState([]);

    const [showModal, setShowModal] = useState(false);
    const [bcmPrices, setBcmPrices] = useState([]); //nowy cennik
    const [bcmPricesStary, setBcmPricesStary] = useState([]); //stary cennik
    const [bcmPricesSubComm, setBcmPricesSubComm] = useState([]); //ile jest aktwynych subskrypcji tego samego produktu u jednego Klienta - potrzebne do modulu komentarzy per subskrypcja
    const [selectedSub, setSelectedSub] = useState([]); //uwagi per subskrypcja - id subskrypcji
    const [selectedSubComment, setSelectedSubComment] = useState([]); //uwagi per subskrypcja - uwagi

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [formValues, setFormValues] = useState({
        Amount: 0, //null byl
        klient: '',
        grupa: '',
        produkt: '',
        billingPlan: '',
        Mailing: '',
        EmailDH: '',
        Waluta: '',
        WalutaProdukt: '',
        UwagiKlient: '', //na poziomie klienta
        UwagiProdukt: '', //na poziomie produktu
        KodProjektuKlient: '',
        KodProjektuProdukt: '', //
        SpecialPrice: 0,
        PurchasePrice: 0,
        ERPPrice: 0,
        SaleCommission: 0,
        StartDate: null,
        EndDate: null,

    });



    const selectBillingPlanRef = useRef(null);
    const selectCustomerRef = useRef(null);
    const selectGroupRef = useRef(null);
    const selectProductRef = useRef(null);

    const [switchChecked, setSwitchChecked] = useState(false);

    const [startDateModal, setStartDateModal] = useState([]);
    const [endDateModal, setEndDateModal] = useState([]);

    const handleModalStartDateChange = (date) => {
        // Ustaw nową datę StartDate
        //setStartDateModal(date);
        //const formattedDate = date.toISOString().split('T')[0];
        date.setHours(0, 0, 0, 0);
        setStartDateModal(date);
        setSelectedRecord({ ...selectedRecord, StartDate: date });
        // Sprawdź, czy EndDate jest wcześniejsza niż StartDate
        //if (endDateModal < date) {
        // Jeśli tak, ustaw EndDate na datę równą StartDate
        //    setEndDateModal(date);
        //}
    };
    const handleModalEndDateChange = (date) => {
        // Sprawdź, czy EndDate jest wcześniejsza niż StartDate
        if (date > startDateModal) {
            // Jeśli tak, ustaw EndDate na datę równą StartDate
            //setEndDateModal(startDateModal);
            //const formattedDate = date.toISOString().split('T')[0];
            // Ustawienie czasu na 00:00:00
            date.setHours(0, 0, 0, 0);
            setSelectedRecord({ ...selectedRecord, EndDate: date });
        } else {
            // W przeciwnym razie ustaw normalnie
            //alert('EndDate nie może być starsza bądź równa StartDate');
            toast.warn('EndDate nie może być starsza bądź równa StartDate');
            //setEndDateModal(date);
            console.log('Dane formularza modal:', selectedRecord);
        }
    };
    /*
    useEffect(() => {
        cenaRef.current = cenaElement;
    }, [cenaElement]); 
*/
    const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 00:00:00`;
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('pl-PL', options);
    };
    const formatDateIso8601 = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const isoDate = new Date(dateString).toISOString().split('T')[0];
        return isoDate;
    };
    useEffect(() => {
        // Ustaw datę domyślną na dzisiejszą datę z dodanym rokiem
        const today = new Date();
        // Odjęcie jednego dnia
        today.setDate(today.getDate() - 1);

        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        setDefaultEndDate(nextYear);
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setFormValues({ ...formValues, StartDate: formatDateString(date) });
    };

    const handleEndDateChange = (date) => {
        // Sprawdzamy, czy nowa data nie jest mniejsza niż Start Date
        if (date > startDate) {
            setDefaultEndDate(date);
            setFormValues({ ...formValues, EndDate: formatDateString(date) });
        } else {
            //alert('EndDate nie może być starsza bądź równa StartDate');
            toast.warn('EndDate nie może być starsza bądź równa StartDate');
        }
    };


    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            try {
                const response = await axios.get("/db/getcustomers");
                if (active) {
                    setKlientOptions(response.data);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    //alert("Your access is not allowed.");
                    toast.error("Your access is not allowed.");
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    const handleKlientChange = async (selected) => {
        if (selected.length > 0) {
            const klientId = selected[0].id;
            if (selectGroupRef.current) {
                selectGroupRef.current.clear();
            }
            if (selectProductRef.current) {
                selectProductRef.current.clear();
            }
            if (selectBillingPlanRef.current) {
                selectBillingPlanRef.current.value = '';
            }

            try {
                //const response = await axios.get(`/db/getproductsgroups/${klientId}`);
                const response = await axios.get("/db/getproductsgroups");
                setGrupaOptions(response.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    //alert("Your access is not allowed.");
                    toast.error("Your access is not allowed.");
                }
            }
            setBillingPlanOptions([
                { key: 'Wybierz', value: '', label: 'Wybierz...', selected: true, disabled: true },]);
            setFormValues({
                ...formValues, klient: klientId, produkt: '', grupa: '', billingPlan: '', Amount: null, Waluta: 'Wybierz...', WalutaProdukt: 'Wybierz...', SpecialPrice: null, PurchasePrice: null, ERPPrice: null, StartDate: null, EndDate: null
            });
            setBcmPrices([]);
            setBcmPricesStary([]);
            setSwitchChecked(false);
            axios
                .get(`/db/getCustomerBCMPrices/${klientId}`) /*, {
                params: {
                    customerId: formValues.klient,
                    productId: formValues.produkt,
                    billingPlanID: selectedBillingPlan,
                },
            })*/
                .then((response) => {
                    setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        Mailing: response.data.length > 0 ? response.data[0]?.Mailing || '' : '',
                        EmailDH: response.data.length > 0 ? response.data[0]?.EmailDH || '' : '',
                        UwagiKlient: response.data.length > 0 ? response.data[0]?.Uwagi || '' : '',
                        KodProjektuKlient: response.data.length > 0 ? response.data[0]?.KodProjektu || '' : '',
                        Waluta: response.data.length > 0 ? response.data[0]?.Waluta || '' : '',
                        Amount: 0
                    }));

                    //setCustomerFromBcmPrices(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        //alert('Your access is not allowed.');
                        toast.error('Your access is not allowed.');
                    }
                });
            //zaladowanie wszystkich rekordow cen dla klienta
            axios
                .get(`/db/getproductBCMprices/${klientId}`)
                .then((response) => {
                    setBcmPrices(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        toast.error('Your access is not allowed.');
                        //alert('Your access is not allowed.');
                    }
                });

        }
    };

    const handleGrupaChange = async (selected) => {
        if (selected.length > 0) {
            if (selected[0]?.label === 'Wybierz...') {
                if (selectGroupRef.current) {
                    selectGroupRef.current.clear();
                }
            }
            else {
                const grupaId = selected[0].id;

                try {
                    const response = await axios.get(`/db/getproductsbygroup/${grupaId}`);
                    setProduktOptions(response.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        //alert("Your access is not allowed.");
                        toast.error("Your access is not allowed.");
                    }
                }
                setFormValues({ ...formValues, grupa: grupaId });
            }
        }

    };


    const handleProduktChange = (selected) => {
        if (selected.length > 0) {
            const selectedProduktId = selected[0].id;

            if (selectBillingPlanRef.current) {
                selectBillingPlanRef.current.value = '';
            }
            // Ustaw nowe opcje dla Billing Plan
            setBillingPlanOptions([
                { key: 'Wybierz', value: '', label: 'Wybierz...', selected: true, disabled: true },
                { key: 'RM', value: 1, label: 'RM' },
                { key: 'RR', value: 2, label: 'RR' },
                { key: 'MM', value: 4, label: 'MM' },
            ]);
            // Aktualizuj stan formularza, ustawiając produkt na wybrany produkt (ID)
            setFormValues({ ...formValues, billingPlan: null, produkt: selectedProduktId, StartDate: formatDateIso8601(startDate), EndDate: formatDateIso8601(defaultEndDate) });
            //setBillingPlanOptions([]);
            /*
            const { klient } = formValues;
            axios
                .get(`/db/getproductBCMpricesSC/${klient}/${selectedProduktId}`)
                .then((response) => {
                    setBcmPricesStary(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        //alert('Your access is not allowed.');
                        toast.error('Your access is not allowed.');
                    }
                });
            */
            //zaladowanie wszystkich rekordow cen dla klienta
            const { klient } = formValues;
            axios
                .get(`/db/getproductBCMprices/${klient}/${selectedProduktId}/`)
                .then((response) => {
                    setBcmPrices(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        toast.error('Your access is not allowed.');
                        //alert('Your access is not allowed.');
                    }
                });
        }
    };
    const handleTextboxesChange = (e) => {
        let selectedValue = e.target.value;
        //if (selectedValue === '') { selectedValue = null }
        if (e.target.name === 'SaleCommission' || e.target.name === 'ERPPrice' || e.target.name === 'PurchasePrice' || e.target.name === 'SpecialPrice') {
            selectedValue = selectedValue.replace(',', '.');

            //selectedValue = parseFloat(selectedValue)
            //if (/^-?\d*[\.,]?\d*$/.test(selectedValue)) { selectedValue = parseFloat(selectedValue) }
            //else { selectedValue = null}
        }
        if (e.target.name === 'SpecialPrice' && selectedValue === '') { selectedValue = null }
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [e.target.name]: selectedValue,
        }));
    };


    const handleModalFormChange = (e) => {
        let selectedValue = e.target.value;
        if (e.target.name === 'Amount') { selectedValue = selectedValue.replace(',', '.'); }

        setSelectedRecord((prevFormValues) => ({
            ...prevFormValues,
            [e.target.name]: selectedValue,
        }));
        /*
        //if (selectedValue === '') { selectedValue = null }
        if (e.target.name === 'SaleCommission' || e.target.name === 'ERPPrice' || e.target.name === 'PurchasePrice' || e.target.name === 'SpecialPrice') {
            selectedValue = selectedValue.replace(',', '.');

            //selectedValue = parseFloat(selectedValue)
            //if (/^-?\d*[\.,]?\d*$/.test(selectedValue)) { selectedValue = parseFloat(selectedValue) }
            //else { selectedValue = null}
        }
        if (e.target.name === 'SpecialPrice' && selectedValue === '') { selectedValue = null }
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [e.target.name]: selectedValue,
        }));

        */
    };


    const handleBillingPlanChange = (e) => {
        const selectedBillingPlan = e.target.value;
        console.log('handleBP: ', formValues);
        // Aktualizuj stan formularza, ustawiając billingPlan na wybrany billingPlan
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            billingPlan: selectedBillingPlan,
        }));
        const { klient, produkt, grupa } = formValues;
        axios
            .get(`/db/getproductBCMprices/${klient}/${produkt}/${selectedBillingPlan}`)
            .then((response) => {
                setBcmPrices(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });
        axios
            .get(`/db/getproductPrices/${grupa}/${produkt}/${selectedBillingPlan}`)
            .then((response) => {
                
                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    PurchasePrice: response.data.length > 0 ? response.data[0]?.PURCHASE_Amount || '' : '',
                    ERPPrice: response.data.length > 0 ? response.data[0]?.ERP_Amount || '' : '',
                }))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    //alert('Your access is not allowed.');
                    toast.error('Your access is not allowed.');
                }
            });
        axios
            .get(`/db/getCommentsBCMPrices/${klient}/${produkt}/${selectedBillingPlan}`)
            .then((response) => {
                setBcmPricesSubComm(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });


    };

    const handleCenaChange = (e) => {
        const newAmount = parseFloat(e.target.value);
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            Amount: isNaN(newAmount) ? null : newAmount,
        }));
    };

    const handleTableRowClick = (record) => {
        setSelectedRecord(record);
        console.log('Dane record:', record);
        // Przekazujemy funkcję zwrotną do setSelectedRecord
        setSelectedRecord((prevRecord) => {
            // Tworzymy nowy obiekt zaktualizowany o nowe wartości
            const updatedRecord = {
                ...prevRecord,
                ...record, // Kopiujemy wszystkie pola z obiektu "record"
            };

            // Ustawiamy czas na 00:00:00 dla dat
            updatedRecord.EndDate = new Date(updatedRecord.EndDate);
            updatedRecord.StartDate = new Date(updatedRecord.StartDate);
            updatedRecord.EndDate.setHours(0, 0, 0, 0);
            updatedRecord.StartDate.setHours(0, 0, 0, 0);

            // Wyświetlamy zaktualizowany obiekt
            console.log('Dane selectedRecord:', updatedRecord);

            // Zwracamy zaktualizowany obiekt jako nowy stan
            return updatedRecord;
        });
        console.log('Dane selectedRecord:', selectedRecord);
        setShowModal(true);
    };
    const formatDateToString = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleCloseModal = () => {
        console.log('Dane formularza modal:', selectedRecord);
        setShowModal(false);
        setSelectedRecord(null);
    };

    const handleUpdate = () => {
        console.log(selectedRecord.StartDate)

        const formattedStartDate = formatDateToString(selectedRecord.StartDate);
        const formattedEndDate = formatDateToString(selectedRecord.EndDate);
        console.log(formattedStartDate)

        const updatedRecord = {
            ...selectedRecord,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate,
        };
        console.log('Dane formularza updated:', updatedRecord);

        // Wyślij żądanie POST do aktualizacji rekordu
        axios.post('/db/UpdateNewCennikRecord', updatedRecord)
            .then((response) => {
                handleCloseModal(); // Zamknij modal po zaktualizowaniu
                console.log('Pomyślnie zaktualizowano rekord:', response.data);
                toast.success('Pomyślnie zaktualizowano rekord.');
                updateBCMprices();
                updateClient();
                //updateBCMPricesStary();
                //setBillingPlanOptions([
                //    { key: 'Wybierz', value: '', label: 'Wybierz...', selected: true, disabled: true },]);
                //setFormValues({ ...formValues, klient: '', produkt: '', grupa: '', billingPlan: '', Amount: null, Waluta: 'EUR', SpecialPrice: null, PurchasePrice: null, ERPPrice: null, StartDate: null, EndDate: null });
                //setBcmPrices([]);
                setBcmPricesStary([]);
                //setSwitchChecked(false);
                handleCloseModal(); // Zamknij modal po zaktualizowaniu
            })
            .catch((error) => {
                //alert(`Błąd podczas aktualizacji rekordu ${error.message}`);
                toast.error(`Błąd podczas aktualizacji rekordu ${error.message}`);
                console.error('Błąd podczas aktualizacji rekordu:', error);
            });

        //updateClient();

    };

    const handleDelete = () => {
        //console.log('Dane formularza modal:', selectedRecord);
        console.log(selectedRecord.StartDate)
        //const formattedStartDate = selectedRecord.StartDate.toISOString().split('T')[0];
        const formattedStartDate = formatDateToString(selectedRecord.StartDate);
        const formattedEndDate = formatDateToString(selectedRecord.EndDate);
        console.log(formattedStartDate)

        const updatedRecord = {
            ...selectedRecord,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate,
        };
        console.log('Dane formularza updated:', updatedRecord);

        // Wyślij żądanie POST do aktualizacji rekordu
        axios.post('/db/DeleteNewCennikRecord', updatedRecord)
            .then((response) => {
                handleCloseModal(); // Zamknij modal po zaktualizowaniu
                console.log('Pomyślnie usunięto rekord:', response.data);
                toast.success('Pomyślnie usunięto rekord.');
                updateBCMprices();
                //updateBCMPricesStary();

                setBcmPricesStary([]);
                setSwitchChecked(false);
                handleCloseModal(); // Zamknij modal po zaktualizowaniu
            })
            .catch((error) => {
                toast.error(`Błąd podczas usuwania rekordu ${error.message}`);
                console.error('Błąd podczas usuwania rekordu:', error);
            });

    };

    const updateClient = () => {
        const { klient, produkt, billingPlan } = formValues;
        console.log('klient updateClient: ', klient);
        //setFormValues({
        //    ...formValues, produkt: '', grupa: '', billingPlan: '', Amount: null, Waluta: 'Wybierz...', WalutaProdukt: 'Wybierz...', SpecialPrice: null, PurchasePrice: null, ERPPrice: null, StartDate: null, EndDate: null
        //});
        axios
            .get(`/db/getCustomerBCMPrices/${klient}`)
            .then((response) => {
                console.log('getCustomerBCMPrices: ', response);
                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    Mailing: response.data.length > 0 ? response.data[0]?.Mailing || '' : '',
                    EmailDH: response.data.length > 0 ? response.data[0]?.EmailDH || '' : '',
                    UwagiKlient: response.data.length > 0 ? response.data[0]?.Uwagi || '' : '',
                    KodProjektuKlient: response.data.length > 0 ? response.data[0]?.KodProjektu || '' : '',
                    Waluta: response.data.length > 0 ? response.data[0]?.Waluta || '' : '',
                    //Amount: 0
                }));

                //setCustomerFromBcmPrices(response.data);
            })

    }

    const updateBCMprices = () => {
        const { klient, produkt, billingPlan } = formValues;
        axios
            .get(`/db/getproductBCMprices/${klient}/${produkt}/${billingPlan}`)
            .then((response) => {
                setBcmPrices(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    //alert('Your access is not allowed.');
                    toast.error('Your access is not allowed.');
                }
            });
    }

    const updateBCMPricesStary = () => {
        const { klient, produkt } = formValues;
        axios
            .get(`/db/getproductBCMpricesSC/${klient}/${produkt}`)
            .then((response) => {
                setBcmPricesStary(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    //alert('Your access is not allowed.');
                    toast.error('Your access is not allowed.');
                }
            });
    }

    const handleDisplayData = () => {
        console.log('Dane formularza:', formValues);
    };

    const handleSendButton = () => {
        console.log('Dane formularza1:', formValues);
        // Wyślij żądanie POST do aktualizacji rekordu

        if (formValues.Waluta === '' || formValues.Amount <= 0) { toast.error('Wypełnij wymagane pola: Waluta (KLIENT) i Cena są obligatoryjne.'); }
        else {
            const { WalutaProdukt, ERPPrice, PurchasePrice, ...rest } = formValues;
            const formValuesCopy = {
                ...rest,
                WalutaProdukt: WalutaProdukt === 'Wybierz...' ? '' : WalutaProdukt,
                ERPPrice: ERPPrice === '' ? 0 : ERPPrice,
                PurchasePrice: PurchasePrice === '' ? 0 : PurchasePrice,

            };
            axios.post('/db/AddPriceToNewCennik', formValuesCopy)
                .then((response) => {
                    //alert(`Rekord dodany pomyślnie ${response.data}`);
                    toast.success(`Rekord dodany pomyślnie ${response.data}`);
                    updateBCMprices();
                    updateClient();
                    //updateBCMPricesStary();
                    //clear formValues po wyslaniu dopisac
                })
                .catch((error) => {
                    //alert(`Błąd podczas dodawania rekordu ${error.message}`);
                    toast.error(`Błąd podczas dodawania rekordu ${error.message}`);
                    console.error('Błąd podczas dodawania rekordu:', error);
                });
        }
    };




    const specjalnaValue =
            formValues.SpecialPrice !== null &&
            parseFloat(formValues.SpecialPrice) > 0 &&
            formValues.Amount !== null &&
            parseFloat(formValues.Amount) > 0
            ? (
                ((parseFloat(formValues.Amount) - parseFloat(formValues.SpecialPrice)) /
                    parseFloat(formValues.SpecialPrice)) * 100
            ).toFixed(2) // Zaokrąglenie do dwóch miejsc po przecinku 
            : '0';

    const rabatValue =
            formValues.ERPPrice !== null &&
            parseFloat(formValues.ERPPrice) > 0 &&
            formValues.Amount !== null &&
            parseFloat(formValues.Amount) > 0
            ? (
                ((parseFloat(formValues.Amount) - parseFloat(formValues.ERPPrice)) /
                    parseFloat(formValues.ERPPrice)) * 100
            ).toFixed(2) // Zaokrąglenie do dwóch miejsc po przecinku 
            : '0';

    const PURCHASEvalue =
            formValues.PurchasePrice !== null &&
            parseFloat(formValues.PurchasePrice) > 0 &&
            formValues.Amount !== null &&
            parseFloat(formValues.Amount) > 0
            ? (
                ((parseFloat(formValues.Amount) - parseFloat(formValues.PurchasePrice)) /
                    parseFloat(formValues.PurchasePrice)) * 100
            ).toFixed(2) // Zaokrąglenie do dwóch miejsc po przecinku 
            : '0'

    useEffect(() => {
        const updateSaleCommission = () => {
            if (!switchChecked) {
                const currentSpecjalnaValue =
                    formValues.SpecialPrice !== null &&
                        parseFloat(formValues.SpecialPrice) > 0 &&
                        formValues.Amount !== null &&
                        parseFloat(formValues.Amount) > 0
                        ? (
                            ((parseFloat(formValues.Amount) - parseFloat(formValues.SpecialPrice)) /
                                parseFloat(formValues.SpecialPrice)) * 100
                        ).toFixed(2)
                        : '0';

                const currentPURCHASEvalue =
                    formValues.PurchasePrice !== null &&
                        parseFloat(formValues.PurchasePrice) > 0 &&
                        formValues.Amount !== null &&
                        parseFloat(formValues.Amount) > 0
                        ? (
                            ((parseFloat(formValues.Amount) - parseFloat(formValues.PurchasePrice)) /
                                parseFloat(formValues.PurchasePrice)) * 100
                        ).toFixed(2)
                        : '0';

                const saleCommission =
                    formValues.SpecialPrice !== null ? parseFloat(currentSpecjalnaValue) : parseFloat(currentPURCHASEvalue);

                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    SaleCommission: parseFloat(saleCommission),
                }));

                console.log('updateSaleCommission:', formValues);
            }
        };

        updateSaleCommission();

        // Automatyczne wywołanie funkcji przy zmianach w formValues i switchChecked
    }, [formValues.Amount, formValues.PurchasePrice, formValues.SpecialPrice, switchChecked]);

    const handleButtonProwizjaChange = () => {
        if (parseFloat(formValues.SaleCommission) > 0) {
            const sale = formValues.SpecialPrice !== null ? formValues.SpecialPrice : formValues.PurchasePrice;
            const newPrice = (parseFloat(sale) * (parseFloat(formValues.SaleCommission) / 100 + 1)).toFixed(2)

            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                //[e.target.name]: selectedValue,
                Amount: newPrice,
            }))
        }
    };

    // Funkcja do obsługi wyboru Id z Typeahead
    const handleSubTypeaheadChange = (selected) => {
        const { klient, produkt, billingPlan } = formValues;
        axios
            .get(`/db/getCommentsBCMPrices/${klient}/${produkt}/${billingPlan}`)
            .then((response) => {
                setBcmPricesSubComm(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });
        if (selected.length > 0) {
            console.log('selected', selected[0].id);
            // const selectedId = selected[0]; // Wybrane Id (może być puste)
            setSelectedSub(selected[0].id);

            // Znajdujemy rekord po Id
            const selectedRecord = bcmPricesSubComm.find((item) => item.Id === selected[0].id);

            // Jeśli rekord istnieje, ustawiamy wartość pola Comment w stanie formValues
            if (selectedRecord) {
                setSelectedSubComment(selectedRecord.Comment || "")
            }
        }
        else { setSelectedSubComment("") }
    };
    // Funkcja przechwytywanie zmiany w text input komentarza
    const handleSubCommChange = (e) => {
        setSelectedSubComment(e.target.value);

    };
    // Funkcja formatująca datę do formatu yyyy-mm-dd
    const formatDateComm = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące w JavaScript zaczynają się od 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    // opcje do Typeahead komentarzy per id sub, złączając Id i sformatowaną datę CreatedAt
    const optionsSubComm = bcmPricesSubComm.map((item) => ({
        id: item.Id,
        display: `${item.Id} | Created: ${formatDateComm(item.CreatedAt)} | Q: ${item.Quantity} `
    }));

    const handleUpdateCommButton = async () => {

        const payload = {
            SubscriptionId: selectedSub,  // Id subskrypcji
            Comment: selectedSubComment,  // Komentarz
        };
        /*
        if (selectedSubComment.length > 0) {
            toast.error(payload.SubscriptionId);

        }
        */
        if (selectedSub) {
            try {
                const response = await axios.post('/db/UpdateCommentsBCMPrices', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Sprawdzenie odpowiedzi
                if (response.status === 200) {
                    toast.success('Aktualizacja powiodła się', response.data);
                    setSelectedSub(null);
                    const { klient, produkt, billingPlan } = formValues;
                    axios
                        .get(`/db/getCommentsBCMPrices/${klient}/${produkt}/${billingPlan}`)
                        .then((response) => {
                            setBcmPricesSubComm(response.data);
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 401) {
                                toast.error('Your access is not allowed.');
                                //alert('Your access is not allowed.');
                            }
                        });

                } else {
                    toast.error('Błąd aktualizacji', response.status);
                }
            } catch (error) {
                toast.error('Błąd w żądaniu POST', error);
            }
        }
 
        
    };
    return (
        
        <div className="container mt-4">
            <ToastContainer />
            <div className="border p-2 mb-4 my-form">
                <Form>
                    <Row>
                        <Col>
                                <Form.Group controlId="formKlient">
                                    <Form.Label>Klient</Form.Label>
                                    <Typeahead
                                        ref={selectCustomerRef}
                                        //selected={selectedCustomerOption}
                                        id="klientTypeahead"
                                        labelKey="name"
                                        options={klientOptions}
                                        onChange={handleKlientChange}
                                        placeholder="Wpisz lub wybierz klienta..."
                                    />
                                </Form.Group>
                        </Col>
                            <Col>
                                <Form.Group controlId="formGrupa">
                                    <Form.Label>Grupa</Form.Label>
                                    <Typeahead
                                        ref={selectGroupRef}
                                        //selected={selectedGroupOption}
                                        id="grupaTypeahead"
                                        labelKey="name" 
                                        options={grupaOptions}
                                        onChange={handleGrupaChange}
                                        placeholder="Wpisz lub wybierz grupę..."
                                    />
                                </Form.Group>
                        </Col>
                        <Col>
                                <Form.Group controlId="formProdukt">
                                    <Form.Label>Produkt</Form.Label>
                                    <Typeahead
                                        ref={selectProductRef}
                                        //selected={selectedProductOption}
                                        id="produktTypeahead"
                                        labelKey="name"
                                        options={produktOptions}
                                        onChange={handleProduktChange}
                                        placeholder="Wpisz lub wybierz produkt..."
                                    />
                                </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                                <Form.Group controlId="formBillingPlan">
                                    <Form.Label>Billing Plan</Form.Label>
                                    <Form.Control ref={selectBillingPlanRef}
                                        as="select"
                                        className="custom-select"
                                        defaultValue="Wybierz..."
                                        placeholder="Wybierz..."
                                        onChange={handleBillingPlanChange}
                                    >
                                        
                                        {billingPlanOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formWaluta" className="d-flex">
                                <div className="mr-2 w-50">
                                    <Form.Label>Waluta (KLIENT)</Form.Label>
                                    <Form.Control
                                        name="Waluta"
                                        as="select"
                                        onChange={handleTextboxesChange}
                                        value={formValues.Waluta}

                                        className="custom-select"
                                    >
                                        {opcjeWalut.map(opcja => (
                                            <option key={opcja.key} value={opcja.value}>
                                                {opcja.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>

                                <div className="w-50">
                                    <Form.Label>Waluta (PRODUKT)</Form.Label>
                                    <Form.Control
                                        name="WalutaProdukt"
                                        as="select"
                                        onChange={handleTextboxesChange}
                                        value={formValues.WalutaProdukt}
                                        className="custom-select"
                                    >
                                        {opcjeWalut.map(opcja => (
                                            <option key={opcja.key} value={opcja.value}>
                                                {opcja.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formCena">
                                <Form.Label>Cena</Form.Label>
                                    <Form.Control
                                        type="number" step="0.01" placeholder="Cena"
                                        className="form-control"
                                        value={formValues.Amount}
                                        onChange={handleCenaChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formProwizja">
                                <Form.Label>Marża %</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        //id="custom-switch"
                                        //label="Przełącz"
                                        checked={switchChecked}
                                        onChange={() => setSwitchChecked(!switchChecked)}
                                        className="ml-2"
                                    />
                                    <Form.Control name="SaleCommission" type="text" placeholder="Marża" className="form-control" value={formValues.SaleCommission} onChange={handleTextboxesChange} disabled={!switchChecked} />  
                                    <Button variant="primary" onClick={handleButtonProwizjaChange} className="ml-2">
                                        Oblicz
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <div className="border p-2">
                                <Form.Group controlId="formStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEndDate">
                                    <Form.Label>End Date&nbsp;</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        selected={defaultEndDate}
                                        onChange={handleEndDateChange}
                                        dateFormat="yyyy-MM-dd"

                                    />
                                </Form.Group>
                            </div>
                        </Col>
                        <Col>
                            <div className="border p-1 d-flex">
                                <Form.Group controlId="formPrCs" className="mr-2">
                                    <Form.Label>Cena specjalna</Form.Label>
                                    <Form.Control name="SpecialPrice" type="number" step="0.01" className="form-control" onChange={handleTextboxesChange} title="Jeśli została wynegocjowana należy uzupełnić pole. W przeciwnym razie pole powinno pozostać puste, by nie zostało przekazane do bazy danych." />
                                    <Form.Control type="text" readOnly disabled value={`${specjalnaValue}%`} className="form-control" />
                                </Form.Group>
                                <Form.Group controlId="formPrPurchase" className="mr-2">
                                    <Form.Label>Aktualna PURCHASE</Form.Label>
                                    <Form.Control type="text" readOnly disabled value={formValues.PurchasePrice} className="form-control" />
                                    <Form.Control type="text" readOnly disabled value={`${PURCHASEvalue}%`} className="form-control" />
                                </Form.Group>
                                <Form.Group controlId="formPrSrp">
                                    <Form.Label>Aktualna ERP</Form.Label>
                                    <Form.Control type="text" readOnly disabled value={formValues.ERPPrice} className="form-control" />
                                    <Form.Control title="RABAT" type="text" readOnly disabled value={`${rabatValue}%`}
                                            className = "form-control" />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group controlId="formMailing">
                                <Form.Label>Mailing (KLIENT)</Form.Label>
                                <Form.Control name="Mailing" type="text" className="form-control" onChange={handleTextboxesChange} value={formValues.Mailing} />
                            </Form.Group>
                            <Form.Group controlId="formEmailDh">
                                <Form.Label>Email DH (KLIENT)</Form.Label>
                                <Form.Control name="EmailDH" type="text" className="form-control" onChange={handleTextboxesChange} value={formValues.EmailDH} />
                            </Form.Group>
                            <Form.Group controlId="formKodProjektuKlient">
                                <Form.Label>Kod Projektu (KLIENT)</Form.Label>
                                <Form.Control name="KodProjektuKlient" type="text" className="form-control" onChange={handleTextboxesChange} value={formValues.KodProjektuKlient} />
                            </Form.Group>
                            <Form.Group controlId="formKodProjektuProdukt">
                                <Form.Label>Kod Projektu (PRODUKT)</Form.Label>
                                <Form.Control name="KodProjektuProdukt" type="text" className="form-control" onChange={handleTextboxesChange} value={formValues.KodProjektuProdukt} title="Gdy pole nie zostanie wypełnione w raporcie wykorzystane będzie pole kodprojektu na poziomie Klienta (powyżej)." />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formUwagiKlient">
                                <Form.Label>Uwagi (KLIENT)</Form.Label>
                                <Form.Control name="UwagiKlient" as="textarea" rows={4} className="form-control" onChange={handleTextboxesChange} value={formValues.UwagiKlient} />
                            </Form.Group>
                            <Form.Group controlId="formUwagiProdukt">
                                <Form.Label>Uwagi (PRODUKT)</Form.Label>
                                <Form.Control name="UwagiProdukt" as="textarea" rows={4} className="form-control" onChange={handleTextboxesChange} value={formValues.UwagiProdukt} />
                            </Form.Group>
                        </Col>
                    </Row>
                        <Button variant="primary"  onClick={handleSendButton}>
                        Dodaj
                    </Button>
                    </Form>
                    
                </div>
            {/* Tabela z wynikami */}

            {bcmPricesSubComm.length > 1 && (
                <div>
                    <h6>Klient posiada kilka subskrypcji tego produktu. Możesz dodać uwagi do każdej z nich:</h6>
                    <Form onSubmit={handleUpdate}>
                        <Row className="mb-2">
                            <Col xs={3}>
                                {/* Typeahead - autouzupełnianie */}
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="display"
                                    onChange={handleSubTypeaheadChange}
                                    //options={bcmPricesSubComm.map((item) => item.Id)}
                                    options={optionsSubComm}
                                    
                                    placeholder="Wybierz"
                                    //selected={selectedOption}
                                />
                            </Col>
                            <Col xs={8}>
                                {/* Pole tekstowe */}
                                <Form.Group controlId="formMailing">

                                    <Form.Control name="SubComment" type="text" className="form-control" onChange={handleSubCommChange} value={selectedSubComment} />
                                </Form.Group>
                            </Col>
                            <Col xs={1}>
                                {/* Przycisk Update */}
                                <Button variant="primary" onClick={handleUpdateCommButton}>
                                    Aktualizuj
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <br/>
                </div>               
            )}

            {bcmPrices.length > 0 && (
                <div>
                    <h6>Rekordy z nowego cennika:</h6>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Product</th>
                                <th>Group</th>
                                <th>BillingPlan</th>
                                <th>StartDate</th>
                                <th>EndDate</th>
                                <th>Currency</th>
                                <th>Amount</th>
                                <th>SaleCommission</th>
                                <th>SpecialPrice</th>
                                <th>PurchasePrice</th>
                                <th>ERPPrice</th>
                                <th>KodProjektu</th>
                                <th>Uwagi</th>
                                <th>CreatedAt</th>
                                <th>UpdatedAt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bcmPrices.map((record) => (
                                <tr key={record.id} onClick={() => handleTableRowClick(record)}>
                                    <td>{record.Id}</td>
                                    <th>{record.ProductName}</th>
                                    <th>{record.ProductGroup}</th>
                                    <th>{record.BillingPlan}</th>
                                    <td>{formatDate(record.StartDate)}</td>
                                    <td>{formatDate(record.EndDate)}</td>
                                    <td>{record.Currency}</td>
                                    <td>{record.Amount}</td>
                                    <td>{record.SaleCommission}%</td>
                                    <td>{record.SpecialPrice}</td>
                                    <td>{record.PurchasePrice}</td>
                                    <td>{record.ERPPrice}</td>
                                    <td>{record.KodProjektu}</td>
                                    <td>{record.Uwagi}</td>
                                    <td>{formatDate(record.DBCreatedAt)}</td>
                                    <td>{record.DBUpdatedAt}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    </div>
                )}

            {/* Tabela z wynikami starego cennika*/}
            {bcmPricesStary.length > 0 && (
                <div>
                    <h6>Rekordy ze starego cennika:</h6>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Klient</th>
                            <th>KlientId</th>
                            <th>Produkt</th>
                            <th>ProduktId</th>
                            <th>Grupa</th>
                            <th>CenaRok</th>
                            <th>CenaMiesiac</th>
                            <th>Waluta</th>
                            <th>Mailing</th>
                            <th>EmailDH</th>
                            <th>Uwagi</th>
                            <th>KodProjektu</th>
                            <th>CreatedAt</th>
                            <th>UpdatedAt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bcmPricesStary.map((record) => (
                            <tr key={record.id} >
                                <td>{record.Id}</td>
                                <td>{record.KlientNazwa}</td>
                                <td>{record.ABKlientId}</td>
                                <td>{record.Produkt}</td>
                                <td>{record.ABProduktId}</td>
                                <td>{record.ABProduktGroupId}</td>
                                <td>{record.CenaRok}</td>
                                <td>{record.CenaMiesiac}</td>
                                <td>{record.Waluta}</td>
                                <td>{record.Mailing}</td>
                                <td>{record.EmailDH}</td>
                                <td>{record.Uwagi}</td>
                                <td>{record.KodProjektu}</td>
                                <td>{record.DBCreatedAt}</td>
                                <td>{record.DBModifiedAt}</td>
                            </tr>
                        ))}
                    </tbody>
                    </Table>
                </div>
            )}


                {/* Modal z komunikatem */}
            <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">

                <Modal.Header closeButton>
                    <Modal.Title>Edycja rekordu cennika</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRecord && (
                        <Form>
                            <Row>
                                <Col>
                                    {/* KLIENT */}
                                    <Form.Group label="Mailing (KLIENT)" controlId="formMailing">
                                        <Form.Label className="custom-label">Mailing (KLIENT)</Form.Label>
                                        <Form.Control
                                            name="Mailing"
                                            type="text"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.Mailing}
                                        />
                                    </Form.Group>
                                    <Form.Group label="Email DH (KLIENT)" controlId="formEmailDh">
                                        <Form.Label className="custom-label">Email DH (KLIENT)</Form.Label>
                                        <Form.Control
                                            name="EmailDH"
                                            type="text"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.EmailDH}
                                        />
                                    </Form.Group>
                                    <Form.Group label="Kod Projektu (KLIENT)" controlId="formKodProjektuKlient">
                                        <Form.Label className="custom-label">Kod Projektu (KLIENT)</Form.Label>
                                        <Form.Control
                                            name="KodProjektuKlient"
                                            type="text"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.KodProjektuKlient}
                                        />
                                    </Form.Group>
                                    <Form.Group label="Kod Projektu (PRODUKT)" controlId="formKodProjektuProdukt">
                                        <Form.Label className="custom-label">Kod Projektu (PRODUKT)</Form.Label>
                                        <Form.Control
                                            name="KodProjektu"
                                            type="text"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.KodProjektu}
                                            title="Gdy pole nie zostanie wypełnione w raporcie wykorzystane będzie pole kodprojektu na poziomie Klienta (powyżej)."
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {/* KLIENT */}
                                    <Form.Group label="Uwagi (KLIENT)" controlId="formUwagiKlient">
                                        <Form.Label className="custom-label">Uwagi (KLIENT)</Form.Label>
                                        <Form.Control
                                            name="UwagiKlient"
                                            as="textarea"
                                            rows={4}
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.UwagiKlient}
                                        />
                                    </Form.Group>
                                    {/* PRODUKT */}
                                    <Form.Group label="Uwagi (PRODUKT)" controlId="formUwagiProdukt">
                                        <Form.Label className="custom-label">Uwagi (PRODUKT)</Form.Label>
                                        <Form.Control
                                            name="Uwagi"
                                            as="textarea"
                                            rows={4}
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.Uwagi}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {/* Waluta (KLIENT) */}
                                <Col className="mr-2 w-50">
                                    <Form.Group label="Waluta (KLIENT)">
                                        <Form.Label className="custom-label">Waluta (KLIENT)</Form.Label>
                                        <Form.Control
                                            name="WalutaKlient"
                                            as="select"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.WalutaKlient}
                                            className="custom-select"
                                        >
                                            {opcjeWalut.map(opcja => (
                                                <option key={opcja.key} value={opcja.value}>
                                                    {opcja.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                {/* Waluta (PRODUKT) */}
                                <Col className="w-50">
                                    <Form.Group label="Waluta (PRODUKT)">
                                        <Form.Label className="custom-label">Waluta (PRODUKT)</Form.Label>
                                        <Form.Control
                                            name="Currency"
                                            as="select"
                                            onChange={handleModalFormChange}
                                            value={selectedRecord.Currency}
                                            className="custom-select"
                                        >
                                            {opcjeWalut.map(opcja => (
                                                <option key={opcja.key} value={opcja.value}>
                                                    {opcja.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* Id */}
                                    <Form.Group label="Id:">
                                        <Form.Label className="custom-label">tBCMP Id</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={selectedRecord.Id}
                                            readOnly
                                            disabled
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {/* Amount */}
                                    <Form.Group label="Amount:">
                                        <Form.Label className="custom-label">Cena</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Amount"
                                            defaultValue={selectedRecord.Amount}
                                            onChange={handleModalFormChange}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="my-4"></div>
                            <Row>
                                {/* StartDate */}
                                <Col className="mr-1 w-50">
                                    <Form.Group label="StartDate:">
                                        <Form.Label className="custom-label with-margin">StartDate</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            selected={selectedRecord.StartDate}
                                            onChange={handleModalStartDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            locale="pl"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="w-50"></Col>
                            </Row>
                            <Row>
                                {/* EndDate */}
                                <Col className="w-50">
                                    <Form.Group label="End Date:">
                                        <Form.Label className="custom-label with-margin2">EndDate</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            selected={selectedRecord.EndDate}
                                            onChange={handleModalEndDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            locale="pl"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                </Modal.Footer>
                </Modal>
            
            </div>
        
    );
};

export default BcmPriceList;
