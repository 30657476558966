import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { BarChart, Legend, Bar, PieChart, Pie, Cell } from 'recharts';
import Title from './Title';
import axios from "axios";
import '../Table.css';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const formatTooltip = (value, name, props) => {
    const total = props.payload.reduce((acc, item) => acc + item.value, 0);
    const percent = (value / total) * 100;
    return [`${name}: ${value}`, `${percent.toFixed(2)}%`];
};

function CustomLegend({ payload }) {
    return (
        <ul>
            {payload.map((entry, index) => (
                <li key={`item-${index}`}>
                    <span style={{ color: entry.color }}>{entry.value}: </span>
                    {entry.payload.Amount}
                </li>
            ))}
        </ul>
    );
}

export default function ChartCReports() {
    const theme = useTheme();
    const [data, setDatas] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/GetCustomersMonthlyReport/")
                .then((response) => {
                    setDatas(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });
        })();
    }, [setDatas]);
    const [searchTerm, setSearchTerm] = React.useState('');
    function handleSearchTermChange(event) {
        setSearchTerm(event.target.value);
    }
    const filteredData = data.filter(item =>
        item.Customer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const totalAmount = data.reduce((acc, item) => acc + item.Amount, 0);

    // Loop through the data to calculate the percentage for each item
    const dataWithPercent = filteredData.map(item => ({
        ...item,
        percent: item.Amount / totalAmount,
    }));


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#ff7300'];

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            //backgroundColor: theme.palette.primary.main,
            //color: theme.palette.common.white,
            fontSize: 12,
            fontWeight: 'bold'            
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
        },
    })); 

    return (
    <React.Fragment>
            <Title>Ostatni raport</Title>
            <input
                type="text"
                placeholder="Szukaj klienta..."
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Klient</StyledTableCell>
                            <StyledTableCell>Kwota (€)</StyledTableCell>
                                <StyledTableCell>% Raportu</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {dataWithPercent.map(item => (
                            <TableRow key={item.Customer} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <StyledTableCell>{item.Customer}</StyledTableCell>
                                <StyledTableCell>{item.Amount}</StyledTableCell>
                                <StyledTableCell className="percent">{(item.percent * 100).toFixed(2)}%</StyledTableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            
    </React.Fragment>
  );
}
