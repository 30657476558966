import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';

function preventDefault(event) {
    event.preventDefault();
}

function Row(props) {
    const { order } = props;
    const [open, setOpen] = React.useState(false);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{order.Type}</StyledTableCell>
                <StyledTableCell>{order.Name}</StyledTableCell>
                <StyledTableCell>{order.Firstname}</StyledTableCell>
                <StyledTableCell>{order.Lastname}</StyledTableCell>
                <StyledTableCell>{order.Phone}</StyledTableCell>
                <StyledTableCell>{order.Email}</StyledTableCell>
                <StyledTableCell>{order.IsActive}</StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>CustomerId</StyledTableCell>
                                        <StyledTableCell>TaxId</StyledTableCell>
                                        <StyledTableCell>State</StyledTableCell>
                                        <StyledTableCell>City</StyledTableCell>
                                        <StyledTableCell>Address</StyledTableCell>
                                        <StyledTableCell>Postcode</StyledTableCell>
                                        <StyledTableCell>CreatedAt</StyledTableCell>
                                        <StyledTableCell>DBCreatedAt</StyledTableCell>
                                        <StyledTableCell>DBUpdatedAt</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={order.Id} >
                                        <StyledTableCell component="th" scope="row">{order.Id}</StyledTableCell>
                                            <StyledTableCell>{order.TaxId}</StyledTableCell>
                                            <StyledTableCell>{order.State}</StyledTableCell>
                                        <StyledTableCell>{order.City}</StyledTableCell>
                                        <StyledTableCell>{order.Address}</StyledTableCell>
                                        <StyledTableCell>{order.Postcode}</StyledTableCell>
                                        <StyledTableCell>{order.CreatedAt}</StyledTableCell>
                                        <StyledTableCell>{order.DBCreatedAt}</StyledTableCell>
                                        <StyledTableCell>{order.DBUpdatedAt}</StyledTableCell>
                                        </TableRow>
                                    
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}



export default function Customers() {
    //const { instance } = useMsal();
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/getcustomerslist/")
                .then((response) => {
                    setOrders(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });
            setLoading(false);
            //setOrders(response);
        })();
    }, [setOrders]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            //backgroundColor: "#e4e7eb",
            //color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    let navigate = useNavigate();
    const routeChange = () => {
        let path = "/orders";
        navigate(path);
    }

    const renderOrdersTable = (orders) => {
        return (
        <React.Fragment>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                <Title>Klienci</Title>
                <TableContainer >
                    <Table size="small" aria-label="collapsible table" padding="checkbox">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                    <StyledTableCell>CustomerName</StyledTableCell>
                                    <StyledTableCell>FirstName</StyledTableCell>
                                    <StyledTableCell>LastName</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>IsActive</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {orders.map((order, index) => (
                                    <Row key={index} order={order} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            </React.Fragment>
        )
    }

    let contents = loading
        ? (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
                <p><em>&nbsp;&nbsp;Loading... trwa ładowanie danych - stay calm:) ...</em></p>
            </Box>
        )
        : renderOrdersTable(orders);

    return (
        <>
            {contents}
        </>
    );
}





