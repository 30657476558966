import * as React from 'react';

import Title from './Title';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const Vulnerabilities = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]); //usery wylaczone z licencja przypisana
    const [isTableExpanded, setIsTableExpanded] = useState(true); //rozwijanie tabeli usery wylaczone z licencja przypisana

    const handleToggleTable = () => {
        setIsTableExpanded(prevState => !prevState);
    };

    const [slideIndex, setSlideIndex] = React.useState(0);

    const handleSlideChange = (step) => {
        const newIndex = slideIndex + step;
        if (newIndex >= 0 && newIndex < datas.length) {
            setSlideIndex(newIndex);
            console.log(newIndex);
        }
    };
    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    React.useEffect(() => {
        (async () => {
            const url = "/atp/getVulnerabilitiesSD"
            setLoading(true);
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            console.log('rasponse:'+ JSON.stringify(response.data, null, 2));
            console.log(datas);
            setLoading(false);
            setIsTableExpanded(false);
        })();
    }, [setDatas]);

    return (
        <React.Fragment>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div style={{ overflowX: 'auto', marginBottom: '1rem' }}>
                    Nowe podatności
                </div>
                    <div style={{ overflowX: 'auto' }}>
                        {loading ? (
                            <Box sx={{ display: 'flex' }}><CircularProgress color="success" /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
                        ) : (                         
                            <div>
                                    {datas.map((obj, index) => (
                                        <div key={index} style={{ display: index === slideIndex ? 'block' : 'none' }}>
                                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th style={tableHeaderStyle}>Id (Name)</th>
                                                    <th style={tableHeaderStyle}>Severity</th>
                                                    <th style={tableHeaderStyle}>ExposedMachines</th>
                                                    <th style={tableHeaderStyle}>PublicExploit</th>
                                                    <th style={tableHeaderStyle}>ExploitVerified</th>
                                                    <th style={tableHeaderStyle}>ExploitInKit</th>
                                                    <th style={tableHeaderStyle}>PublishedOn</th>
                                                    <th style={tableHeaderStyle}>UpdatedOn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={tableCellStyle}>{obj.id} ({obj.name})</td>
                                                    <td style={tableCellStyle}>{obj.severity}</td>
                                                    <td style={tableCellStyle}>{obj.exposedMachines}</td>
                                                    <td style={tableCellStyle}>{obj.publicExploit ? "Yes" : "No"}</td>
                                                    <td style={tableCellStyle}>{obj.exploitVerified ? "Yes" : "No"}</td>
                                                    <td style={tableCellStyle}>{obj.exploitInKit ? "Yes" : "No"}</td>
                                                    <td style={tableCellStyle}>{obj.publishedOn}</td>
                                                    <td style={tableCellStyle}>{obj.updatedOn}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="8" style={tableCellStyle}>{obj.description}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                                    {datas.length > 1 && (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                                            <div>
                                                <IconButton onClick={() => handleSlideChange(-1)} disabled={slideIndex === 0}><NavigateBeforeIcon /></IconButton>
                                                {slideIndex + 1} / {datas.length}
                                                <IconButton onClick={() => handleSlideChange(1)} disabled={slideIndex === datas.length - 1}><NavigateNextIcon /></IconButton>
                                            </div>
                                            
                                        </div>
                                )}
                            </div>

                        )}
                    </div>
            </Paper>
        </React.Fragment>
                    
 
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default Vulnerabilities;
