import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
//import Modal from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal, Container } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';  //powiadomienia



const Jira = () => {
    const [validationError, setValidationError] = useState('');
    let timeoutId = null;
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [upnInput, setUpnInput] = useState("");
    const [selectedPM, setSelectedPM] = useState([]); //do funkcji czyszczacej
    const [selectedDH, setSelectedDH] = useState([]); //do funkcji czyszczacej
    const [selectedCK, setSelectedCK] = useState([]); //do funkcji czyszczacej
    const [selectedDepartment, setSelectedDepartment] = useState([]); //do funkcji czyszczacej


    const [accountData, setAccountData] = useState({
        firstName: "",
        lastName: "",
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        position: "",
        department: "",
        manager: "",
    });

    const inputRef = useRef(null);

    const [optionsDEPARTMENTs, setOptionsDEPARTMENTs] = useState([]);

    const getDeparts = async () => {
        try {
            const url = `/jira/GetBCMDeparts`;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            /*// Filtruj rekordy, które mają atrybut active ustawiony na true
            const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            activeManagers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            */
            //setOptionsMANAGERs(responseData);
            setOptionsDEPARTMENTs(responseData);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getDeparts();
    }, []);

    /*
    const optionsDEPARTMENTs = [
        { id: 9, Department: 'LoB HiT' },
        { id: 1, Department: 'LoB HiT - CK Microsoft' },
        { id: 2, Department: 'LoB HiT - CK Service' },
        { id: 7, Department: 'LoB HiT - CK ITIL' },
        { id: 10, Department: 'LoB HiT - CK IA' },
        { id: 3, Department: 'LoB Smart WorkPlace' },
        { id: 8, Department: 'LoB Edukacja' },
        { id: 4, Department: 'LoB HiT - CK RI' },
        { id: 5, Department: 'Zarząd' },
        { id: 6, Department: 'Marketing' },      
        { id: 11, Department: 'Logistyka' },
        { id: 12, Department: 'Administracja' },
        { id: 13, Department: 'HR' },
        { id: 14, Department: 'DH Enterprise' },
        { id: 15, Department: 'DH Corporate' },
        { id: 16, Department: 'Rada Nadzorcza' },
        { id: 17, Department: 'Control System Software' },
        { id: 18, Department: 'Księgowość' },
        { id: 19, Department: 'Finanse i Księgowość' },
        { id: 20, Department: 'VIA Dystrybucja' },
        { id: 21, Department: 'VIA TMS' },
        { id: 22, Department: 'TMS Apollo' }
    ];
    */
    const [optionsMANAGERs, setOptionsMANAGERs] = useState([]);
    const [selectedOptionMANAGER, setSelectedOptionMANAGER] = useState([]);

    const getManagers = async () => {
        try {
            const url = `/jira/getusers`;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            activeManagers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsMANAGERs(activeManagers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getManagers();
    }, []);

    const [optionsDHUsers, setOptionsDHUsers] = useState([]);
    const [selectedOptionDHUSER, setSelectedOptionDHUSER] = useState([]);

    const getDHUsers = async () => {
        try {
            var department = "Dział Handlowy";
            var encodedDepartment = encodeURIComponent(department);
            const url = `/graph/getUsersByDep/` + encodedDepartment;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            //const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            const activeUsers = responseData
            activeUsers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsDHUsers(activeUsers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getDHUsers();
    }, []);

    const validateProjectName = async (name) => {
        try {
            const url = `/jira/validprojectname/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy projektu:', error);
            return false;
        }
    };

    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    const formatValue = (value) => {
        if (value === null) {
            return "null";
        } else if (typeof value === "boolean") {
            return value ? "True" : "False";
        } else {
            return value;
        }
    };

    const [formData, setFormData] = useState({
        projectPurpose: 'komercyjny',
        projectConfig: '',
        projectCategory: '',
        projectName: '',
        projectDescription: '',
        projectCK: '',
        projectPM: '',
        projectDH: '',
        projectKlientNazwa: '',
        projectKlientAdres: '',
        projectKlientNip: '',
        projectCurrency: 'PLN',
        projectTermin: '7',
        projectUwagi: '',
        projectCreatedBy: accounts[0]?.username,
        projectCreatedAt: '',
        projectProduct: ''
    });
    /*const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleChangeDepartment = (selectedOptions) => {
        const selectedDepartment = selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        setFormData(prevState => ({
            ...prevState,
            selectedDepartment: selectedDepartment
        }));
    };*/
    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja
        //console.log(name);
        if (name === 'projectName') {
            // Anuluj poprzednie zdarzenie setTimeout
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                // Walidacja nazwy projektu
                const isValid = await validateProjectName(newValue);
                if (!isValid) {
                    setValidationError('Ta nazwa jest nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                   // toast.warn('Ta nazwa jest nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                } else {
                    setValidationError('');
                }
            }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
            
        }
        if (id === 'projectDH') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].userPrincipalName : '';
        }
        if (id === 'projectPM') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].accountId : '';
        }
        if (id === 'projectCK') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        }
        if (name === 'projectKlientNip') {
            newValue = newValue.replace(/[^\d-]/g, ''); // Usunięcie znaków niebędących cyfrą ani '+'
            /*
            // Sprawdzenie, czy wprowadzony numer pasuje do wzorca
            const nipPattern = /^-*(?:\d-*){10}$/;
            if (!nipPattern.test(newValue)) {
                return; // Przerywa dalsze przetwarzanie
            }
            */
        }

        setFormData(prevState => ({
            ...prevState,
            [name || id]: newValue || selectedValue
        }));

        //console.log(formData)
    };



    const handleClear = () => {
        setFormData({
            projectPurpose: 'komercyjny',
            projectConfig: '',
            projectCategory: '',
            projectName: '',
            projectDescription: '',
            //projectCK: '',
            projectPM: '',
            projectDH: '',
            projectKlientNazwa: '',
            projectKlientAdres: '',
            projectKlientNip: '',
            projectCurrency: 'PLN',
            projectTermin: '7',
            projectUwagi: '',
            projectCreatedBy: accounts[0]?.username,
            projectCreatedAt: '',
            projectProduct: ''
        });
        setSelectedPM([]);
        setSelectedDH([]);
        setSelectedCK([]);
        //setSelectedDepartment([]);


        /*
        const typeahead = document.getElementById('selectedHANDLOWIEC');
        if (typeahead) {
            typeahead.clear();
        }*/
    };

    const [myRequests, setMyRequests] = useState([]); //wnioski zlozone przez zalogowanego uzytkownika
    const getMyRequests = async () => {
        const { projectCreatedBy } = formData;
        axios
            .get(`/jira/getProjects/${projectCreatedBy}/`)
            .then((response) => {
                setMyRequests(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });
    };
    useEffect(() => {
        getMyRequests();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        await SendForm();
        
        
    };

    const SendForm = async () => {
        if (formData.projectPurpose === '' ||
            //formData.projectConfig === '' ||
            //formData.projectCategory === '' ||
            formData.projectName === '' ||
            formData.projectCK === '' ||
            //formData.projectPM === '' ||
            formData.projectDH === '' ||
            formData.projectKlientNazwa === '' ||
            formData.projectKlientAdres === '' ||
            formData.projectKlientNip === '' ||
            formData.projectCurrency === '' ||
            formData.projectTermin === '' ||
            formData.projectProduct === '') { toast.error('Wypełnij wszystkie wymagane pola.'); }
        else {
            axios.post('/jira/newCommercialProject', formData) //bylo formDataCopy
                .then((response) => {
                    //alert(`Rekord dodany pomyślnie ${response.data}`);
                    toast.success(`Twój wniosek został wysłany ${response.data}`);
                    getMyRequests();
                    handleClear();

                })
                .catch((error) => {
                    //alert(`Błąd podczas dodawania rekordu ${error.message}`);
                    toast.error(`Błąd podczas dodawania rekordu ${error.message}`);
                    console.error('Błąd podczas dodawania rekordu:', error);
                });
        }
    };
    return (
        <div className="container mt-4">
            <ToastContainer />
            <div className="border p-2 mb-4 my-form">
                <Container>
                    <Form onSubmit={handleSubmit} className="my-form" >
                        <Row className="mb-2">

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPurpose" className="form-group">
                                <Form.Label>Przeznaczenie projektu</Form.Label>
                                <Form.Select disabled name="projectPurpose" value={formData.projectPurpose} onChange={(e) => handleChange(e, null, null)} className="custom-select" placeholder="Wybierz...">
                                    
                                    <option value="komercyjny" selected>KOMERCYJNY</option>
                                    <option value="neutral">NEUTRAL</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectConfig" className="form-group">
                                <Form.Label>Konfiguracja</Form.Label>
                                <Form.Select disabled name="projectConfig" value={formData.projectConfig} onChange={handleChange} className="custom-select" placeholder="Wybierz...">
                                    <option value="" selected>Wybierz...</option>
                                    <option value="kanban">Kanban</option>
                                    <option value="scrum">Scrum</option>
                                    <option value="presales" disabled>Presales</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCategory" className="form-group">
                                <Form.Label>Kategoria</Form.Label>
                                <Form.Select disabled name="projectCategory" value={formData.projectCategory} onChange={handleChange} className="custom-select" placeholder="Wybierz..." title="Jeśli kategorii nie ma liście: wybieramy “inne” i w polu “Uwagi” opisujemy kategorię.">
                                    <option value="" selected>Wybierz...</option>
                                    <option value="Licencja" >Licencja</option>
                                    <option value="Maintenance">Maintenance</option>
                                    <option value="UslugaBCM">Usługa BCM</option>
                                    <option value="UslugaEXT">Usługa Ext</option>
                                    <option value="Hardware">Hardware</option>
                                    <option value="inne">inne</option>
                                </Form.Select>
                            </Form.Group>

                        </Row>

                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="sam">
                                <Form.Label>Nazwa projektu<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectName"
                                    value={formData.projectName}
                                    onChange={(e) => handleChange(e, null, "projectName")}
                                    isInvalid={validationError !== ''}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={8} lg={8} controlId="sam">
                                <Form.Label>Opis</Form.Label>
                                <Form.Control type="text" name="projectDescription" value={formData.projectDescription} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCK" className="form-group">
                                <Form.Label>CK<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Typeahead
                                    clearButton
                                    //defaultSelected={optionsDEPARTMENTs.slice(0, 1)}
                                    selected={selectedCK}
                                    id="projectCK"
                                    labelKey="Department"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsDEPARTMENTs}
                                    placeholder="Wybierz..."
                                    onChange={selectedOptions => { setSelectedCK(selectedOptions); handleChange(null, selectedOptions, "projectCK") }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="PM">
                                <Form.Label>Project Manager<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Typeahead 
                                    clearButton
                                    //defaultSelected={optionsMANAGERs.slice(0, 1)}
                                    selected={selectedPM}
                                    id="selectedMANAGER"
                                    labelKey="displayName"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsMANAGERs}
                                    placeholder="Wybierz..."
                                    onChange={selectedOptions => {
                                        setSelectedPM(selectedOptions);
                                        handleChange(null, selectedOptions, "projectPM");
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="Handlowiec">
                                <Form.Label>Opiekun handlowy<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Typeahead
                                    clearButton
                                    //defaultSelected={optionsMANAGERs.slice(0, 1)}
                                    selected={selectedDH}
                                    id="selectedHANDLOWIEC"
                                    labelKey="displayName"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsDHUsers}
                                    placeholder="Wybierz..."
                                    onChange={selectedOptions => { setSelectedDH(selectedOptions); handleChange(null, selectedOptions, "projectDH") }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNazwa">
                                <Form.Label>Nazwa Klienta z KRS<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control type="text" name="projectKlientNazwa" value={formData.projectKlientNazwa} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientAdres">
                                <Form.Label>Adres Klienta z KRS<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control type="text" name="projectKlientAdres" value={formData.projectKlientAdres} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNip">
                                <Form.Label>NIP Klienta<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control type="text" name="projectKlientNip" value={formData.projectKlientNip} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={2} lg={2} controlId="projectCurrency" className="form-group">
                                <Form.Label>Waluta<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Select name="projectCurrency" value={formData.projectCurrency} onChange={handleChange} className="custom-select" placeholder="Wybierz...">
                                    <option value="" selected>Wybierz...</option>
                                    <option value="PLN">PLN</option>
                                    <option value="EUR">EUR</option>
                                    <option value="USD">USD</option>
                                    <option value="inna">inna</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={2} lg={2} controlId="projectTermin" className="form-group">
                                <Form.Label>Termin płatności<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Select name="projectTermin" value={formData.projectTermin} onChange={handleChange} className="custom-select" placeholder="Wybierz...">
                                    <option value="" selected>Wybierz...</option>
                                    <option value="3">3dni</option>
                                    <option value="7">7dni</option>
                                    <option value="14">14dni</option>
                                    <option value="21">21dni</option>
                                    <option value="30">30dni</option>
                                    <option value="45">45dni</option>
                                    <option value="60">60dni</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectProduct" className="form-group">
                                <Form.Label>Produkt<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Select name="projectProduct" value={formData.projectProduct} onChange={handleChange} className="custom-select" placeholder="Wybierz..." title="Jeśli kategorii nie ma liście: wybieramy “inne” i w polu “Uwagi” opisujemy kategorię.">
                                    <option value="" selected>Wybierz...</option>
                                    <option value="Licencja" >Licencja</option>
                                    <option value="Maintenance">Maintenance</option>
                                    <option value="UslugaBCM">Usługa BCM</option>
                                    <option value="UslugaEXT">Usługa Ext</option>
                                    <option value="Hardware">Hardware</option>
                                    <option value="inne">inne</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} controlId="projectUwagi">
                                <Form.Label>Uwagi</Form.Label>
                                <Form.Control as="textarea" rows={3} name="projectUwagi" value={formData.projectUwagi} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                        </Row>

                        <Button variant="primary" type="submit">Wyślij</Button>{' '}
                        <Button variant="info" onClick={handleClear}>Wyczyść</Button>

                    </Form>
                </Container>
            </div>
            {myRequests.length > 0 && (
                <div >
                    <h6>Moje wnioski:</h6>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Przeznaczenie</th>
                                <th>Konfiguracja</th>
                                <th>Kategoria</th>
                                <th>Klucz</th>
                                <th>Nazwa</th>
                                <th>Opis</th>
                                <th>CK</th>
                                <th>Lider</th>
                                <th>DH</th>
                                <th>Klient</th>
                                <th>Adres</th>
                                <th>NIP</th>
                                <th>Waluta</th>
                                <th>FV termin</th>
                                <th>Uwagi</th>
                                <th>Data utworzenia</th>
                                <th>Aktualizacja</th>
                                <th>Data zakończenia</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {myRequests.map((record) => (
                                <tr key={record.Id} >

                                    <td>{record.Purpose}</td>
                                    <td>{record.Config}</td>
                                    <td>{record.Category}</td>
                                    <td>{record.ProjectKey}</td>
                                    <td>{record.ProjectName}</td>
                                    <td>{record.ProjectDescription}</td>
                                    <td>{record.CK}</td>
                                    <td>{record.PM}</td>
                                    <td>{record.DH}</td>
                                    <td>{record.CustomerName}</td>
                                    <td>{record.CustomerAddress}</td>
                                    <td>{record.CustomerNIP}</td>
                                    <td>{record.CustomerCurrency}</td>
                                    <td>{record.CustomerInvoiceDate}dni</td>
                                    <td>{record.Comments}</td>
                                    <td>{record.CreatedAt}</td>
                                    <td>{record.ModifiedBy}</td>
                                    <td>{record.CompletedAt}</td>
                                    <td>
                                        <span style={{ fontWeight: record.Status === 'COMPLETED' || record.Status === 'NEW' ? 'bold' : 'normal', color: record.Status === 'COMPLETED' ? 'green' : record.Status === 'NEW' ? 'red' : 'inherit' }}>
                                            {record.Status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default Jira;
