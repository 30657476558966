import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
//import Modal from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal, Container } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';  //powiadomienia
import { useParams } from 'react-router-dom';


const Jira = () => {
    const [validationError, setValidationError] = useState('');
    let timeoutId = null;
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [requestsUpdated, setRequestsUpdated] = useState(true); //renderowanie listy wnioskow po aktualizacji jednego z nich

    const { recordId } = useParams(); // Pobieramy parametr z adresu URL
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({}); //useState(null);

    const handleRecordClick = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const handleCloseModal = async () => {
        await getMyRequests();
        setRequestsUpdated(true);
        setShowModal(false);

    };

    const inputRef = useRef(null);

    const optionsDEPARTMENTs = [
        { id: 9, Department: 'LoB HiT' },
        { id: 1, Department: 'LoB HiT - CK Microsoft' },
        { id: 2, Department: 'LoB HiT - CK Service' },
        { id: 7, Department: 'LoB HiT - CK ITIL' },
        { id: 10, Department: 'LoB HiT - CK IA' },
        { id: 3, Department: 'LoB Smart WorkPlace' },
        { id: 8, Department: 'LoB Edukacja' },
        { id: 4, Department: 'LoB HiT - CK RI' },
        { id: 5, Department: 'Zarząd' },
        { id: 6, Department: 'Marketing' },      
        { id: 11, Department: 'Logistyka' },
        { id: 12, Department: 'Administracja' },
        { id: 13, Department: 'HR' },
        { id: 14, Department: 'DH Enterprise' },
        { id: 15, Department: 'DH Corporate' },
        { id: 16, Department: 'Rada Nadzorcza' },
        { id: 17, Department: 'Control System Software' },
        { id: 18, Department: 'Księgowość' },
        { id: 19, Department: 'Finanse i Księgowość' },
        { id: 20, Department: 'VIA Dystrybucja' },
        { id: 21, Department: 'VIA TMS' },
        { id: 22, Department: 'TMS Apollo' }
    ];

    /*const getRecordById = async (id) => {
        try {
            if (Object.keys(selectedRecord).length === 0) {
                const response = await axios.get(`/jira/getProject/${id}`);
                setSelectedRecord(response.data[0]);
                setShowModal(true); // Pokazujemy modal po pobraniu danych rekordu
                //console.log(response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching record:', error);
        }
    };*/
    const getRecordById = async (id) => {
        try {
            if (!/^\d+$/.test(id)) {
                console.error('Nie kombinuj:)');
                toast.error('Nie kombinuj:)'); //Nieprawidłowy format ID: musi zawierać tylko cyfry
                return;
            }
            if (Object.keys(selectedRecord).length === 0) {
                const response = await axios.get(`/jira/getProject/${id}`);
                if (!response.data[0] /*|| !Array.isArray(response.data[0])*/ || response.data[0].length === 0 || response.status === 400) {
                    console.error('Record not found'); // Wyświetlenie komunikatu błędu, że rekord nie został znaleziony
                    toast.error('Błąd :<');

                } else {
                    setSelectedRecord(response.data[0]);
                    setShowModal(true); // Pokazujemy modal po pobraniu danych rekordu
                }
            }
        } catch (error) {
            console.error('Error fetching record:', error);
        }
    };

    const [optionsMANAGERs, setOptionsMANAGERs] = useState([]);
    const [selectedOptionMANAGER, setSelectedOptionMANAGER] = useState([]);

    const getManagers = async () => {
        try {
            const url = `/jira/getusers`;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            activeManagers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsMANAGERs(activeManagers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    /*useEffect(() => {
        getManagers();
    }, []);
    */
    const [optionsDHUsers, setOptionsDHUsers] = useState([]);
    const [selectedOptionDHUSER, setSelectedOptionDHUSER] = useState([]);

    const getDHUsers = async () => {
        try {
            var department = "Dział Handlowy";
            var encodedDepartment = encodeURIComponent(department);
            const url = `/graph/getUsersByDep/` + encodedDepartment;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            //const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            const activeUsers = responseData
            activeUsers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsDHUsers(activeUsers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    /*useEffect(() => {
        getDHUsers();
    }, []);
    */
    const validateProjectName = async (name) => {
        try {
            const url = `/jira/validprojectname/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy projektu:', error);
            return false;
        }
    };

    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    const formatValue = (value) => {
        if (value === null) {
            return "null";
        } else if (typeof value === "boolean") {
            return value ? "True" : "False";
        } else {
            return value;
        }
    };

    const [formData, setFormData] = useState({
        projectPurpose: 'komercyjny',
        projectConfig: '',
        projectCategory: '',
        projectName: '',
        projectDescription: '',
        projectCK: '',
        projectPM: '',
        projectDH: '',
        projectKlientNazwa: '',
        projectKlientAdres: '',
        projectKlientNip: '',
        projectCurrency: 'PLN',
        projectTermin: '7',
        projectUwagi: '',
        projectCreatedBy: accounts[0]?.username,
        projectCreatedAt: ''
    });

    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja
        //console.log(name);
        if (name === 'projectName') {
            // Anuluj poprzednie zdarzenie setTimeout
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                // Walidacja nazwy projektu
                const isValid = await validateProjectName(newValue);
                if (!isValid) {
                    setValidationError('Ta nazwa jest nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                    toast.warn('Ta nazwa jest nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                } else {
                    setValidationError('');
                }
            }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
            
        }
        if (id === 'projectDH') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].userPrincipalName : '';
        }
        if (id === 'projectPM') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].accountId : '';
        }
        if (id === 'projectCK') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        }


        if (name === 'expiresOn') {
            //newValue.setHours(0, 0, 0, 0);
            if (new Date(newValue) <= new Date()) {
                toast.warn('Data ważności konta nie może być datą przeszłą.');
                return;
            }
        }
        if (name === 'projectKlientNip') {
            newValue = newValue.replace(/[^\d-]/g, ''); // Usunięcie znaków niebędących cyfrą ani '+'
            /*
            // Sprawdzenie, czy wprowadzony numer pasuje do wzorca
            const nipPattern = /^-*(?:\d-*){10}$/;
            if (!nipPattern.test(newValue)) {
                return; // Przerywa dalsze przetwarzanie
            }
            */
        }

        setFormData(prevState => ({
            ...prevState,
            [name || id]: newValue || selectedValue
        }));

        console.log(formData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
        getMyRequests();
    };

    const handleClear = () => {
        setFormData({
            projectPurpose: 'komercyjny',
            projectConfig: '',
            projectCategory: '',
            projectName: '',
            projectDescription: '',
            projectCK: '',
            projectPM: '',
            projectDH: '',
            projectKlientNazwa: '',
            projectKlientAdres: '',
            projectKlientNip: '',
            projectCurrency: 'PLN',
            projectTermin: '7',
            projectUwagi: '',
            projectCreatedBy: accounts[0]?.username,
            projectCreatedAt: ''
        });
    };

    const [myRequests, setMyRequests] = useState([]); //wnioski zlozone przez zalogowanego uzytkownika
    const getMyRequests = async () => {
        axios
            .get(`/jira/GetCommercialProjects/`)
            .then((response) => {
                setMyRequests(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });
    };
    /*useEffect(() => {
        getMyRequests();
    }, []);*/
    /*useEffect(() => {
        getMyRequests();
        if (recordId) {
            getRecordById(recordId); // Pobieramy rekord na podstawie ID z adresu URL
        }
    }, [recordId]);
    */
    useEffect(() => {
        if (requestsUpdated) {
            getMyRequests();
            setRequestsUpdated(false); // Resetowanie flagi
        }
        if (recordId) {
            getRecordById(recordId); // Pobieramy rekord na podstawie ID z adresu URL
        }
    }, [recordId, requestsUpdated]);

    return (
        <div className=" mt-4">
            <ToastContainer />
            
            {myRequests.length > 0 && (
                <div className="border p-2 mb-4 my-form">
                    <h6>Oczekujące wnioski:</h6>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Przeznaczenie</th>
                                <th>Konfiguracja</th>
                                <th>Kategoria</th>
                                <th>Klucz</th>
                                <th>Nazwa</th>
                                <th>Opis</th>
                                <th>CK</th>
                                <th>Lider</th>
                                <th>DH</th>
                                <th>Klient</th>
                                <th>Adres</th>
                                <th>NIP</th>
                                <th>Waluta</th>
                                <th>FV termin</th>
                                <th>Uwagi</th>
                                <th>Data utworzenia</th>
                                <th>Aktualizacja</th>
                                <th>Data zakończenia</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {myRequests.map((record) => (
                                <tr key={record.Id} onClick={() => handleRecordClick(record)}>

                                    <td>{record.Purpose}</td>
                                    <td>{record.Config}</td>
                                    <td>{record.Category}</td>
                                    <td>{record.ProjectKey}</td>
                                    <td>{record.ProjectName}</td>
                                    <td>{record.ProjectDescription}</td>
                                    <td>{record.CK}</td>
                                    <td>{record.PM}</td>
                                    <td>{record.DH}</td>
                                    <td>{record.CustomerName}</td>
                                    <td>{record.CustomerAddress}</td>
                                    <td>{record.CustomerNIP}</td>
                                    <td>{record.CustomerCurrency}</td>
                                    <td>{record.CustomerInvoiceDate}dni</td>
                                    <td>{record.Comments}</td>
                                    <td>{record.CreatedAt}</td>
                                    <td>{record.ModifiedBy}</td>
                                    <td>{record.CompletedAt}</td>
                                    <td>
                                        <span style={{ fontWeight: record.Status === 'COMPLETED' || record.Status === 'NEW' ? 'bold' : 'normal', color: record.Status === 'COMPLETED' ? 'green' : record.Status === 'NEW' ? 'red' : 'inherit' }}>
                                            {record.Status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
            {/* Modal edycji rekordu */}
            <EditRecordModal show={showModal} recordData={selectedRecord} onClose={handleCloseModal} />
        </div>
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

const EditRecordModal = ({ show, recordData, onClose }) => {
    const [validationError, setValidationError] = useState('');
    let timeoutId = null;

    const { instance, accounts } = useMsal();
    const [record, setRecord] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            // Pobierz dane użytkownika
            const username = accounts[0]?.username;
            setRecord({ ...recordData, ModifiedBy: username });
        };

        // Wywołaj funkcję fetchData, gdy dane użytkownika są dostępne
        if (accounts.length > 0) {
            fetchData();
        }
    }, [accounts, recordData]);


    //console.log('recordData: ', recordData);
    //console.log('record: ', record);



    
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );
    /*
    useEffect(() => {
        // Pobieramy dane rekordu na podstawie ID
        const fetchRecord = async () => {
            try {
                const response = await axios.get(`/jira/getProject/${recordId}`);
                setRecord(response.data);
            } catch (error) {
                console.error('Error fetching record:', error);
            }
        };

        if (recordId) {
            fetchRecord();
        }
    }, [recordId]);
    */
    

    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(record);
        /*setRecord(prevState => ({
            ...prevState,
            ModifiedBy: accounts[0]?.username
        }));
        */
        console.log(record);
        await SendForm();
        onClose();

    };

    const SendForm = async () => {
        axios.post('/jira/updateCommercialProject', record) //bylo formDataCopy
            .then((response) => {
                //alert(`Rekord dodany pomyślnie ${response.data}`);
                toast.success(`Projekt został utworzony. ${response.data}`);
                //getMyRequests();
                //handleClear();

            })
            .catch((error) => {
                //alert(`Błąd podczas dodawania rekordu ${error.message}`);
                toast.error(`Błąd podczas dodawania rekordu ${error.message}`);
                console.error('Błąd podczas dodawania rekordu:', error);
            });
    };
    const validateProjectKey = async (name) => {
        try {
            const url = `/jira/validprojectkey/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy klucza:', error);
            return false;
        }
    };
    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja

        // Sprawdź nazwę pola, czy ma wartość 'ProjectKey' lub 'projectKey'
        const fieldName = name.toLowerCase() === 'projectkey' ? 'ProjectKey' : name;

        // Anuluj poprzednie zdarzenie setTimeout
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async () => {
            // Walidacja nazwy projektu
            if (fieldName === 'ProjectKey') {
                const isValid = await validateProjectKey(newValue);
                if (!isValid) {
                    setValidationError('Ten klucz jest nieprawidłowy/zajęty - spróbuj dodać literę na końcu ciągu np. ' + newValue + 'A, ' + newValue + 'B');
                } else {
                    setValidationError('');
                }
            }
        }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
        newValue = newValue.toUpperCase();
        // Zaktualizuj stan rekordu
        setRecord(prevState => ({
            ...prevState,
            [fieldName || id]: newValue || selectedValue
        }));

        console.log(record)
    };


   // console.log('rdata' + recordData);

        return (
            <Modal show={show} onHide={onClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Wprowadź klucz projektu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {record && (
                        <div>
                            <Container>
                                <Form className="my-form" >
                                    <Row className="mb-2">

                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPurpose" className="form-group">
                                            <Form.Label>Przeznaczenie projektu</Form.Label>
                                            <Form.Select disabled name="projectPurpose" value={record.Purpose} /*onChange={(e) => handleChange(e, null, null)}*/ className="custom-select" placeholder="Wybierz...">

                                                <option value="komercyjny" selected>KOMERCYJNY</option>
                                                <option value="neutral">NEUTRAL</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectConfig" className="form-group">
                                            <Form.Label>Konfiguracja</Form.Label>
                                            <Form.Select disabled name="projectConfig" value={record.Config} /*onChange={handleChange}*/ className="custom-select" placeholder="Wybierz...">

                                                <option value="kanban">Kanban</option>
                                                <option value="scrum">Scrum</option>
                                                <option value="presales">Presales</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCategory" className="form-group">
                                            <Form.Label>Kategoria</Form.Label>
                                            <Form.Control disabled type="text" name="projectCategory" value={record.Category} /*onChange={handleChange}*/ className="custom-select" />

                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} xs={12} md={8} lg={4} controlId="sam">
                                            <Form.Label>Klucz projektu<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Control type="text" name="projectKey" value={record.ProjectKey} onChange={(e) => handleChange(e, null, null)} isInvalid={validationError !== ''} maxLength={10} />
                                            {<Form.Control.Feedback type="invalid">
                                            {validationError}
                                            </Form.Control.Feedback>}
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={8} controlId="sam">
                                            <Form.Label>Nazwa projektu</Form.Label>
                                            <Form.Control disabled
                                                type="text"
                                                name="projectName"
                                                value={record.ProjectName}
                                            /*onChange={(e) => handleChange(e, null, "projectName")}*/
                                            //isInvalid={validationError !== ''}
                                            />
                                            {/*<Form.Control.Feedback type="invalid">
                                            {validationError}
                                        </Form.Control.Feedback>*/}
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} xs={12} md={12} lg={12} controlId="sam">
                                            <Form.Label>Opis projektu</Form.Label>
                                            <Form.Control disabled type="text" name="projectDescription" value={record.ProjectDescription} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCK" className="form-group">
                                            <Form.Label>CK</Form.Label>
                                            <Form.Control disabled ype="text" name="projectCK" value={record.CK} /*onChange={(e) => handleChange(e, null, null)}*/ />
        
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPM">
                                            <Form.Label>Project Manager</Form.Label>
                                            <Form.Control disabled type="text" name="projectPM" value={record.PM} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="Handlowiec">
                                            <Form.Label>Opiekun handlowy</Form.Label>
                                            <Form.Control disabled type="text" name="projectDH" value={record.DH} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNazwa">
                                            <Form.Label>Nazwa Klienta z KRS</Form.Label>
                                            <Form.Control disabled type="text" name="projectKlientNazwa" value={record.CustomerName} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientAdres">
                                            <Form.Label>Adres Klienta z KRS</Form.Label>
                                            <Form.Control disabled type="text" name="projectKlientAdres" value={record.CustomerAddress} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNip">
                                            <Form.Label>NIP Klienta</Form.Label>
                                            <Form.Control disabled type="text" name="projectKlientNip" value={record.CustomerNIP} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCurrency" className="form-group">
                                            <Form.Label>Waluta</Form.Label>
                                            <Form.Select disabled name="projectCurrency" value={record.CustomerCurrency} /*onChange={handleChange}*/ className="custom-select" placeholder="Wybierz...">
                                                <option value="">Waluta</option>
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="USD">USD</option>
                                                <option value="inna">inna</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectTermin" className="form-group">
                                            <Form.Label>Termin płatności</Form.Label>
                                            <Form.Select disabled name="projectTermin" value={record.CustomerInvoiceDate} /*onChange={handleChange}*/ className="custom-select" placeholder="Wybierz...">
                                                <option value="">Termin płatności</option>
                                                <option value="3">3dni</option>
                                                <option value="7">7dni</option>
                                                <option value="14">14dni</option>
                                                <option value="21">21dni</option>
                                                <option value="30">30dni</option>
                                                <option value="45">45dni</option>
                                                <option value="60">60dni</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} controlId="projectUwagi">
                                            <Form.Label>Uwagi</Form.Label>
                                            <Form.Control disabled as="textarea" rows={3} name="projectUwagi" value={record.Comments} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                        </Form.Group>
                                    </Row>



                                </Form>
                            </Container>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Zamknij
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };


export default Jira;
