import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
//import Modal from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal, Container } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';  //powiadomienia

/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const BcmAccs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [upnInput, setUpnInput] = useState("");

    const [accountData, setAccountData] = useState({
        firstName: "",
        lastName: "",
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        position: "",
        department: "",
        manager: "",
    });
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setAccountData({
            ...accountData,
            [name]: value,
        });
    };
    const handleChange1 = (e) => {
        setUpnInput(e.target.value);
    };

    const handleCreate = () => {
        // Tutaj możesz dodać logikę zapisywania konta, np. wysłanie na serwer
        console.log("Utwórz konto:", accountData);
    };

    const handleFormClear = () => {
        setAccountData({
            firstName: "",
            lastName: "",
            fullName: "",
            phoneNumber: "",
            emailAddress: "",
            position: "",
            department: "",
            manager: "",
        });
    };
    const inputRef = useRef(null);




 



    const testGraph = () => {
        (async () => {
            const url = "/graph/me"
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    } //dodac: <button disabled onClick={testGraph}>graph req</button><br />
    /*
    const testAD = () => {
        (async () => {
            const url = "/proxy/getuser/ppoczobut@betacom.com.pl"
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }*/
    const testAD = async () => {
        try {
            const url = `/proxy/getuser/${upnInput}`;
            setLoading(true);
            const response = await axios.get(url);
            const responseData = JSON.parse(response.data);
            setDatas(responseData);
            
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
        setLoading(false);
    }
    };

    const optionsDEPARTMENTs = [
        { id: 9, Department: 'LoB HiT' },
        { id: 1, Department: 'LoB HiT - CK Microsoft' },
        { id: 2, Department: 'LoB HiT - CK Service' },
        { id: 7, Department: 'LoB HiT - CK ITIL' },
        { id: 10, Department: 'LoB HiT - CK IA' },
        { id: 3, Department: 'LoB Smart WorkPlace' },
        { id: 8, Department: 'LoB Edukacja' },
        { id: 4, Department: 'LoB HiT - CK RI' },
        { id: 5, Department: 'Zarząd' },
        { id: 6, Department: 'Marketing' },      
        { id: 11, Department: 'Logistyka' },
        { id: 12, Department: 'Administracja' },
        { id: 13, Department: 'HR' },
        { id: 14, Department: 'DH Enterprise' },
        { id: 15, Department: 'DH Corporate' },
        { id: 16, Department: 'Rada Nadzorcza' },
        { id: 17, Department: 'Control System Software' },
        { id: 18, Department: 'Księgowość' },
        { id: 19, Department: 'Finanse i Księgowość' },
        { id: 20, Department: 'VIA Dystrybucja' },
        { id: 21, Department: 'VIA TMS' },
        { id: 22, Department: 'TMS Apollo' }
    ];

    const [optionsOUs, setOptionsOUs] = useState([]);
    const [selectedOptionOU, setSelectedOptionOU] = useState([]);

    const getOus = async () => {
        try {
            const url = `/proxy/getous`;
            setLoading(true);
            const response = await axios.get(url);
            const responseData = JSON.parse(response.data);
            setOptionsOUs(responseData);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getOus();
    }, []);

    const [optionsMANAGERs, setOptionsMANAGERs] = useState([]);
    const [selectedOptionMANAGER, setSelectedOptionMANAGER] = useState([]);

    const getManagers = async () => {
        try {
            const url = `/proxy/getmanagers`;
            setLoading(true);
            const response = await axios.get(url);
            const responseData = JSON.parse(response.data);
            setOptionsMANAGERs(responseData);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getManagers();
    }, []);

    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    const formatValue = (value) => {
        if (value === null) {
            return "null";
        } else if (typeof value === "boolean") {
            return value ? "True" : "False";
        } else {
            return value;
        }
    };

    const [formData, setFormData] = useState({
        accountType: '',
        enabled: false,
        ouPath: '',
        sam: '',
        upn: '',
        expiresOn: '',
        manager: '',
        displayName: '',
        firstName: '',
        lastName: '',
        telephone: '+48',
        email: '',
        department: '',
        company: 'Betacom S.A.',
        street: 'Połczyńska 31A',
        city: 'Warszawa',
        office: 'Warszawa',
        description: ''
    });
    /*const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleChangeDepartment = (selectedOptions) => {
        const selectedDepartment = selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        setFormData(prevState => ({
            ...prevState,
            selectedDepartment: selectedDepartment
        }));
    };*/
    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja
        //console.log(name);
        if (id === 'department') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        }
        if (id === 'manager') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].DistinguishedName : '';
        }
        if (id === 'ouPath') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].AdsPath : '';
        }
        if (name === 'sam') {
            if (formData.accountType === 'BCMUser') {
                setFormData(prevState => ({
                    ...prevState,
                    upn: newValue + '@betacom.com.pl',
                    email: newValue + '@betacom.com.pl'
                }));
            }
            if (formData.accountType === 'BCMService') {
                setFormData(prevState => ({
                    ...prevState,
                    upn: newValue + '@bcm.betacom.com.pl',
                    email: newValue + '@bcm.betacom.com.pl'
                }));
            }
        }
        if (name === 'expiresOn') {
            //newValue.setHours(0, 0, 0, 0);
            if (new Date(newValue) <= new Date()) {
                toast.warn('Data ważności konta nie może być datą przeszłą.');
                return;
            }
        }
        if (name === 'telephone') {
            newValue = newValue.replace(/[^\d+]/g, ''); // Usunięcie znaków niebędących cyfrą ani '+'

            // Sprawdzenie, czy wprowadzony numer pasuje do wzorca
            const phonePattern = /^\+?\d{1,3}\d*$/;
            if (!phonePattern.test(newValue)) {
                return; // Przerywa dalsze przetwarzanie
            }
        }

        setFormData(prevState => ({
            ...prevState,
            [name || id]: newValue || selectedValue
        }));

        console.log(formData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    const handleClear = () => {
        setFormData({
            accountType: '',
            enabled: false,
            ouPath: '',
            sam: '',
            upn: '',
            expiresOn: '',
            manager: '',
            displayName: '',
            firstName: '',
            lastName: '',
            telephone: '+48',
            email: '',
            department: '',
            company: 'Betacom S.A.',
            street: 'Połczyńska 31A',
            city: 'Warszawa',
            office: 'Warszawa',
            description: ''
        });
    };


    return (
        <div className="container mt-4">
            <ToastContainer />
            <div className="border p-2 mb-4 my-form">
        <Container>
                    <Form onSubmit={handleSubmit} className="my-form" >
                        <Row className="mb-2">
                            
                            <Form.Group as={Col} xs={12} md={4} lg={3} controlId="accountType" className="form-group">
                                <Form.Label>Typ konta</Form.Label>
                                <Form.Select name="accountType" value={formData.accountType} onChange={handleChange} className="custom-select" placeholder="Wybierz...">
                                    <option value="">Wybierz typ konta</option>
                                    <option value="BCMUser">BCMUser</option>
                                    <option value="BCMUser">EXTUser</option>
                                    <option value="BCMService">BCMService</option>
                                    
                                    {/* Add options for account types */}
                                </Form.Select>
                            
                                <div className="form-check">
                                <Form.Check type="checkbox" name="enabled" checked={formData.enabled} onChange={(e) => handleChange(e, null, null)} />
                                <Form.Label className="form-check-label">Enabled</Form.Label>
                                </div> 

                                   
                            </Form.Group>
                            
                            
                            <Form.Group as={Col} xs={12} md={8} lg={9} controlId="ouPath" className="form-group">
                                <Form.Label>OU Path</Form.Label>                              
                                <Typeahead
                                    clearButton
                                    defaultSelected={optionsOUs.slice(0, 1)}
                                    id="selectedOU"
                                    labelKey="AdsPath"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsOUs}
                                    placeholder="Choose a container..."
                                    onChange={selectedOptions => handleChange(null, selectedOptions, "ouPath")}
                                />
                            </Form.Group>
                        </Row>

                <Row className="mb-2">
                    <Form.Group as={Col} controlId="sam">
                        <Form.Label>sAMAccountName</Form.Label>
                                <Form.Control type="text" name="sam" value={formData.sam} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="upn">
                        <Form.Label>UPN</Form.Label>
                                <Form.Control type="email" name="upn" value={formData.upn} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="expiresOn">
                        <Form.Label>Expires on</Form.Label>
                                <Form.Control type="date" name="expiresOn" value={formData.expiresOn} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="manager">
                        <Form.Label>Manager</Form.Label>
                                <Typeahead
                                    clearButton
                                    defaultSelected={optionsMANAGERs.slice(0, 1)}
                                    id="selectedMANAGER"
                                    labelKey="DisplayName"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsMANAGERs}
                                    placeholder="Choose a manager..."
                                    onChange={selectedOptions => handleChange(null, selectedOptions, "manager")}
                                />
                    </Form.Group>
                </Row>

                <Row className="mb-2">
                    <Form.Group as={Col} controlId="displayName">
                        <Form.Label>DisplayName</Form.Label>
                                <Form.Control type="text" name="displayName" value={formData.displayName} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="firstName">
                        <Form.Label>FirstName</Form.Label>
                                <Form.Control type="text" name="firstName" value={formData.firstName} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="lastName">
                        <Form.Label>LastName</Form.Label>
                                <Form.Control type="text" name="lastName" value={formData.lastName} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>
                </Row>

                <Row className="mb-2">
                    <Form.Group as={Col} controlId="telephone">
                        <Form.Label>Telephone</Form.Label>
                                <Form.Control type="tel" name="telephone" value={formData.telephone} onChange={(e) => handleChange(e, null, null)} placeholder="Wprowadź numer telefonu" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="email">
                        <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email} onChange={(e) => handleChange(e, null, null)} placeholder="Wprowadź adres email" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="department">
                        <Form.Label>Department</Form.Label>
                                <Typeahead
                                    clearButton
                                    //defaultSelected={optionsDEPARTMENTs.slice(0, 1)}
                                    id="selectedDEPARTMENT"
                                    labelKey="Department"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsDEPARTMENTs}
                                    placeholder="Choose a department"
                                    //onChange={handleChange}
                                    onChange={selectedOptions => handleChange(null, selectedOptions, "department")}
                                />
                    </Form.Group>
                </Row>

                <Row className="mb-2">
                    <Form.Group as={Col} controlId="company">
                        <Form.Label>Company</Form.Label>
                                <Form.Control type="text" name="company" value={formData.company} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="street">
                        <Form.Label>Street</Form.Label>
                                <Form.Control type="text" name="street" value={formData.street} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="city">
                        <Form.Label>City</Form.Label>
                                <Form.Control type="text" name="city" value={formData.city} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="office">
                        <Form.Label>Office</Form.Label>
                                <Form.Control type="text" name="office" value={formData.office} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>
                </Row>

                <Row className="mb-2">
                    <Form.Group as={Col} controlId="description">
                        <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" value={formData.description} onChange={(e) => handleChange(e, null, null)} />
                    </Form.Group>
                </Row>

                <Button variant="primary" type="submit">Create</Button>{' '}
                <Button variant="info" onClick={handleClear}>Clear</Button>
                        
            </Form>
                </Container>
            </div></div>
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default BcmAccs;
