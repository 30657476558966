import React from 'react';
//import { useNavigate, useParams } from 'react-router-dom';
/*
function Redirect() {
    const navigate = useNavigate();
    const { linkadres } = useParams();

    const handleClick = () => {
        // Użyj parametru z adresu ścieżki
        navigate(`/${linkadres}`, { replace: true });
    };

    return (
        <div>
            <h2>Redirecting...</h2>
            <button onClick={handleClick}>Navigate to {linkadres}</button>
        </div>
    );
}

export default Redirect;
*/

class Redirect extends React.Component {
    componentDidMount() { window.location.href = "https://apps.powerapps.com/play/e/default-015e734b-c94d-4117-b999-42bc566cc590/a/ab64bf9d-0617-4ccd-98c8-5ce511ea3c7f"; }


    render() {
        return (
            <div>
                <h2>Redirecting...</h2>

            </div>
        )
    }
}

/*
//wersja ktora otwiera w nowej zakladce
class Redirect extends React.Component {
    componentDidMount() {
        // Otwórz nowe okno i przekieruj na wskazany adres
        window.open("https://teams.microsoft.com/l/chat/0/0?users=ppoczobut@betacom.com.pl", "_blank");

        // Po dwóch sekundach, przekieruj główne okno na główną ścieżkę
        setTimeout(() => {
            window.location.href = "/";
        }, 2000);
    }

    render() {
        return (
            <div>
                <h2>Redirecting...</h2>
            </div>
        );
    }
}
*/
export default Redirect;
