import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
//import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
//import Modal from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal, Container } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';  //powiadomienia
import { useParams } from 'react-router-dom';


const Jira = () => {
    const [validationError, setValidationError] = useState('');
    const [validationErrorKey, setValidationErrorKey] = useState('');
    let timeoutId = null;
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [upnInput, setUpnInput] = useState("");
    const [selectedPM, setSelectedPM] = useState([]); //do funkcji czyszczacej
    const [selectedCategory, setSelectedCategory] = useState([]); //do funkcji czyszczacej
    //const [selectedDH, setSelectedDH] = useState([]); //do funkcji czyszczacej
    const [selectedCK, setSelectedCK] = useState([]); //do funkcji czyszczacej
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({}); //useState(null);
    const [requestsUpdated, setRequestsUpdated] = useState(true); //renderowanie listy wnioskow po aktualizacji jednego z nich

    const { recordId } = useParams(); // Pobieramy parametr z adresu URL

    const handleRecordClick = (record) => {
        if (record.Status === 'NEW' && record.Purpose === 'komercyjny') {
            setSelectedRecord(record);
            setShowModal(true);
        }
    };

    const handleCloseModal = async () => {
        await getMyRequests();
        setRequestsUpdated(true);
        setShowModal(false);
        setSelectedRecord([]);

    };

    const getRecordById = async (id) => {
        try {
            if (!/^\d+$/.test(id)) {
                console.error('Nie kombinuj:)');
                toast.error('Nie kombinuj:)'); //Nieprawidłowy format ID: musi zawierać tylko cyfry
                return;
            }
            if (Object.keys(selectedRecord).length === 0) {
                const response = await axios.get(`/jira/GetProjectCKMgmt/${id}`);
                if (response.data[0]?.JiraManager === accounts[0]?.username) {
                    const responseRec = await axios.get(`/jira/getProject/${id}`);
                    if (!responseRec.data[0] /*|| !Array.isArray(response.data[0])*/ || responseRec.data[0].length === 0 || responseRec.status === 400) {
                        console.error('Record not found'); // Wyświetlenie komunikatu błędu, że rekord nie został znaleziony
                        toast.error('Błąd :<<');
                    }
                    else {
                        setSelectedRecord(responseRec.data[0]);
                        setShowModal(true); // Pokazujemy modal po pobraniu danych rekordu
                    }

                }
                else {
                    console.error('Nie masz wystarczających uprawnień.');
                    toast.error('Nie masz wystarczających uprawnień:<');
                }
            }
        } catch (error) {
            console.error('Error fetching record:', error);
        }
    };
    const inputRef = useRef(null);




    const optionsDEPARTMENTs = [
        { id: 9, Department: 'LoB HiT' },
        { id: 1, Department: 'LoB HiT - CK Microsoft' },
        { id: 2, Department: 'LoB HiT - CK Service' },
        { id: 7, Department: 'LoB HiT - CK ITIL' },
        { id: 10, Department: 'LoB HiT - CK IA' },
        { id: 3, Department: 'LoB Smart WorkPlace' },
        { id: 8, Department: 'LoB Edukacja' },
        { id: 4, Department: 'LoB HiT - CK RI' },
        { id: 5, Department: 'Zarząd' },
        { id: 6, Department: 'Marketing' },      
        { id: 11, Department: 'Logistyka' },
        { id: 12, Department: 'Administracja' },
        { id: 13, Department: 'HR' },
        { id: 14, Department: 'DH Enterprise' },
        { id: 15, Department: 'DH Corporate' },
        { id: 16, Department: 'Rada Nadzorcza' },
        { id: 17, Department: 'Control System Software' },
        { id: 18, Department: 'Księgowość' },
        { id: 19, Department: 'Finanse i Księgowość' },
        { id: 20, Department: 'VIA Dystrybucja' },
        { id: 21, Department: 'VIA TMS' },
        { id: 22, Department: 'TMS Apollo' }
    ];

    const [optionsCATEGORIEs, setOptionsCATEGORIEs] = useState([]);

    const getCategories = async () => {
        try {
            const url = `/jira/getCategories`;
            setLoading(true);
            const response = await axios.get(url);
            const categories = response.data;
            // Sortuj rekordy alfabetycznie według DisplayName
            categories.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setOptionsCATEGORIEs(categories);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getCategories();
    }, []);

    const [optionsMANAGERs, setOptionsMANAGERs] = useState([]);
    const [selectedOptionMANAGER, setSelectedOptionMANAGER] = useState([]);

    const getManagers = async () => {
        try {
            const url = `/jira/getusers`;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            activeManagers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsMANAGERs(activeManagers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getManagers();
    }, []);

    const [optionsDHUsers, setOptionsDHUsers] = useState([]);
    const [selectedOptionDHUSER, setSelectedOptionDHUSER] = useState([]);

    const getDHUsers = async () => {
        try {
            var department = "Dział Handlowy";
            var encodedDepartment = encodeURIComponent(department);
            const url = `/graph/getUsersByDep/` + encodedDepartment;
            setLoading(true);
            const response = await axios.get(url);
            //const responseData = JSON.parse(response);
            const responseData = response.data;
            // Filtruj rekordy, które mają atrybut active ustawiony na true
            //const activeManagers = responseData.filter(manager => manager.active === true);
            // Sortuj rekordy alfabetycznie według DisplayName
            const activeUsers = responseData
            activeUsers.sort((a, b) => {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            });
            //setOptionsMANAGERs(responseData);
            setOptionsDHUsers(activeUsers);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getDHUsers();
    }, []);

    const validateProjectName = async (name) => {
        try {
            const url = `/jira/validprojectname/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy projektu:', error);
            return false;
        }
    };

    const validateProjectKey = async (name) => {
        try {
            const url = `/jira/validprojectkey/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy klucza:', error);
            return false;
        }
    };

    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    const formatValue = (value) => {
        if (value === null) {
            return "null";
        } else if (typeof value === "boolean") {
            return value ? "True" : "False";
        } else {
            return value;
        }
    };

    const [formData, setFormData] = useState({
        projectPurpose: 'neutral',
        projectConfig: '',
        projectCategory: '',
        projectName: '',
        projectKey: '',
        projectDescription: '',
        projectCK: '',
        projectPM: '',
        projectDH: '',
        projectKlientNazwa: '',
        projectKlientAdres: '',
        projectKlientNip: '',
        projectCurrency: 'PLN',
        projectTermin: '7',
        projectUwagi: '',
        projectCreatedBy: accounts[0]?.username,
        projectCreatedAt: ''
    });
    const [myRequests, setMyRequests] = useState([]); //wnioski zlozone przez zalogowanego uzytkownika


    /*const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleChangeDepartment = (selectedOptions) => {
        const selectedDepartment = selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        setFormData(prevState => ({
            ...prevState,
            selectedDepartment: selectedDepartment
        }));
    };*/
    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja
        //console.log(name);
        if (name === 'projectName') {
            // Anuluj poprzednie zdarzenie setTimeout
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                // Walidacja nazwy projektu
                const isValid = await validateProjectName(newValue);
                if (!isValid) {
                    setValidationError('Ta nazwa jest nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                    //toast.warn('Ta nazwa jest już nieprawidłowa/zajęta - spróbuj dodać literę na końcu nazwy np. ' + newValue + 'A, ' + newValue + 'B');
                } else {
                    setValidationError('');
                }
            }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
            
        }
        if (name === 'projectKey') {
            // Anuluj poprzednie zdarzenie setTimeout
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                // Walidacja nazwy projektu
                const isValid = await validateProjectKey(newValue);
                if (!isValid) {
                    setValidationErrorKey('Ten klucz jest nieprawidłowy/zajęty - spróbuj dodać literę na końcu kodu np. ' + newValue + 'A, ' + newValue + 'B');
                    //toast.warn('Ten klucz jest już nieprawidłowy/zajęty - spróbuj dodać literę na końcu kodu np. ' + newValue + 'A, ' + newValue + 'B');
                } else {
                    setValidationErrorKey('');
                }
            }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
            newValue = newValue.toUpperCase()
        }
        if (id === 'projectDH') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].userPrincipalName : '';
        }
        if (id === 'projectPM') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].accountId : '';
        }
        if (id === 'projectCK') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].Department : '';
        }
        if (id === 'projectCategory') {
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].name : '';
        }

        if (name === 'projectKlientNip') {
            newValue = newValue.replace(/[^\d-]/g, ''); // Usunięcie znaków niebędących cyfrą ani '+'
            /*
            // Sprawdzenie, czy wprowadzony numer pasuje do wzorca
            const nipPattern = /^-*(?:\d-*){10}$/;
            if (!nipPattern.test(newValue)) {
                return; // Przerywa dalsze przetwarzanie
            }
            */
        }

        setFormData(prevState => ({
            ...prevState,
            [name || id]: newValue || selectedValue
        }));

        console.log(formData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
        SendForm();
        getMyRequests();
    };

    const SendForm = () => {
        if (formData.projectPurpose === '' ||
            formData.projectConfig === '' ||
            formData.projectCategory === '' ||
            formData.projectName === '' ||
            formData.projectKey === '' ||
            formData.projectCK === '' ||
            formData.projectPM === '' ) { toast.error('Wypełnij wszystkie wymagane pola.'); }
        else {
            axios.post('/jira/newInternalProject', formData) //bylo formDataCopy
                .then((response) => {
                    //alert(`Rekord dodany pomyślnie ${response.data}`);
                    toast.success(`Projekt wewnętrzny został założony. ${response.data}`);
                    handleClear();
                })
                .catch((error) => {
                    //alert(`Błąd podczas dodawania rekordu ${error.message}`);
                    toast.error(`Błąd podczas dodawania rekordu ${error.message}`);
                    console.error('Błąd podczas dodawania rekordu:', error);
                });
        }
    };

    const fetchDisplayName = async (accountId) => {
        try {
            const response = await axios.get(`/jira/getUser/${accountId}`);
            //console.log('Response from getUser endpoint for accountId:', accountId, response.data);
            return response.data; // Zwróć string `displayName`
        } catch (error) {
            //console.error('Error fetching displayName for accountId:', accountId, error);
            return null; // Jeśli wystąpił błąd, zwróć null jako fallback
        }
    };

    const mapAccountIdsToDisplayNames = async (data) => {
        const updatedData = await Promise.all(data.map(async (record) => {
            if (record.PM) {
                const displayName = await fetchDisplayName(record.PM);
                record.PM = displayName || record.PM; // Jeśli displayName jest null lub undefined, zachowaj accountId
            }
            return record;
        }));
        return updatedData;
    };
    /*
    const getMyRequests = async () => {
        const { projectCreatedBy } = formData;
        try {
            const response = await axios.get(`/jira/getProjects/${projectCreatedBy}/`);
            //console.log('Received data from endpoint:', response.data);
            const updatedData = await Promise.all(response.data.map(async (record, index) => {
                //console.log('Processing record:', record);
                if (record.PM) {
                    //console.log('Fetching displayName for accountId:', record.PM);
                    const displayName = await fetchDisplayName(record.PM);
                    //console.log('Fetched displayName:', displayName);
                    // Zaktualizuj PM tylko jeśli displayName jest poprawne
                    if (displayName) {
                        response.data[index].PM = displayName;
                    } else {
                        console.log('Failed to fetch displayName for accountId:', record.PM);
                        // W przypadku niepowodzenia, zachowaj oryginalne konto
                        // Możesz dodać tutaj obsługę takiej sytuacji
                    }
                }
                return record;
            }));
            console.log('Updated data:', updatedData);
            setMyRequests(updatedData);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Your access is not allowed.');
            }
            console.error('Error fetching projects:', error);
        }
    };*/
    const getMyRequests = async () => {
        const { projectCreatedBy } = formData;
        axios
            .get(`/jira/getProjects/${projectCreatedBy}/`)
            .then((response) => {
                setMyRequests(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error('Your access is not allowed.');
                    //alert('Your access is not allowed.');
                }
            });
    };
    useEffect(() => {
        getMyRequests();
    }, []);


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('pl-PL', options);
    };
    const handleClear = () => {
        setFormData({
            projectPurpose: 'neutral',
            projectConfig: '',
            projectCategory: '',
            projectName: '',
            projectKey: '',
            projectDescription: '',
            projectCK: '',
            projectPM: '',
            projectDH: '',
            projectKlientNazwa: '',
            projectKlientAdres: '',
            projectKlientNip: '',
            projectCurrency: 'PLN',
            projectTermin: '7',
            projectUwagi: '',
            projectCreatedBy: accounts[0]?.username,
            projectCreatedAt: ''
        });
        setSelectedPM([]);
        setSelectedCK([]);
        setSelectedCategory([]);
    };

    useEffect(() => {
        if (requestsUpdated) {
            getMyRequests();
            setRequestsUpdated(false); // Resetowanie flagi
        }
        if (recordId) {
            getRecordById(recordId); // Pobieramy rekord na podstawie ID z adresu URL
        }
    }, [recordId, requestsUpdated]);

    return (
        <div className="container mt-4">
            <ToastContainer />
            <div className="border p-2 mb-4 my-form">
                <Container>
                    <Form onSubmit={handleSubmit} className="my-form" >
                        <Row className="mb-2">

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPurpose" className="form-group">
                                <Form.Label>Przeznaczenie projektu</Form.Label>
                                <Form.Select disabled name="projectPurpose" value={formData.projectPurpose} onChange={(e) => handleChange(e, null, null)} className="custom-select" placeholder="Wybierz...">                                  
                                    <option value="komercyjny">KOMERCYJNY</option>
                                    <option value="neutral" selected>NEUTRAL</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectConfig" className="form-group">
                                <Form.Label>Konfiguracja<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Select name="projectConfig" value={formData.projectConfig} onChange={(e) => handleChange(e, null, null)} className="custom-select" placeholder="Wybierz...">                               
                                    <option value="" selected>Wybierz...</option>
                                    <option value="kanban">Kanban</option>
                                    <option value="scrum">Scrum</option>
                                    <option value="presales" disabled>Presales</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCategory" className="form-group">
                                <Form.Label>Kategoria<span style={{ color: 'red' }}>*</span></Form.Label>
                            
                            <Typeahead
                                clearButton
                                //defaultSelected={optionsDEPARTMENTs.slice(0, 1)}
                                selected={selectedCategory}
                                id="projectCategory"
                                labelKey="name"
                                onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                    console.log(text, e);
                                }}
                                options={optionsCATEGORIEs}
                                placeholder="Wybierz..."
                                onChange={selectedOptions => { setSelectedCategory(selectedOptions); handleChange(null, selectedOptions, "projectCategory") }}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={4} lg={2} controlId="projectKey">
                                <Form.Label>Klucz projektu<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectKey"
                                    value={formData.projectKey}
                                    onChange={(e) => handleChange(e, null, "projectKey")}
                                    //onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.target.value.toUpperCase() } }, null, "projectKey")}
                                    isInvalid={validationErrorKey !== ''}
                                    maxLength={10}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrorKey}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectName">
                                <Form.Label>Nazwa projektu<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectName"
                                    value={formData.projectName}
                                    onChange={(e) => handleChange(e, null, "projectName")}
                                    isInvalid={validationError !== ''}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={8} lg={6} controlId="projectDescription">
                                <Form.Label>Opis</Form.Label>
                                <Form.Control type="text" name="projectDescription" value={formData.projectDescription} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCK" className="form-group">
                                <Form.Label>CK<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Typeahead
                                    clearButton
                                    //defaultSelected={optionsDEPARTMENTs.slice(0, 1)}
                                    selected={selectedCK}
                                    id="projectCK"
                                    labelKey="Department"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsDEPARTMENTs}
                                    placeholder="Wybierz..."
                                    onChange={selectedOptions => { setSelectedCK(selectedOptions); handleChange(null, selectedOptions, "projectCK") }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPM">
                                <Form.Label>Project Manager<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Typeahead
                                    clearButton
                                    //defaultSelected={optionsMANAGERs.slice(0, 1)}
                                    selected={selectedPM}
                                    id="projectPM"
                                    labelKey="displayName"
                                    onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                        console.log(text, e);
                                    }}
                                    options={optionsMANAGERs}
                                    placeholder="Wybierz..."
                                    onChange={selectedOptions => { setSelectedPM(selectedOptions); handleChange(null, selectedOptions, "projectPM") }}
                                />
                            </Form.Group>
                            
                        </Row>
                        
                        <Row className="mb-2">
                            <Form.Group as={Col} controlId="projectUwagi">
                                <Form.Label>Uwagi</Form.Label>
                                <Form.Control as="textarea" rows={3} name="projectUwagi" value={formData.projectUwagi} onChange={(e) => handleChange(e, null, null)} />
                            </Form.Group>
                        </Row>

                        <Button variant="primary" type="submit">Wyślij</Button>{' '}
                        <Button variant="info" onClick={handleClear}>Wyczyść</Button>

                    </Form>
                </Container>
            </div>
            {myRequests.length > 0 && (
                <div className="border p-2 mb-4 my-form">
                    <h6>Moje wnioski:</h6>
                    <Table striped  hover>
                        <thead>
                            <tr>
                                <th>Przeznaczenie</th>
                                <th>Konfiguracja</th>
                                <th>Kategoria</th>
                                <th>Klucz</th>
                                <th>Nazwa</th>
                                <th>Opis</th>
                                <th>CK</th>
                                <th>Lider</th>
                                <th>Uwagi</th>
                                <th>Data utworzenia</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {myRequests.map((record) => (
                                <tr key={record.Id} onClick={() => handleRecordClick(record)}>
                                    
                                    <td>{record.Purpose}</td>
                                    <td>{record.Config}</td>
                                    <td>{record.Category}</td>
                                    <td>{record.ProjectKey}</td>
                                    <td>{record.ProjectName}</td>
                                    <td>{record.ProjectDescription}</td>
                                    <td>{record.CK}</td>
                                    <td>{record.PM}</td>
                                    <td>{record.Comments}</td>
                                    <td>{record.CreatedAt}</td>
                                    <td>
                                        <span style={{ fontWeight: record.Status === 'COMPLETED' || record.Status === 'NEW' ? 'bold' : 'normal', color: record.Status === 'COMPLETED' ? 'green' : record.Status === 'NEW' ? 'red' : 'inherit' }}>
                                            {record.Status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
            {/* Modal edycji rekordu */}
            <EditRecordModal show={showModal} recordData={selectedRecord} onClose={handleCloseModal} />
        </div>


    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

const EditRecordModal = ({ show, recordData, onClose }) => {
    const [validationError, setValidationError] = useState('');
    let timeoutId = null;

    const { instance, accounts } = useMsal();
    const [record, setRecord] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState([]); //do funkcji czyszczacej
    const [optionsCATEGORIEs, setOptionsCATEGORIEs] = useState([]);
    const [loading, setLoading] = React.useState(false);
    /*
    const getCategories = async () => {
        try {
            const url = `/jira/getCategories`;
            setLoading(true);
            const response = await axios.get(url);
            const categories = response.data;
            // Sortuj rekordy alfabetycznie według DisplayName
            categories.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setOptionsCATEGORIEs(categories);
            //console.log((optionsCATEGORIEs.find(option => option.name.trim().toUpperCase() === record.Category)).name)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getCategories();
    }, []);
    */
    const getCategories = async () => {
        try {
            const url = `/jira/getCategories`;
            setLoading(true);
            const response = await axios.get(url);
            const categories = response.data;
            // Przekształć pobrane kategorie, aby zawierały tylko właściwość "name"
            const categoryNames = categories.map(category => category.name);
            // Ustaw przekształcone kategorie w stanie
            setOptionsCATEGORIEs(categoryNames);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("An error occurred:", error.message);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // Pobierz dane użytkownika
            const username = accounts[0]?.username;
            setRecord({ ...recordData, ModifiedBy: username });
            console.log('fetch: ' + recordData.Category)
        };

        // Wywołaj funkcję fetchData, gdy dane użytkownika są dostępne
        if (accounts.length > 0) {
            fetchData();
            //getCategories();
        }
    }, [accounts, recordData]);


    //console.log('recordData: ', recordData);
    //console.log('record: ', record);




    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );
    /*
    useEffect(() => {
        // Pobieramy dane rekordu na podstawie ID
        const fetchRecord = async () => {
            try {
                const response = await axios.get(`/jira/getProject/${recordId}`);
                setRecord(response.data);
            } catch (error) {
                console.error('Error fetching record:', error);
            }
        };

        if (recordId) {
            fetchRecord();
        }
    }, [recordId]);
    */




    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(record);
        /*setRecord(prevState => ({
            ...prevState,
            ModifiedBy: accounts[0]?.username
        }));
        */
        console.log(record);
        await SendForm();
        

    };

    const SendForm = async () => {
        if (
            record.Config === '' ||
            record.Category === '') { toast.error('Wypełnij wszystkie wymagane pola.'); }
        else {
            //console.log('sent: ' + record.ModifiedBy);
            axios.post('/jira/updateCommercialProjectCK', record) //bylo formDataCopy
                .then((response) => {
                    //alert(`Rekord dodany pomyślnie ${response.data}`);
                    toast.success(`Wniosek zaktualizowany. ${response.data}`);
                    //getMyRequests();
                    //handleClear();
                    onClose();
                })
                .catch((error) => {
                    //alert(`Błąd podczas dodawania rekordu ${error.message}`);
                    toast.error(`Błąd podczas dodawania rekordu ${error.message}`);
                    console.error('Błąd podczas dodawania rekordu:', error);
                });
        }
    };
    const validateProjectKey = async (name) => {
        try {
            const url = `/jira/validprojectkey/${name}`;
            const response = await axios.get(url);
            const isValid = response.data
            return isValid;
        } catch (error) {
            console.error('Błąd walidacji nazwy klucza:', error);
            return false;
        }
    };
    const handleChange = (e, selectedOptions, id) => {
        const { name, value, type, checked } = e ? e.target : {};
        let newValue = type === 'checkbox' ? checked : value;
        let selectedValue = ''; // Inicjalizacja


        
        // Sprawdź nazwę pola, czy ma wartość 'ProjectKey' lub 'projectKey'
        const fieldName = name?.toLowerCase() === 'projectkey' ? 'ProjectKey' : name;
        if (name === 'projectKey') {
            // Anuluj poprzednie zdarzenie setTimeout
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                // Walidacja nazwy projektu
                if (fieldName === 'ProjectKey') {
                    const isValid = await validateProjectKey(newValue);
                    if (!isValid) {
                        setValidationError('Ten klucz jest nieprawidłowy/zajęty - spróbuj dodać literę na końcu ciągu np. ' + newValue + 'A, ' + newValue + 'B');
                    } else {
                        setValidationError('');
                    }
                }
            }, 500); // Poczekaj 500 ms przed wysłaniem żądania walidacyjnego
            newValue = newValue.toUpperCase();
        }
        /*if (id === 'Category') {
            console.log("selected cat:" + selectedOptions[0].name);
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].name : '';
        }*/

        if (id === 'Category') {
            console.log("selected cat:" + selectedOptions);
            selectedValue = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0] : '';
        }

        // Zaktualizuj stan rekordu
        setRecord(prevState => ({
            ...prevState,
            [fieldName || id]: newValue || selectedValue
        }));

        console.log(record)
    };


    // console.log('rdata' + recordData);

    return (
        <Modal show={show} onHide={onClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Wprowadź parametry projektu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {record && (
                    <div>
                        <Container>
                            <Form className="my-form" >
                                <Row className="mb-2">

                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPurpose" className="form-group">
                                        <Form.Label>Przeznaczenie projektu</Form.Label>
                                        <Form.Select disabled name="projectPurpose" value={record.Purpose} /*onChange={(e) => handleChange(e, null, null)}*/ className="custom-select" placeholder="Wybierz...">

                                            <option value="komercyjny" selected>KOMERCYJNY</option>
                                            <option value="neutral">NEUTRAL</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectConfig" className="form-group">
                                        <Form.Label>Konfiguracja<span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Select name="Config" value={record.Config} onChange={(e) => handleChange(e, null, null)} className="custom-select" placeholder="Wybierz...">
                                            <option value="">Wybierz...</option>
                                            <option value="kanban">Kanban</option>
                                            <option value="scrum">Scrum</option>
                                            <option value="presales">Presales</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="Category" className="form-group">
                                        <Form.Label>Kategoria<span style={{ color: 'red' }}>*</span></Form.Label>
                                        
                                        <Typeahead
                                            clearButton
                                            //defaultSelected={optionsDEPARTMENTs.slice(0, 1)}
                                            //defaultSelected={record.Category}
                                            //defaultSelected={record.Category ? [record.Category] : []}
                                            //defaultSelected={record.Category ? [record.Category] : [{ name: '' }]}
                                            //defaultSelected={record.Category ? [optionsCATEGORIEs.find(option => option === record.Category)] : []}
                                            //defaultSelected={record.Category ? [record.Category] : []}
                                            //defaultSelected={record.Category}
                                            //defaultSelected={optionsCATEGORIEs.filter(option => record.Category === option)}
                                            selected={record.Category ? [record.Category] : []}
                                            id="Category"
                                            //labelKey="name"
                                            onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                                console.log(text, e);
                                            }}
                                            options={optionsCATEGORIEs}
                                            placeholder="Wybierz..."
                                            onChange={selectedOptions => { handleChange(null, selectedOptions, "Category") }}
                                        />
                                    </Form.Group>

                                </Row>

                                <Row className="mb-2">
                                    <Form.Group as={Col} xs={12} md={8} lg={4} controlId="sam">
                                        <Form.Label>Klucz projektu</Form.Label>
                                        <Form.Control disabled type="text" name="projectKey" value={record.ProjectKey} /*onChange={(e) => handleChange(e, null, null)}*/ isInvalid={validationError !== ''} maxLength={10} />
                                        {<Form.Control.Feedback type="invalid">
                                            {validationError}
                                        </Form.Control.Feedback>}
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={8} controlId="sam">
                                        <Form.Label>Nazwa projektu</Form.Label>
                                        <Form.Control disabled
                                            type="text"
                                            name="projectName"
                                            value={record.ProjectName}
                                        /*onChange={(e) => handleChange(e, null, "projectName")}*/
                                        //isInvalid={validationError !== ''}
                                        />
                                        {/*<Form.Control.Feedback type="invalid">
                                            {validationError}
                                        </Form.Control.Feedback>*/}
                                    </Form.Group>

                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} xs={12} md={12} lg={12} controlId="sam">
                                        <Form.Label>Opis projektu</Form.Label>
                                        <Form.Control disabled type="text" name="projectDescription" value={record.ProjectDescription} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCK" className="form-group">
                                        <Form.Label>CK</Form.Label>
                                        <Form.Control disabled ype="text" name="projectCK" value={record.CK} /*onChange={(e) => handleChange(e, null, null)}*/ />

                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectPM">
                                        <Form.Label>Project Manager</Form.Label>
                                        <Form.Control disabled type="text" name="projectPM" value={record.PM} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="Handlowiec">
                                        <Form.Label>Opiekun handlowy</Form.Label>
                                        <Form.Control disabled type="text" name="projectDH" value={record.DH} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNazwa">
                                        <Form.Label>Nazwa Klienta z KRS</Form.Label>
                                        <Form.Control disabled type="text" name="projectKlientNazwa" value={record.CustomerName} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientAdres">
                                        <Form.Label>Adres Klienta z KRS</Form.Label>
                                        <Form.Control disabled type="text" name="projectKlientAdres" value={record.CustomerAddress} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectKlientNip">
                                        <Form.Label>NIP Klienta</Form.Label>
                                        <Form.Control disabled type="text" name="projectKlientNip" value={record.CustomerNIP} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-2">
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectCurrency" className="form-group">
                                        <Form.Label>Waluta</Form.Label>
                                        <Form.Select disabled name="projectCurrency" value={record.CustomerCurrency} /*onChange={handleChange}*/ className="custom-select" placeholder="Wybierz...">
                                            <option value="">Waluta</option>
                                            <option value="PLN">PLN</option>
                                            <option value="EUR">EUR</option>
                                            <option value="USD">USD</option>
                                            <option value="inna">inna</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="projectTermin" className="form-group">
                                        <Form.Label>Termin płatności</Form.Label>
                                        <Form.Select disabled name="projectTermin" value={record.CustomerInvoiceDate} /*onChange={handleChange}*/ className="custom-select" placeholder="Wybierz...">
                                            <option value="">Termin płatności</option>
                                            <option value="3">3dni</option>
                                            <option value="7">7dni</option>
                                            <option value="14">14dni</option>
                                            <option value="21">21dni</option>
                                            <option value="30">30dni</option>
                                            <option value="45">45dni</option>
                                            <option value="60">60dni</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} controlId="projectUwagi">
                                        <Form.Label>Uwagi</Form.Label>
                                        <Form.Control disabled as="textarea" rows={3} name="projectUwagi" value={record.Comments} /*onChange={(e) => handleChange(e, null, null)}*/ />
                                    </Form.Group>
                                </Row>



                            </Form>
                        </Container>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Zapisz
                </Button>
            </Modal.Footer>
        </Modal>
    );
};



export default Jira;
