import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import axios from "axios";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography'

function Row(props) {
    const { price } = props;
    const [openTable, setOpenTable] = React.useState(false);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 11
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
        },
    }));
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell><IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenTable(!openTable)}
                >
                    {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton></StyledTableCell>
                <StyledTableCell>{price.Id}</StyledTableCell>
                <StyledTableCell >{price.KlientNazwa}</StyledTableCell>
                <StyledTableCell size="small">{price.ABProduktGroup}</StyledTableCell>
                <StyledTableCell>{price.Produkt}</StyledTableCell>
                <StyledTableCell>{price.CenaRok}</StyledTableCell>
                <StyledTableCell>{price.CenaRokProcent === false ? 'False'
                    : price.CenaRokProcent === true
                        ? 'True'
                        : price.CenaRokProcent}</StyledTableCell>
                <StyledTableCell>{price.CenaMiesiac}</StyledTableCell>
                <StyledTableCell>{price.CenaMiesiacProcent === false ? 'False'
                    : price.CenaMiesiacProcent === true
                        ? 'True'
                        : price.CenaMiesiacProcent}</StyledTableCell>
                <StyledTableCell>{price.Waluta}</StyledTableCell>
                <StyledTableCell>{price.KodProjektu}</StyledTableCell>
                <StyledTableCell align="right">{price.CreatedAt}</StyledTableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={openTable} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>KlientId</StyledTableCell>
                                        <StyledTableCell>ProduktId</StyledTableCell>
                                        <StyledTableCell>EmailDH</StyledTableCell>
                                        <StyledTableCell>Mailing</StyledTableCell>
                                        <StyledTableCell align="right">Uwagi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell component="th" scope="row">{price.ABKlientId}</StyledTableCell>
                                        <StyledTableCell>{price.ABProduktId}</StyledTableCell>
                                        <StyledTableCell>{price.EmailDH}</StyledTableCell>
                                        <StyledTableCell>{price.Mailing}</StyledTableCell>
                                        <StyledTableCell align="right">{price.Uwagi}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse></TableCell></TableRow>
        </React.Fragment>
    );
}


function EnhancedTableToolbar(props) {
    const { searchTerm, onSearchTermChange } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...({
                    bgcolor: "#ffffff"
                }),
            }}
        >               
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Cennik BCM
                </Typography>
            <input
                type="text"
                placeholder="Szukaj klienta..."
                value={searchTerm}
                onChange={onSearchTermChange}
            />
        </Toolbar>
    );
}



const BCMProductsTable = () => {
    const [prices, setPrices] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            //const response = await fetch("https://localhost:7259/db/getcennikbcm").then((response) => response.json());
            const response = await axios.get("/db/getcennikbcm").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setPrices(response);
        })();
    }, [setPrices]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    const [searchTerm, setSearchTerm] = React.useState('');
    function handleSearchTermChange(event) {
        setSearchTerm(event.target.value);
    }
    const filteredData = prices.filter(item =>
        item.KlientNazwa.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const divStyle = {
        backgroundColor: "#e4e7eb"
    };
    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar onSearchTermChange={handleSearchTermChange} searchTerm={searchTerm} />    
        <TableContainer component={Paper}>          
                <Table size="small">
                    <TableHead>
                    <TableRow style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <StyledTableCell />
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Klient</StyledTableCell>
                            <StyledTableCell>Grupa</StyledTableCell>
                            <StyledTableCell>Produkt</StyledTableCell>
                            <StyledTableCell>Cena R/M</StyledTableCell>
                            <StyledTableCell>Rozl. procentowe rok</StyledTableCell>
                            <StyledTableCell>Cena M/M</StyledTableCell>
                            <StyledTableCell>Rozl. procentowe miesiąc</StyledTableCell>
                            <StyledTableCell>Waluta</StyledTableCell>
                            <StyledTableCell>Kod projektu</StyledTableCell>
                            <StyledTableCell align="right">Data dodania</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {/*
{prices.map((price) => ( */}
                        {filteredData.map(price => (
                            <Row key={price.Id} price={price} />

                        ))}
                    </TableBody>
                </Table>
            
                </TableContainer>
            </Paper>
        </React.Fragment>
        
    )
};

export default BCMProductsTable;