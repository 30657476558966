import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import Title from './Title';
import axios from "axios";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

/*
const data = [
    createData('2022-12', 0),
    createData('2023-01', 91914.93),
    createData('2023-02', 133332.69),
    createData('2023-03', undefined),
];
*/
export default function Chart() {
    const theme = useTheme();
    const [data, setDatas] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/GetOrdersAmount/")
                .then((response) => {
                    setDatas(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });
        })();
    }, [setDatas]);   
    return (
    <React.Fragment>
      <Title>Sprzedaż</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
              >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Date"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
                Sprzedaż (€)
            </Label>
            </YAxis>
            <Tooltip />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="Amount"
            stroke={theme.palette.primary.main}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
