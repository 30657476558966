import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import axios from "axios";

function preventDefault(event) {
  event.preventDefault();
}

const currDate = new Date().toDateString() //.toLocaleDateString();
const currTime = new Date().toLocaleTimeString();

export default function Deposits() {
    const [orders, setOrders] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/GetOrdersTodayAmount")
                .then((response) => {
                    setOrders(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });

            //setOrders(response);
        })();
    }, [setOrders]); 

  return (
    <React.Fragment>
      <Title>Wartość zamówień</Title>
          <Typography component="p" variant="h4">
              €{orders.length > 0 ? orders[0].Amount : 0}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
              on {currDate}
          </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          Raport bieżący
        </Link>
      </div>
    </React.Fragment>
  );
}
