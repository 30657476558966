import * as React from 'react';

import Title from './Title';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const DisabledAccsWithLicenses = () => {
    const [loading, setLoading] = React.useState(false);
    const [datasUsers, setDatasUsers] = React.useState([]); //usery wylaczone z licencja przypisana
    const [isTableExpanded, setIsTableExpanded] = useState(true); //rozwijanie tabeli usery wylaczone z licencja przypisana

    const handleToggleTable = () => {
        setIsTableExpanded(prevState => !prevState);
    };


    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    React.useEffect(() => {
        (async () => {
            const url = "/graph/getDisLicUsers"
            setLoading(true);
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatasUsers(response.value);
            console.log(datasUsers);
            setLoading(false);
            setIsTableExpanded(false);
        })();
    }, [setDatasUsers]);

    return (
        <React.Fragment>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div style={{ overflowX: 'auto', marginBottom: '1rem' }}>
                    Konta wyłączone ale licencje przypisane
                    <IconButton onClick={handleToggleTable}>
                        {isTableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
                {isTableExpanded ? (
                    <div style={{ overflowX: 'auto' }}>
                        {loading ? (
                            <Box sx={{ display: 'flex' }}><CircularProgress color="success" /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
                        ) : (
                            <table style={{ borderCollapse: "collapse", width: "100%" }} >
                                <thead>
                                    <tr>
                                        <th style={tableHeaderStyle}>DisplayName</th>
                                        <th style={tableHeaderStyle}>UserPrincipalName</th>
                                        <th style={tableHeaderStyle}>extensionAttribute1</th>
                                        <th style={tableHeaderStyle}>Enabled</th>
                                        <th style={tableHeaderStyle}>Licenses</th>
                                        <th style={tableHeaderStyle}></th>
                                    </tr>
                                </thead>
                                    <tbody>           
                                    {datasUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td style={tableCellStyle}>{user.displayName}</td>
                                            <td style={tableCellStyle}>{user.userPrincipalName}</td>
                                            <td style={tableCellStyle}>{user.extensionAttribute1}</td>
                                            <td style={tableCellStyle}>{user.accountEnabled ? "Yes" : "No"}</td>
                                            <td style={tableCellStyle}>
                                                <ul>
                                                    {user.licenses.map((license, licenseIndex) => (
                                                        <li key={licenseIndex}>{license.skuPartNumber}</li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td style={tableCellStyle}><button>Zdejmij</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                ) : (
                    <div style={{ overflowX: 'auto' }}>
                        <table style={{ borderCollapse: "collapse", width: "100%" }} >
                            <thead>
                                <tr>
                                    <th style={tableHeaderStyle}>DisplayName</th>
                                    <th style={tableHeaderStyle}>UserPrincipalName</th>
                                    <th style={tableHeaderStyle}>extensionAttribute1</th>
                                    <th style={tableHeaderStyle}>Enabled</th>
                                    <th style={tableHeaderStyle}>Licenses</th>
                                    <th style={tableHeaderStyle}></th>
                                </tr>
                            </thead>
                                <tbody>
                                {datasUsers.length > 0 && datasUsers.slice(0, Math.min(3, datasUsers.length)).map((user, index) => (
                                    <tr key={index}>
                                        <td style={tableCellStyle}>{user.displayName}</td>
                                        <td style={tableCellStyle}>{user.userPrincipalName}</td>
                                        <td style={tableCellStyle}>{user.extensionAttribute1}</td>
                                        <td style={tableCellStyle}>{user.accountEnabled ? "Yes" : "No"}</td>
                                        <td style={tableCellStyle}>
                                            <ul>
                                                {user.licenses.map((license, licenseIndex) => (
                                                    <li key={licenseIndex}>{license.skuPartNumber}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td style={tableCellStyle}><button>Zdejmij</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {datasUsers.length > 3 && (
                                
                            <IconButton onClick={handleToggleTable}><ExpandMoreIcon/>
                                </IconButton>
                        )}
                    </div>
                )}
            </Paper>
        </React.Fragment>
                    
 
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default DisabledAccsWithLicenses;
