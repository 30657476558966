import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { BarChart, Legend, Bar  } from 'recharts';
import Title from './Title';
import axios from "axios";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

/*
const data = [
    createData('2022-12', 0),
    createData('2023-01', 91914.93),
    createData('2023-02', 133332.69),
    createData('2023-03', undefined),
];
*/
export default function ChartReports() {
    const theme = useTheme();
    const [data, setDatas] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/GetReportsAmount/")
                .then((response) => {
                    setDatas(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });
        })();
    }, [setDatas]);   
    return (
    <React.Fragment>
            <Title>Raporty miesięczne</Title>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="Month"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    >
                    <Label
                        angle={270}
                        position="left"
                        style={{
                            textAnchor: 'middle',
                            fill: theme.palette.text.primary,
                            ...theme.typography.body1,
                        }}
                    >
                        Suma (€)
                    </Label>
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="Amount"
                        fill={theme.palette.primary.main}
                        />
                </BarChart>
            </ResponsiveContainer>
    </React.Fragment>
  );
}
