import * as React from 'react';

import Title from './Title';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import axios from "axios";
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginApiRequest, updateScopes, resetLoginApiRequest } from '../authConfig.js'; 
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Doughnut } from 'react-chartjs-2';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const SubscribedSkus = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]); //usery wylaczone z licencja przypisana
    const [isTableExpanded, setIsTableExpanded] = useState(true); //rozwijanie tabeli usery wylaczone z licencja przypisana

    const handleToggleTable = () => {
        setIsTableExpanded(prevState => !prevState);
    };


    const { instance, accounts } = useMsal();
    // Funkcja pomocnicza do ustawiania nagłówka 'Authorization' z aktualnym tokenem dostępu
    const setAuthorizationHeader = (config) => {
        config.headers['Authorization'] = `Bearer ${accounts[0]?.idToken}`;
        return config;
    };
    // Ustawienie interceptora przed wysłaniem żądania
    axios.interceptors.request.use(
        (config) => setAuthorizationHeader(config),
        (error) => Promise.reject(error)
    );

    /*
    React.useEffect(() => {
        (async () => {
            const url = "/graph/getSubscribedSkus"
            setLoading(true);
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            console.log(datas);
            setLoading(false);
        })();
    }, [setDatas]);
    */
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get("/graph/getSubscribedSkus");
                // Filtrowanie SKU z enabled mniejszym niż 10 000
                const filteredData = response.data.filter((subscribedSku) => subscribedSku.enabled <= 1000);
                setDatas(filteredData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    // Funkcja do obliczania maksymalnej liczby dostępnych jednostek dla wszystkich SKU
    const calculateMaxEnabled = () => {
        let maxEnabled = 0;
        datas.forEach((subscribedSku) => {
            const enabled = subscribedSku.enabled;
            if (enabled <= 1000 && enabled > maxEnabled) {
                maxEnabled = enabled;
            } else if (enabled > 1000 && 1000 > maxEnabled) {
                maxEnabled = 1000;
            }
        });
        return maxEnabled;
    };
    return (
        <React.Fragment>

            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div style={{ overflowX: 'auto', marginBottom: '1rem' }}>
                    Stan licencji
                    
                </div>
                
                {isTableExpanded && (
                    loading ? (
                        <Box sx={{ display: 'flex' }}><CircularProgress color="success" /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
                    ) : (
                        <ResponsiveContainer height={240}>
                                <BarChart
                                    data={datas.filter(subscribedSku => subscribedSku.appliesTo === 'User' && subscribedSku.consumedUnits > 0).map((subscribedSku, index) => ({
                                        skuPartNumber: subscribedSku.skuPartNumber,
                                        consumedUnits: subscribedSku.consumedUnits,
                                        remainingUnits: subscribedSku.enabled - subscribedSku.consumedUnits
                                    }))}
                                margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="skuPartNumber" angle={-45} height={80}  textAnchor="end" tick={{ fontSize: 8 }} />
                                    <YAxis tick={{ fontSize: 10 }} domain={[0, 'auto']} />
                                <Tooltip />
                                <Legend layout="horizontal" align="center" verticalAlign="top" wrapperStyle={{ fontSize: '10px' }} />
                                    <Bar dataKey="consumedUnits" stackId="a" fill="#727272" />
                                    <Bar dataKey="remainingUnits" stackId="a" fill="#69b246" />
                            </BarChart>
                        </ResponsiveContainer>
                    )
                )}
                  </Paper>   
            </React.Fragment>
                    
 
    );
};

const tableHeaderStyle = {
    
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontSize: "9px",
};

const tableCellStyle = {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
    fontSize: "9px",
};

export default SubscribedSkus;
