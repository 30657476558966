import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import { styled } from '@mui/material/styles';
//import makeStyles from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Title from './Title';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

const defaultValues = {
    ABKlientId: 0,
    KlientNazwa: "",
    ABProduktId: 0,
    Produkt: "",
    ABProduktGroupId: 0,
    CenaRok: 0.0,
    CenaRokProcent: false,
    CenaMiesiac: 0.0,
    CenaMiesiacProcent: false,
    Waluta: "PLN",
    Uwagi: "",
    EmailDH: "",
    Mailing: "",
    KodProjektu: ""
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function Row(props) {
    const { price } = props;
    const [openTable, setOpenTable] = React.useState(false);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 11
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
        },
    }));
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell><IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenTable(!openTable)}
                >
                    {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton></StyledTableCell>
                <StyledTableCell>{price.Id}</StyledTableCell>
                <StyledTableCell >{price.KlientNazwa}</StyledTableCell>
                <StyledTableCell size="small">{price.ABProduktGroup}</StyledTableCell>
                <StyledTableCell>{price.Produkt}</StyledTableCell>
                <StyledTableCell>{price.CenaRok}</StyledTableCell>
                <StyledTableCell>{price.CenaRokProcent === false ? 'False'
                    : price.CenaRokProcent === true
                        ? 'True'
                        : price.CenaRokProcent}</StyledTableCell>
                <StyledTableCell>{price.CenaMiesiac}</StyledTableCell>
                <StyledTableCell>{price.CenaMiesiacProcent === false ? 'False'
                    : price.CenaMiesiacProcent === true
                        ? 'True'
                        : price.CenaMiesiacProcent}</StyledTableCell>
                <StyledTableCell>{price.Waluta}</StyledTableCell>
                <StyledTableCell>{price.KodProjektu}</StyledTableCell>
                <StyledTableCell align="right">{price.CreatedAt}</StyledTableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={openTable} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>KlientId</StyledTableCell>
                                        <StyledTableCell>ProduktId</StyledTableCell>
                                        <StyledTableCell>EmailDH</StyledTableCell>
                                        <StyledTableCell>Mailing</StyledTableCell>
                                        <StyledTableCell align="right">Uwagi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell component="th" scope="row">{price.ABKlientId}</StyledTableCell>
                                        <StyledTableCell>{price.ABProduktId}</StyledTableCell>
                                        <StyledTableCell>{price.EmailDH}</StyledTableCell>
                                        <StyledTableCell>{price.Mailing}</StyledTableCell>
                                        <StyledTableCell align="right">{price.Uwagi}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse></TableCell></TableRow>
        </React.Fragment>
        );
}



const BCMProducts = () => {
    const [openmodal, setOpenmodal] = React.useState(false);
    const handleOpen = () => setOpenmodal(true);
    const handleClose = () => {
        setOpenmodal(false);
        reloadData();
    }
    //const [openTable, setOpenTable] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [options2, setOptions2] = React.useState([]);
    const [options3, setOptions3] = React.useState([]);
    const loading = open && options.length === 0;

    const [prices, setPrices] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            //const response = await fetch("https://localhost:7259/db/getcennikbcm/limit/10").then((response) => response.json());
            const response = await axios.get("/db/getcennikbcm/limit/5").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setPrices(response);
        })();
    }, [setPrices]);
    const reloadData = () => {
        (async () => {
            //const response = await fetch("https://localhost:7259/db/getcennikbcm/limit/10").then((response) => response.json());
            const response = await axios.get("/db/getcennikbcm/limit/5").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setPrices(response);
        })();
    }
    const reloadProducts = () => {
            (async () => {
                //const response = await fetch("https://localhost:7259/db/getcennikbcm").then((response) => response.json());
                const response = await axios.get("/db/getproductsbygroup/" + formValues.ABProduktGroupId).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
                setOptions2(response);
            })();
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }
        (async () => {
            //const response = await fetch("https://localhost:7259/db/getcustomers").then((response) => response.json());
            const response = await axios.get("/db/getcustomers").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            //const response2 = await fetch("https://localhost:7259/db/getproducts").then((response) => response.json());
            //const response2 = await axios.get("/db/getproducts").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            const response3 = await axios.get("/db/getproductsgroups").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            //const response2 = await axios.get("/db/getproductsbygroup/" + formValues.ABProduktGroupId).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });

            if (active) {
                setOptions(response);
                setOptions3(response3);
                //setOptions2(response2);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const [formValues, setFormValues] = useState(defaultValues);
    //const clearFromValues = () => setFormValues([defaultValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "CenaRokProcent" || name === "CenaMiesiacProcent") {
            var val = (value.toLowerCase() === "true"); //konwersja do boolean
            setFormValues({
                ...formValues,
                [name]: val,
            });
        }
        else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
        //console.log(formValues);
    }

    function fetchdata() {
        /*fetch('https://localhost:7259/db/AddPrice', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formValues)
        }).then((response) => response.json())
        */
        (async () => {
        axios.post("/db/AddPrice", formValues).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
        })();
        return 
    };
    function preventDefault(event) {
        event.preventDefault();
    }

    let navigate = useNavigate();
    const routeChange = () => {
        let path = "/bcmproductstable";
        navigate(path);
    }
    const routeChangeEdit = () => {
        let path = "/bcmproductsedit";
        navigate(path);
    }

    const handleSubmit = (event) => {
        //let data;
        event.preventDefault();
        //console.log(value.id.toString);
        fetchdata()
        reloadData();
        console.log(formValues);
        //event.target.reset();
        setFormValues({
            ...formValues,
            CenaRok: 0.0,
            CenaMiesiac: 0.0
        });
        //clearFromValues();
        //console.log(formValues);
        event.target.reset();
        handleOpen();
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontSize: 12,
            fontWeight: 'bold' 
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
        },
    })); 
    /*
    const useStyles = makeStyles((theme) => ({
        gridItem: {
            padding: '5px',
            height: '15px',
        },
    }));

    const classes = useStyles();
    */
    const StyledGridItem = styled('div')({
        boxSizing: 'border-box',
        padding: '1px',
        height: '5px',
        //width: '10px',
    });
    const StyledAutocomplete = styled(Autocomplete)({
        '.MuiAutocomplete-input': {
            fontSize: '10px', // Zmień rozmiar czcionki według potrzeb
            height: '10px',
            //padding: '2px 5px 2px 2px',
        },
        //boxSizing: 'border-box',
        padding: '1px 5px 2px 2px',
        //height: '10px',
        //width: '10px',
    });

    const updateFormValues = (newValue) => {
        // Dodaj logikę aktualizacji formValues według potrzeb
        // W poniższym przykładzie, zakładam, że `formValues` jest hookiem stanu
        setFormValues({
            ...formValues,
            ABKlientId: newValue?.id || null,
            KlientNazwa: newValue?.label || '',
        });
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedOption(newValue);
        updateFormValues(newValue);
    };
    const autocompleteRef = useRef(null);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>
                            <Grid item xs={5}><FormControl fullWidth>


                                <Autocomplete

                                    ref={autocompleteRef}

                                    disablePortal
                                    id="customer-input"
                                    //options={customers.map(option => ({ id: option.id, label: option.name }))}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    //getOptionLabel={(option) => option.name}
                                    options={options.map(option => ({ id: option.id, label: option.name }))}
                                    loading={loading}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => { formValues.ABKlientId = newValue.id; formValues.KlientNazwa = newValue.label }}
                                    //onChange={handleAutocompleteChange}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            //onChange={(e) => formValues.ABKlientId = e.target.value}
                                            //onChange={handleInputChange}
                                            name="KlientNazwa"
                                            label="Klient"
                                            type="text"

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />} /></FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        id="productgroup-input"
                                        open={open3}
                                        onOpen={() => {
                                            setOpen3(true);
                                            
                                        }}
                                        onClose={() => {
                                            setOpen3(false);
                                            reloadProducts();
                                        }}
                                        //options={products.map(option => ({ id: option.id, label: option.name }))}
                                        options={options3.map(option => ({ id: option.id, label: option.name }))}
                                        loading={loading}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}


                                        onChange={(event, newValue) => { formValues.ABProduktGroupId = newValue.id }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                name="Grupa"
                                                label="Grupa"
                                                type="text"
                                            />} /></FormControl>
                            </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                disablePortal
                                id="product-input"
                                open={open2}
                                onOpen={() => {
                                    setOpen2(true);
                                    //reloadProducts();
                                }}
                                onClose={() => {
                                    setOpen2(false);
                                }}
                                //options={products.map(option => ({ id: option.id, label: option.name }))}
                                options={options2.map(option => ({ id: option.id, label: option.name }))}
                                loading={loading}
                                isOptionEqualToValue={(option, value) => option.id === value.id}


                                        onChange={(event, newValue) => { formValues.ABProduktId = newValue.id; formValues.Produkt = newValue.label  }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        name="Produkt"
                                        label="Produkt"
                                        type="text"
                                    />} /></FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth><TextField
                            id="cenaRok-input"
                            name="CenaRok"
                            label="Cena R/M"
                            type="number"
                            inputProps={{
                                step: 0.0001,
                            }}
                            //value={formValues.cenaRok}
                            onChange={handleInputChange}
                        /></FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel>Sposób naliczania</FormLabel>
                            <RadioGroup
                                name="CenaRokProcent"
                                value={formValues.CenaRokProcent}
                                onChange={handleInputChange}
                                defaultValue={false}
                                row
                            >
                                <FormControlLabel
                                    key="cenaR"
                                    value={false}
                                    control={<Radio size="small" />}
                                    label="Cena"
                                />
                                <FormControlLabel
                                    key="procentR"
                                    value={true}
                                    control={<Radio size="small" />}
                                    label="Procent"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="cena-miesiac"
                                name="CenaMiesiac"
                                label="Cena M/M"
                                type="number"
                                inputProps={{
                                    step: 0.0001,
                                }}
                                //value={formValues.cenaMiesiac}
                                onChange={handleInputChange}
                            /></FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel>Sposób naliczania</FormLabel>
                            <RadioGroup
                                name="CenaMiesiacProcent"
                                value={formValues.CenaMiesiacProcent}
                                onChange={handleInputChange}
                                defaultValue={false}
                                row
                            >
                                <FormControlLabel
                                    key="cenaM"
                                    value={false}
                                    control={<Radio size="small" />}
                                    label="Cena"
                                />
                                <FormControlLabel
                                    key="procentM"
                                    value={true}
                                    control={<Radio size="small" />}
                                    label="Procent"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="waluta-label">Waluta</InputLabel>
                            <Select
                                labelId="waluta-label"
                                id="waluta-select"
                                name="Waluta"
                                value={formValues.Waluta}
                                onChange={handleInputChange}
                                label="Waluta"
                            >
                                <MenuItem key="PLN" value="PLN">PLN</MenuItem>
                                <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                                <MenuItem key="USD" value="USD">USD</MenuItem>
                            </Select>
                        </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth><TextField
                                    id="mailing-input"
                                    name="Mailing"
                                    label="Mailing"
                                    type="text"
                                    value={formValues.Mailing}
                                    onChange={handleInputChange}
                                /></FormControl></Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth><TextField
                            id="emaildh-input"
                            name="EmailDH"
                            label="Email DH"
                            type="text"
                            value={formValues.EmailDH}
                            onChange={handleInputChange}
                        /></FormControl></Grid>
                    <Grid item xs={3}><FormControl fullWidth><TextField
                        id="kodprojektu-input"
                        name="KodProjektu"
                        label="Kod projektu"
                        type="text"
                        value={formValues.KodProjektu}
                        onChange={handleInputChange}
                    /></FormControl></Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="uwagi-input"
                                name="Uwagi"
                                label="Uwagi"
                                type="text"
                                value={formValues.Uwagi}
                                onChange={handleInputChange}
                            /></FormControl></Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                                </Button>
                            </Grid>
                            <Grid item xs={8}/>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" onClick={routeChange}>Zobacz cały cennik</Button>
                                &nbsp;
                                <Button variant="contained" color="primary" onClick={routeChangeEdit}>Edytuj cennik</Button>
                                
                    </Grid>
                </Grid>

                    </form></Paper>
            </Grid>
            <Grid item xs={12}>
                
                </Grid>
            <Grid item xs={12}>
                
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>Ostatnio dodane:</Title>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                
                                <StyledTableCell>Klient</StyledTableCell>
                                <StyledTableCell>Grupa</StyledTableCell>
                                <StyledTableCell>Produkt</StyledTableCell>
                                <StyledTableCell>Cena R/M</StyledTableCell>
                                <StyledTableCell>Rozl. procentowe rok</StyledTableCell>
                                <StyledTableCell>Cena M/M</StyledTableCell>
                                <StyledTableCell>Rozl. procentowe miesiąc</StyledTableCell>
                                <StyledTableCell>Waluta</StyledTableCell>
                                <StyledTableCell>Kod projektu</StyledTableCell>
                                <StyledTableCell align="right">Data dodania</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prices.map((price) => (
                                <TableRow key={price.Id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    
                                    <StyledTableCell >{price.KlientNazwa}</StyledTableCell>
                                    <StyledTableCell size="small">{price.ABProduktGroup}</StyledTableCell>
                                    <StyledTableCell>{price.Produkt}</StyledTableCell>
                                    <StyledTableCell>{price.CenaRok}</StyledTableCell>
                                    <StyledTableCell>{price.CenaRokProcent === false ? 'False'
                                        : price.CenaRokProcent === true
                                            ? 'True'
                                            : price.CenaRokProcent}</StyledTableCell>
                                    <StyledTableCell>{price.CenaMiesiac}</StyledTableCell>
                                    <StyledTableCell>{price.CenaMiesiacProcent === false ? 'False'
                                        : price.CenaMiesiacProcent === true
                                            ? 'True'
                                            : price.CenaMiesiacProcent}</StyledTableCell>
                                    <StyledTableCell>{price.Waluta}</StyledTableCell>
                                    <StyledTableCell>{price.KodProjektu}</StyledTableCell>
                                    <StyledTableCell align="right">{price.CreatedAt}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Link color="primary" href="#" onClick={routeChange} sx={{ mt: 2 }} >
                        Zobacz cały cennik
                    </Link>
                    <Link color="primary" href="#" onClick={routeChangeEdit} sx={{ mt: 0 }} >Edytuj cennik
                    </Link>
                </Paper>
            </Grid>


            <Modal
                keepMounted
                open={openmodal}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Dane wysłane
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        dane przekazane do bazy
                    </Typography>
                </Box>
            </Modal>
        </Grid >

    );
};

export default BCMProducts;





