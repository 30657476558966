import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Chart from './Chart';
import DisLic from './DisabledAccsWLics';
import SubscribedSkus from './SubscribedSkus';
import Deposits from './Deposits';
import Orders from './Orders';
import ChartReports from './ChartReports';
import ChartCReports from './ChartCustomersReport';
import Vulnerabilities from './Vulnerabilities';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://poczobut.pl/">
        PP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}







function DashboardContent() {
    return (
        <React.Fragment>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            TU BEDZIE PANEL
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            STAN SYNCHRONIZACJI
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <SubscribedSkus />
                    </Grid>
                    <Grid item xs={12}>
                        <DisLic />
                    </Grid>
                    <Grid item xs={12}>
                        <Vulnerabilities />
                    </Grid>

                    
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default function DashboardSD() {
  return <DashboardContent />;
}
