import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import axios from "axios";

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = { products: [], loading: true };
    }
    
    componentDidMount() {
        this.populateProductData();
    }
    


    LinearIndeterminate() {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }


    static renderProductsTable(products) {

        const products1 = products.map((product) => {
           return product.prices.map((price) => {                
                    return `${product.id}, ${product.name}` //, ${product.price.amount}`
            }).join("\n")
        }).join("\n")
        //console.log(products1);

        return (
            <React.Fragment>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>Wszystkie produkty</Title>
                    
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Purchase 12/1</TableCell>
                                <TableCell>ERP 12/1</TableCell>
                                <TableCell>Purchase 12/12</TableCell>
                                <TableCell>ERP 12/12</TableCell>
                                <TableCell>Available</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>{product.id}</TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    {product.prices.map((price) => {
                                        if (price.type === "purchase" && price.billingPlan.term === 12 && price.billingPlan.invoicePeriod === 1)
                                            return (
                                                <TableCell key={price}>
                                                    {price.amount}&nbsp;{price.currency}
                                                </TableCell>
                                            )
                                    })}
                                    {product.prices.map((price) => {
                                        if (price.type === "erp" && price.billingPlan.term === 12 && price.billingPlan.invoicePeriod === 1)
                                            return (
                                                <TableCell key={price}>
                                                    {price.amount}&nbsp;{price.currency}
                                                </TableCell>
                                            )                                    
                                    })}
                                    {product.prices.map((price) => {
                                        if (price.type === "purchase" && price.billingPlan.term === 12 && price.billingPlan.invoicePeriod === 12)
                                            return (
                                                <TableCell key={price}>
                                                    {price.amount}&nbsp;{price.currency}
                                                </TableCell>
                                            )
                                    })}
                                    {product.prices.map((price) => {
                                        if (price.type === "erp" && price.billingPlan.term === 12 && price.billingPlan.invoicePeriod === 12)
                                            return (
                                                <TableCell key={price}>
                                                    {price.amount}&nbsp;{price.currency}
                                                </TableCell>
                                            )
                                    })}
                                    <TableCell>{product.isAvailableForPurchase === false ? 'False'
                                        : product.isAvailableForPurchase === true
                                            ? 'True'
                                            : product.isAvailableForPurchase}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Link color="primary" href="#" sx={{ mt: 3 }}>
                        Więcej
                    </Link>
                </Paper>
            </React.Fragment>
        );
    }
    render() {
        let contents = this.state.loading
            ? <Box sx={{ width: '100%' }}><LinearProgress/><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
            : Products.renderProductsTable(this.state.products);

        return (
            <>
                {contents}
            </>
        );
    }
    async populateProductData() {
        //const data = await fetch('/ab/getproducts');
        //const response = await fetch('https://localhost:7259/ab/getproducts');
        const response = await axios.get("/ab/getproducts").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
        //const data = await response.json();
        //console.log(data);
        this.setState({ products: response, loading: false });
    }
}

export default Products;





