import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

function preventDefault(event) {
    event.preventDefault();
}

function Row(props) {
    const { order } = props;
    const [open, setOpen] = React.useState(false);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{order.id}</StyledTableCell>
                <StyledTableCell>{order.CustomerName}</StyledTableCell>
                <StyledTableCell>{order.customerId}</StyledTableCell>
                <StyledTableCell>{order.type}</StyledTableCell>
                <StyledTableCell>{order.status}</StyledTableCell>
                <StyledTableCell>{order.billingPlan.term}/{order.billingPlan.invoicePeriod}</StyledTableCell>
                <StyledTableCell>{order.invoiceNumber}</StyledTableCell>
                <StyledTableCell align="right">{order.createdAt}</StyledTableCell>
                <StyledTableCell align="right">{order.completedAt}</StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Szczegóły
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                            <StyledTableCell>ProductId</StyledTableCell>
                                            <StyledTableCell>ParentProductId</StyledTableCell>
                                            <StyledTableCell>ProductName</StyledTableCell>
                                            <StyledTableCell>ProductPartNumber</StyledTableCell>
                                            <StyledTableCell>ProductGroup</StyledTableCell>
                                            <StyledTableCell>SubscriptionId</StyledTableCell>
                                            <StyledTableCell>ParentSubscriptionId</StyledTableCell>
                                        <StyledTableCell align="right">Quantity</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.items.map((item) => (
                                        <TableRow key={item.productId}>
                                            <StyledTableCell component="th" scope="row">{item.productId}</StyledTableCell>
                                            <StyledTableCell>{item.parentProductId}</StyledTableCell>
                                            <StyledTableCell>{item.ProductName}</StyledTableCell>
                                            <StyledTableCell>{item.productPartNumber}</StyledTableCell>
                                            <StyledTableCell>{item.ProductGroup}</StyledTableCell>
                                            <StyledTableCell>{item.subscriptionId}</StyledTableCell>
                                            <StyledTableCell>{item.parentSubscriptionId}</StyledTableCell>
                                            <StyledTableCell align="right">{item.quantity}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}



export default function Orders1() {
    //const { instance } = useMsal();
    const [orders, setOrders] = React.useState([]);
    React.useEffect(() => {
        (async () => {
            //const response = await fetch("https://localhost:7259/ab/getorderslimit/10").then((response) => response.json());
            await axios
                .get("ab/getorderslimit/10/")
                .then((response) => {
                    setOrders(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });

            //setOrders(response);
        })();
    }, [setOrders]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            //backgroundColor: "#e4e7eb",
            //color: theme.palette.common.black,
            fontSize: 12,
            fontWeight: 'bold'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    let navigate = useNavigate();
    const routeChange = () => {
        let path = "/orders";
        navigate(path);
    }
    return (
        <React.Fragment>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                <Title>Ostatnie zamówienia</Title>
                <TableContainer >
                    <Table size="small" aria-label="collapsible table" padding="checkbox">
                    <TableHead>
                            <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>CustomerName</StyledTableCell>
                            <StyledTableCell>CustomerId</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Term/Period</StyledTableCell>
                            <StyledTableCell>Invoice number</StyledTableCell>
                            <StyledTableCell align="right">Created at</StyledTableCell>
                            <StyledTableCell align="right">Completed at</StyledTableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {orders.map((order) => (
                                <Row key={order.id} order={order} />
                            ))}
                        </TableBody>
                </Table>
                </TableContainer>
                <Link color="primary" href="#" onClick={routeChange} sx={{ mt: 3 }}>
                    Wszystkie zamówienia
                </Link>
            </Paper>
        </React.Fragment>
    );
}
