import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
//import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
//import DraftsIcon from '@mui/icons-material/Drafts';
import Typography from '@mui/material/Typography';
import {
    Route,
    Routes,
    Navigate,
    MemoryRouter,
    useLocation,
    Switch,
    BrowserRouter
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import Orders from './ABOrders';
import Dashboard from './Dashboard';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import DashboardIcon from '@mui/icons-material/Dashboard';
import { mainListItems, secondaryListItems, serviceDeskListItems, helpListItems, JiraCKExtendedListItems } from './MenuItems';
import Chart from './Chart';
//import WeatherForecast from './WeatherForecast';
//import AdbIcon from '@mui/icons-material/Adb';
import Link from '@mui/material/Link';
import Products from './ABProducts';
import BCMProducts from './BCMProducts';
import BCMProductsTable from './BCMProductsTable';
import BCMProductsEdit from './BCMProductsEdit';
import CurrentReport from './CurrentReport';
import PreviousReport from './PreviousReport';
import Subscriptions from './ABSubscriptions'
import Customers from './ABCustomers'
import Redirect from './Redirect';
import ProjektyJiraPowerApps from './RedirectProjektyJira';
import Sync from './Sync';
import Sejm from './Sejm';
import DashboardSD from './DashboardSD';
import Test from './Test';
import BcmPriceList from './BcmPriceList';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import axios from "axios";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { RouteGuard } from './RouteGuard';
import { appRoles } from '../authConfig';
import { useState, useEffect } from 'react';
import BcmAccs from './BcmAccs';
import JiraProjectsDH from './JiraDH';
import JiraProjectsCK from './JiraCK';
import JiraProjectsLOGISTYKA from './JiraLOGISTYKA';
/*
function Router(props) {
    const { children } = props;
    if (typeof window === 'undefined') {
        return <StaticRouter location="/drafts">{children}</StaticRouter>;
    }

    return (
        <MemoryRouter initialEntries={['/']} initialIndex={0}>
            {children}
        </MemoryRouter>
    );
}
*/
Router.propTypes = {
    children: PropTypes.node,
};

function Router(props) {
    const { children } = props;

    return (
        <BrowserRouter>
            {children}
        </BrowserRouter>
    );
}

/*
function Content() {
    const location = useLocation();
    return (
        <Typography variant="body2" sx={{ pb: 2 }} color="text.secondary">
            Current route: {location.pathname}
        </Typography>

    );
}
*/
/*
function GetLocation() {
    const location = useLocation();
    if (location.pathname === '/projects') {
        return (
            <>Wniosek o założenie projektu w Jira</>
        );
    }
    else {
        return (
            <>{location.pathname}</>
        );
    }
}
*/
function GetLocation() {
    const location = useLocation();
    if (location.pathname.startsWith('/projects')) {
        const parts = location.pathname.split('/'); // Rozdziel ścieżkę na części za pomocą separatora '/'
        if (parts.length > 2) {
            return (
                // <>Wniosek o założenie projektu w Jira (ID: {parts[2]})</>
                <>Wniosek o założenie projektu w Jira</>
            );
        } else {
            return (
                <>Wniosek o założenie projektu w Jira</>
            );
        }
    } if (location.pathname.startsWith('/pomoc')) {
        return (
            <>Pomoc</>
        );
    } if (location.pathname.startsWith('/bcmPriceList')) {
        return (
            <>Cennik BCM</>
        );
    } if (location.pathname.startsWith('/dashboard')) {
        return (
            <>Dashboard</>
        );
    } if (location.pathname.startsWith('/currentreport')) {
        return (
            <>Raport bieżący</>
        );
    } if (location.pathname.startsWith('/previousreport')) {
        return (
            <>Raport poprzedni miesiąc</>
        );
    } if (location.pathname.startsWith('/sync')) {
        return (
            <>Synchronizacja i inne toole</>
        );
    } else {
        return (
            <>{location.pathname}</>
        );
    }
}

function Copyright(props) {
    return (
        
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            <b>Copyright &#169;&nbsp; 
            <Link color="inherit" href="https://poczobut.pl/">
                <img className="logo" src='/betacom.png' />
            </Link>{' '}
            {new Date().getFullYear()}
                {'.'}</b>
            </Typography>
    );
}


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme({
    //scale: 1.5,
    typography: {
        fontSize: 11, // Zmniejszenie ogólnej wielkości tekstu
    },
    overrides: {
        MuiFormControl: {
            root: {

            },
        },
        MuiTextField: {
            root: {

            },
        },
        MuiSelect: {
            select: {

            },
        },
        MuiGrid: {

            root: {

            },
            item: {

            },
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    fontSize: '10px',
                },
            },

        
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: '10px',

                },
            },
            defaultProps: {
                InputLabelProps: {
                    classes: {
                    //    shrink: 'global-shrink animated', // Dodanie globalnej klasy dla zwężania
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    
                },
                select: {
                //    padding: '1px !important', // Dostosuj odstęp wewnątrz elementu input
                },

            },
            defaultProps: {
                InputLabelProps: {
                    classes: {
                    //    shrink: 'global-shrink animated', // Dodanie globalnej klasy dla zwężania
                    },
                },
            },

        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                
                item: {
                    
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: '10px', // Dostosuj wielkość czcionki
                //    height: 'auto',
                 //   padding: '4px !important',
                },
                notchedOutline: {
                    borderWidth: '1px', // Dostosuj grubość ramki
                 //   padding: '4px', // Dostosuj odstęp między tekstem a ramką
                //    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Dodaj cień ramki
                },
                input: {
                //    height: '0px',
                 //   padding: '4px',
                    fontSize: '10px'
                },
            },
            defaultProps: {
                InputLabelProps: {
                    classes: {
                    //    shrink: 'global-shrink animated', // Dodanie globalnej klasy dla zwężania
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    //padding: '8px !important', // Dostosuj odstęp wewnątrz elementu input
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    //top: '-12px !important',
                },
                /*
                outlined: {
                    transform: 'translate(14px, 16px) scale(1)',
                    '&.global-shrink': {
                        transform: 'translate(8px, 5px) scale(0.75)',
                    },
                    '&.animated': {
                        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1)',
                    },
                },
                */
            },
        },
    
    },
});
export default function ListRouter() {
    const isAuthenticated = useIsAuthenticated();
    const { accounts, instance } = useMsal();
    const username = accounts[0].name ?? 'USER';
    //const location = useLocation();
    //const { userRole } = location.state;
    const currentAccount = instance.getAllAccounts()[0];
    //const { userRole } = .filter((role) => currentAccount.idTokenClaims['roles'].includes(role));
    const userRole = currentAccount.idTokenClaims['roles'];
    //const [open, setOpen] = React.useState(true);
    const [open, setOpen] = React.useState(userRole.some(role => role === appRoles.JiraDH || role === appRoles.JiraCK || role === appRoles.JiraLOGISTYKA) ? false : true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    //const settings = ['Profile', 'Logout'];
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        //const currentAccount = accounts.getAccountByHomeId(homeAccountId);
        accounts.logoutRedirect({
            account: accounts[0],
            //postLogoutRedirectUri: "https://localhost:3000"
        });

        //accounts.logoutPopup({
        //        postLogoutRedirectUri: "/",
        //        mainWindowRedirectUri: "/" // redirects the top level app after logout
        //    });
       
    }
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    //scopes podajemy tylko do jednego api !!!!!
    const apiRequest = {
        scopes: ["user.read", "user.readbasic.all"],
    };
    const [avatarBlob, setAvatarBlob] = useState(null);
    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                //const account = msalInstance.getAllAccounts()[0];
                const msalResponse = await instance.acquireTokenSilent({
                    ...apiRequest,
                    account: accounts[0],
                });

                const photoRequestConfig = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${msalResponse.accessToken}`,
                    },
                };
                const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', photoRequestConfig);

                if (!response.ok) {
                    // Obsługa błędów
                    if (response.status === 401) {
                        console.error('Your access is not allowed.');
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }
                // Konwersja odpowiedzi na Blob
                const pictureBlob = await response.blob();

                // Przypisanie Blob do stanu
                setAvatarBlob(pictureBlob);
            } catch (error) {
                // Obsługa błędów
                console.error('Error fetching avatar:', error);
            }
        };

        // Wywołanie funkcji do pobrania danych
        fetchAvatar();
    }, []);


    const getDashboardPath = () => {
        console.log(userRole);
        // Tutaj możesz umieścić logikę do określenia ścieżki do przekierowania na podstawie roli użytkownika
        if (userRole.some(userRole => userRole === appRoles.Basic)) {
            console.log('basic: ' + userRole);
            return '/dashboard';
        } else if (userRole.some(userRole => userRole === appRoles.ServiceDesk)) {
            console.log('serviceDesk: ' + userRole);
            return '/sd';
        } else if (userRole.some(userRole => userRole === appRoles.JiraDH)) {
            return '/projects';
        } else if (userRole.some(userRole => userRole === appRoles.JiraCK)) {
            return '/projects';
        } else if (userRole.some(userRole => userRole === appRoles.JiraLOGISTYKA)) {
            return '/projects';
        } else if (userRole.some(userRole => userRole === appRoles.JiraCKextended)) {
            return '/projects';
        }
        else {
            console.log('else: ' + userRole);
            return '/dashboard';
        }
    };
    return (
        <Router>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>

                    <CssBaseline />
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                //onClick={getAvatar}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* <AdbIcon sx={{ flexGrow: 1 }} /> */}
                            <img className="raporter-logo" src='/raporterLogo.png' alt='RAPORTER'/>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                <GetLocation />
                            </Typography>
                            <IconButton color="inherit">
                                <Badge badgeContent={0} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <Tooltip title={username}> 
                                
                                <IconButton onClick={handleOpenUserMenu} >
                                    <Avatar alt={username} src={avatarBlob ? URL.createObjectURL(avatarBlob) : null} {...stringAvatar(username)} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '40px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >

                                
                                    <MenuList>
                                        {isAuthenticated ?

                                        <MenuItem onClick={() => handleLogout}>
                                                <ListItemIcon>
                                                    <LogoutIcon fontSize="small" />
                                                </ListItemIcon>
                                            <SignOutButton />
                                        </MenuItem>
                                            :
                                            <MenuItem >
                                                <ListItemIcon>
                                                    <LoginIcon fontSize="small" />
                                                </ListItemIcon>
                                                <SignInButton />
                                            </MenuItem>}
                                    </MenuList>
                                
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}> 
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        {/* menu */}
                        <Paper elevation={0}>
                            {mainListItems({ userRole: userRole })}
                            
                            {secondaryListItems({ userRole: userRole })}
                            {serviceDeskListItems({ userRole: userRole })}
                            {JiraCKExtendedListItems({ userRole: userRole })}
                            {helpListItems}
                        </Paper>
                        {/* Menu */}
                    </Drawer>


                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        
                        <Toolbar />
                        
                        <Routes>

                            <Route path="/dashboard" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic, appRoles.ServiceDesk]}><Dashboard /></RouteGuard>} />
                            <Route path="/orders" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><Orders /></RouteGuard>} />
                            <Route path="/chart" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><Chart /></RouteGuard>} />
                            <Route path="/products" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><Products /></RouteGuard>} />
                            <Route path="/bcmproducts" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><BCMProducts /></RouteGuard>} />
                            <Route path="/bcmproductstable" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><BCMProductsTable /></RouteGuard>} />
                            <Route path="/bcmproductsedit" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><BCMProductsEdit /></RouteGuard>} />
                            <Route path="/currentreport" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><CurrentReport /></RouteGuard>} />
                            <Route path="/previousreport" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><PreviousReport /></RouteGuard>} />
                            <Route path="/subscriptions" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><Subscriptions /></RouteGuard>} />
                            <Route path="/customers" element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><Customers /></RouteGuard>} />
                            <Route path='/pomoc' element={<Redirect />} />
                            <Route path='/projektyjira' element={<ProjektyJiraPowerApps />} />
                            <Route path='/sync' element={<RouteGuard roles={[appRoles.Admin]}><Sync /></RouteGuard>} />
                            <Route path='/bcmPriceList' element={<RouteGuard roles={[appRoles.Admin, appRoles.Basic]}><BcmPriceList /></RouteGuard>} />
                            <Route path='/sejm' element={<RouteGuard roles={[appRoles.ServiceDesk]}><Sejm /></RouteGuard>} />
                            <Route path='/test' element={<RouteGuard roles={[appRoles.ServiceDesk]}><Test /></RouteGuard>} />
                            <Route path='/sd' element={<RouteGuard roles={[appRoles.ServiceDesk]}><DashboardSD /></RouteGuard>} />
                            <Route path='/onboarding' element={<RouteGuard roles={[appRoles.ServiceDesk]}><BcmAccs /></RouteGuard>} />
                            
                            {userRole.some(role => role === appRoles.JiraDH) && (
                                <Route path='/projects' element={<RouteGuard roles={[appRoles.JiraDH]}><JiraProjectsDH /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraCK) && (
                                <Route path='/projects' element={<RouteGuard roles={[appRoles.JiraCK]}><JiraProjectsCK /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraLOGISTYKA) && (                               
                                <Route path='/projects' element={<RouteGuard roles={[appRoles.JiraLOGISTYKA]}><JiraProjectsLOGISTYKA /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraLOGISTYKA) && ( 
                                <Route path='/projects/:recordId' element={<RouteGuard roles={[appRoles.JiraLOGISTYKA]}><JiraProjectsLOGISTYKA /></RouteGuard>} />    
                            )}
                            {userRole.some(role => role === appRoles.JiraCK) && (
                                <Route path='/projects/:recordId' element={<RouteGuard roles={[appRoles.JiraCK]}><JiraProjectsCK /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraCKextended) && (
                                <Route path='/projects/:recordId' element={<RouteGuard roles={[appRoles.JiraCKextended]}><JiraProjectsCK /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraCKextended) && (
                                <Route path='/projects' element={<RouteGuard roles={[appRoles.JiraCKextended]}><JiraProjectsDH /></RouteGuard>} />
                            )}
                            {userRole.some(role => role === appRoles.JiraCKextended) && (
                                <Route path='/projectsN' element={<RouteGuard roles={[appRoles.JiraCKextended]}><JiraProjectsCK /></RouteGuard>} />
                            )}
                            <Route path="/" element={<Navigate to={getDashboardPath()} />} />
                            <Route path="*" element={<Navigate to="/dashboard" />} />  
                            
                        </Routes>
                        
                        <Copyright sx={{ pt: 4 }} />
                    </Box>                 
                </Box>
            </ThemeProvider>
        </Router>
    );
}