import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import PriceChange from '@mui/icons-material/PriceChange';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CurrencyBitcoin from '@mui/icons-material/CurrencyBitcoin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ThunderstormRoundedIcon from '@mui/icons-material/ThunderstormRounded';

import ListItem from '@mui/material/ListItem';
import {
    Link as RouterLink
} from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { appRoles } from '../authConfig';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import GroupsIcon from '@mui/icons-material/Groups';
import DataObjectIcon from '@mui/icons-material/DataObject';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import JiraProjectsDH from './JiraDH';
import JiraProjectsCK from './JiraCK';

const Link = React.forwardRef(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});
function ListItemLink(props) {
    const { icon, primary, to } = props;

    return (
        <li>
            <ListItem button component={Link} to={to}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export const mainListItems = ({ userRole }) => (
    <React.Fragment>
        {userRole.some(userRole => userRole === appRoles.Basic) && (
            <>
                <List aria-label="main">
                    <ListItemLink to="/" primary="Dashboard" icon={<DashboardIcon />} />
                    <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>
                        <b>A&nbsp;B</b>
                    </ListSubheader>
                    <ListItemLink to="/orders" primary="Zamówienia" icon={<ShoppingCartIcon />} />
                    <ListItemLink to="/customers" primary="Klienci" icon={<PeopleIcon />} />
                    <ListItemLink to="/subscriptions" primary="Subskrypcje" icon={<FolderSharedRoundedIcon />} />
                    <ListItemLink to="/bcmPriceList" primary="Cennik BCM" icon={<PriceChange />} />
                    {/*<ListItemLink to="/bcmproducts" primary={<span style={{ textDecoration: 'line-through' }}>Cennik BCM</span>} icon={<LocalOfferIcon />} />*/}
                    <ListItemLink to="/products" primary="Ceny AB" icon={<CurrencyBitcoin />} />
                    <ListItemLink to="/" primary="Raporty" icon={<BarChartIcon />} />
                    <ListItemLink to="/sync" primary="Synchronizacja" icon={<SyncRoundedIcon />} />
                </List>
            </>
        )}
  </React.Fragment>
);


export const secondaryListItems = ({ userRole }) => (
    <React.Fragment>
        {userRole.some(userRole => userRole === appRoles.Basic) && (
            <>
                <Divider sx={{ my: 1 }} />
                <List aria-label="secondary">
                    <ListSubheader component="div" inset>
                        Raporty
                    </ListSubheader>
                    <ListItemLink to="/currentreport" primary="Ten miesiąc" icon={<AssignmentIcon />} />
                    <ListItemLink to="/previousreport" primary="Poprzedni miesiąc" icon={<AssignmentIcon />} />
                    <Divider sx={{ my: 2 }} />
                    <ListSubheader component="div" inset>
                        Inne usługi
                    </ListSubheader>
                    <ListItemLink to="/" primary="Raporter konfiguracja" icon={<SettingsRoundedIcon />} />
                    <ListItemLink to="/" primary="BSI raport azure" icon={<ThunderstormRoundedIcon />} />
                </List>
            </>
        )}
    </React.Fragment>
);

export const serviceDeskListItems = ({ userRole }) => (
    <React.Fragment>
        {userRole.some(userRole => userRole === appRoles.ServiceDesk) && (
            <>
                <List aria-label="sd">
                    <ListItemLink to="/" primary="DashboardSD" icon={<DashboardIcon />} />
                    <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>
                        <b>B&nbsp;C&nbsp;M</b>
                    </ListSubheader>
                    <ListItemLink to="/onboarding" primary="Onboarding" icon={<PersonAddIcon />} />
                    <ListItemLink to="/sejm" primary="Offboarding" icon={<PersonRemoveAlt1Icon />} />
                    <ListItemLink to="/subscriptions" primary="Grupy" icon={<GroupsIcon />} />
                    <ListItemLink to="/bcmPriceList" primary="Skrypty" icon={<DataObjectIcon />} />
                    <ListItemLink to="/products" primary="Raporty" icon={<AssessmentIcon />} />
                    <ListItemLink to="/" primary="Test" icon={<BarChartIcon />} />
                    <ListItemLink to="/sejm" primary="Sejm strem" icon={<SyncRoundedIcon />} />
                </List>
            </>)}
    </React.Fragment>
);

export const JiraCKExtendedListItems = ({ userRole }) => (
    <React.Fragment>
        {userRole.some(userRole => userRole === appRoles.JiraCKextended) && (
            <>
                <List aria-label="sd">
                    <ListItemLink to="/projects" primary="Projekt komercyjny" icon={<FlipToFrontIcon />} />
                    <ListItemLink to="/projectsN" primary="Projekt neutral" icon={<FlipToBackIcon />} />
                </List>
            </>)}
    </React.Fragment>
);

export const helpListItems = (
    <React.Fragment>
        <List aria-label="help">
            <Divider sx={{ my: 1 }} />
            <ListItemLink to="/pomoc" primary="Pomoc" icon={<SupportAgentRoundedIcon />} />
        </List>
    </React.Fragment>
);
/*
export function AccountListItems() {
    const isAuthenticated = useIsAuthenticated();
    return (
    <React.Fragment>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <SettingsRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Zaloguj</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘X
                        </Typography>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <SettingsRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Wyloguj</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘C
                        </Typography>
                    </MenuItem>
                </MenuList>
            </Paper>
    </React.Fragment>
)
};*/
