import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';

function preventDefault(event) {
    event.preventDefault();
}

function Row(props) {
    const { order } = props;
    const [open, setOpen] = React.useState(false);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e4e7eb",
            color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{order.SPOSOB_ROZLICZENIA}</StyledTableCell>
                <StyledTableCell>{order.NAZWA_KLIENTA}</StyledTableCell>
                <StyledTableCell>{order.PRODUKT}</StyledTableCell>
                <StyledTableCell>{order.ILOSC}</StyledTableCell>
                <StyledTableCell>{order.DATA_START}</StyledTableCell>
                <StyledTableCell>{order.DATA_KONIEC}</StyledTableCell>
                <StyledTableCell>{order.ILOSC_DNI}</StyledTableCell>
                <StyledTableCell>{order.CENA_ZA_DZIEN_EUR}</StyledTableCell>
                <StyledTableCell>{order.CENA_ZA_MIESIAC_EUR}</StyledTableCell>
                <StyledTableCell>{order.CENA_JEDNOSTKOWA_EUR}</StyledTableCell>
                <StyledTableCell>{order.WARTOSC_EUR}</StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>DYSTRYBUTOR</StyledTableCell>
                                        <StyledTableCell>CENA JEDNOSTKOWA PLN</StyledTableCell>
                                        <StyledTableCell>WARTOŚĆ PLN</StyledTableCell>
                                        <StyledTableCell>NR FAKTURY</StyledTableCell>
                                        <StyledTableCell>WALUTA</StyledTableCell>
                                        <StyledTableCell>KOD PROJEKTU</StyledTableCell>
                                        <StyledTableCell align="right">UWAGI</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow key={`${order.NAZWA_KLIENTA}-${order.PRODUKT}`}>
                                        <StyledTableCell component="th" scope="row">{order.DYSTRYBUTOR}</StyledTableCell>
                                        <StyledTableCell>{order.CENA_JEDNOSTKOWA_PLN}</StyledTableCell>
                                        <StyledTableCell>{order.WARTOŚĆ_PLN}</StyledTableCell>
                                        <StyledTableCell>{order.NR_FAKTURY}</StyledTableCell>
                                        <StyledTableCell>{order.WALUTA}</StyledTableCell>
                                        <StyledTableCell>{order.KOD_PROJEKTU}</StyledTableCell>
                                        <StyledTableCell align="right">{order.UWAGI}</StyledTableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}


function EnhancedTableToolbar(props) {
    const { searchTerm, onSearchTermChange } = props;

    function download() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth(); //javascript returns zero-based index of month -> jan = 00
        const formattedMonth = (month < 10 ? '0' : '') + month;
        const filename = `RAPORT_${year}-${formattedMonth}.xlsx`;

        axios({
            url: '/db/DownloadCurrentReport',
            method: 'GET',
            responseType: 'blob'
        })
            .then(response => {
                // Create a temporary URL for the downloaded file
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link element to trigger the file download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                // Append the link to the document and trigger the download
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    alert("Your access is not allowed.");
                } else {
                    console.error('Error downloading report:', error);
                }
            });
    };
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...({
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.05),
                }),
            }}
        >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Raport bieżący
                </Typography>

            <input
                type="text"
                placeholder="Szukaj klienta..."
                value={searchTerm}
                onChange={onSearchTermChange}
            />           
                <Tooltip title="Pobierz xlsx">
                <IconButton onClick={() => {download()}}>
                        <CloudDownloadIcon />
                    </IconButton>

                </Tooltip>
        </Toolbar>
    );
}

function useSearchTerm(initialValue) {
    const [searchTerm, setSearchTerm] = useState(initialValue);

    function handleSearchTermChange(event) {
        setSearchTerm(event.target.value);
    }

    return [searchTerm, handleSearchTermChange];
}


export default function CurrentReport() {
    //const { instance } = useMsal();
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            await axios
                .get("db/getcurrentreport/")
                .then((response) => {
                    setOrders(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        alert("Your access is not allowed.");
                    }
                });
            setLoading(false);
            //setOrders(response);
        })();
    }, [setOrders]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            //backgroundColor: "#e4e7eb",
            //color: theme.palette.common.black,
            fontSize: 12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));
    let navigate = useNavigate();
    const routeChange = () => {
        let path = "/previousreport";
        navigate(path);
    }

    const [searchTerm, handleSearchTermChange] = useSearchTerm('');

    const filteredData = orders.filter(item =>
        item.NAZWA_KLIENTA.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderOrdersTable = (orders) => {
        if (!Array.isArray(orders)) {
            return null;
        }
        return (
        <React.Fragment>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <EnhancedTableToolbar onSearchTermChange={handleSearchTermChange} searchTerm={searchTerm} />
                
                <TableContainer >
                    <Table size="small" aria-label="collapsible table" padding="checkbox">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>SPOSÓB ROZLICZENIA</StyledTableCell>
                                    <StyledTableCell>NAZWA KLIENTA</StyledTableCell>
                                    <StyledTableCell>PRODUKT</StyledTableCell>
                                    <StyledTableCell>ILOŚĆ</StyledTableCell>
                                    <StyledTableCell>DATA START</StyledTableCell>
                                    <StyledTableCell>DATA KONIEC</StyledTableCell>
                                    <StyledTableCell>ILOŚĆ DNI</StyledTableCell>
                                    <StyledTableCell>CENA ZA DZIEŃ EUR</StyledTableCell>
                                    <StyledTableCell>CENA ZA MIESIĄC EUR</StyledTableCell>
                                    <StyledTableCell>CENA JEDNOSTKOWA EUR</StyledTableCell>
                                    <StyledTableCell>WARTOŚĆ EUR</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((order, index) => (
                                <Row key={index} order={order} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link color="primary" href="#" onClick={routeChange} sx={{ mt: 3 }}>
                    Raport z poprzedniego miesiąca
                </Link>
            </Paper>
            </React.Fragment>
        )
        
    }

    let contents = loading
        ? (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
                <p><em>&nbsp;&nbsp;Loading... trwa ładowanie danych - stay calm:) ...</em></p>
            </Box>
        )
        : renderOrdersTable(orders);

    return (
        <>
            {contents}
        </>
    );
}





