import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: "fc3e9c1f-3ccf-43a5-aa67-d2d19b6f07ad", // Client ID 
      authority: 'https://login.microsoftonline.com/015e734b-c94d-4117-b999-42bc566cc590', // Tenant ID of the React.JS App Registration
        redirectUri: "/",
      //redirectUri: "https://localhost:3000/",
      //redirectUri: "https://raporter.betacom.com.pl",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              //console.info(message);
              return;
            case LogLevel.Verbose:
              //console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
export const defaultApiRequest = {
    scopes: ["api://67f30c7a-8d34-448e-8568-3bc5b5fbcf8d/access_as_user", 'openid', 'profile'],
};
/*
export const loginApiRequest = {
    scopes: ["api://67f30c7a-8d34-448e-8568-3bc5b5fbcf8d/access_as_user", 'openid', 'profile'],
};*/

export let loginApiRequest = { ...defaultApiRequest };
export const updateScopes = (newScopes) => {
    loginApiRequest.scopes = newScopes;
};
export const resetLoginApiRequest = () => {
    loginApiRequest = { ...defaultApiRequest };
};

/*export const protectedResources = {
    apiTodoList: {
        todoListEndpoint: 'http://localhost:5000/todolist',
        dashboardEndpoint: 'http://localhost:5000/api/dashboard',
        scopes: ['api://67f30c7a-8d34-448e-8568-3bc5b5fbcf8d/access_as_user'],
    },
};
*/


export const appRoles = {
    Basic: "Basic",
    Admin: "Admin",
    Dev: "Dev",
    BCMAdmin: "BCMAdmin",
    ServiceDesk: "ServiceDesk",
    ServiceDeskOP: "ServiceDeskOP",
    ServiceDeskA: "ServiceDeskA",
    JiraDH: "JiraDH",
    JiraCK: "JiraCK",
    JiraCKextended: "JiraCKextended",
    JiraLOGISTYKA: "JiraLOGISTYKA"
}